import { getAxiosInstance } from '../services/service'

require('dotenv').config()

export const logService = {
   getLogs
};

async function getLogs(handleResponse, handleAction){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/logs`).get().catch(async error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
    if(handleAction) handleAction()
}