import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { userService } from '../services/user_service';
import { toast } from 'react-toastify'
import GoogleLogin from 'react-google-login'
import { validateShowResponseToast } from '../helpers/toast_alerts';
import { authConstants, colors } from '../constants/dc_constants';
import TokenLogin from './TokenAuth'
import { point } from 'leaflet';
import { Box } from '@material-ui/core'
import { CustomButton } from '../GlobalStyles'

class FormLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      submitted: false,
      loading: false,
      error: ''
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.error !== this.state.error) {
      this.setState({ error: '' })
    }
  }

  switch_route = (route) => {
    if (this.props.browserHistory) {
      this.props.browserHistory.push(route)
    }
  }

  login(e) {
    this.setState({ submitted: true });
    const { username, password } = this.state;
    if (!(username && password)) { return; }
    this.setState({ loading: true });
    userService.login(username, password).then(
      user => {
        const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
        this.props.history.replace(from, true);
      },
      error => {
        this.setState({ error, loading: false })
        toast.error("Falha ao realizar login! Verifique Usuário e Senha")
      }
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleKeyDown(e) {
    if (e.key === 'Enter') {
      this.login(e)
    }
  }

  handleClick(e) {
    e.preventDefault();
    this.login(e)
  }

  responseGoogle = (response) => {
    if (!response?.error) {
      const data = { email: response.profileObj.email, id_token: response.Zi.id_token }
      userService.loginGoogle(data, response => {
        if (validateShowResponseToast(response, '', false)) {
          const { from } = this.props.location.state || { from: { pathname: "/dashboard" } };
          this.props.history.push(from);
        }
      })
    }
  }

  render() {
    const { username, password } = this.state;
    const { classes } = this.props;

    return (

      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <div className={classes.formContainer}>
          <div className={classes.iconRight}></div>
          <div className={classes.paper}>
            <img
              className={classes.logo}
              src="/static/images/logo.png"
              alt="Logo iCrop Power"
            >
            </img>
            <form className={classes.form} noValidate>
              <TextField
                className={classes.textStyle}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Usuário"
                name="username"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={username}
                autoComplete=""
                autoFocus
              />
              <TextField
                className={classes.textStyle}
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={password}
                autoComplete="current-password"
              />
              <CustomButton
                fullWidth
                onClick={this.handleClick}
              >
                Entrar
              </CustomButton>
            </form>
            {/* <Grid container style={{marginTop:20}}>
              <Grid item xs={12} >
                <Link
                  component="button"
                  onClick={e => this.switch_route("/recuperar")}
                  variant="body2"
                  style={{color: colors.ORANGE_POWER}}
                >
                  Esqueceu sua senha?
                </Link>
              </Grid>
            </Grid> */}
          </div>
        </div>
      </Container>
    );
  }
}

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0',
    alignItems: 'center',
    minHeight: '100vh'
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 20px rgba(0,0,0,0.2)'
  },
  iconRight: {
    backgroundColor: "#1e2142",
    backgroundImage: 'url("/static/images/icon-power.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 130,
    width: 180,
    height: 350,
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paper: {
    width: 400,
    height: 350,
    backgroundColor: colors.WHITE,
    padding: 40,
    borderRadius: '0 6px 6px 0',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 6,
      maxWidth: 400,
      width: '100%'
    },
  },
  submit: {
    backgroundColor: colors.ORANGE_POWER,
    boxShadow: 'none'
  },
  textStyle: {
    backgroundColor: colors.WHITE,
    marginBottom: 10,
    width: '100%'
  },
  logo: {
    width: '60%',
    minWidth: '120px',
    marginBottom: 15
  }
});

export default withStyles(styles)(FormLogin);
