import React from "react";
import {
	Grid,
	Typography,
	Menu,
	MenuItem,
	IconButton,
	CircularProgress,
} from "@material-ui/core";
import GaugeChart from "./GaugeChart";
import { coletaService } from "../../services/coleta_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { colors } from "../../constants/dc_constants";
import Quadro from "./Quadro";
import { convertData } from "../../util/abb_convert";
import moment from "moment";
import { OfflineBolt } from "@material-ui/icons";
import { gestorService } from "../../services/gestor_service";
import { floorTo2 } from "../../util/utils";


class ChartGestorOnWater extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			chartSize: 400,
			textDescricaoGestor: "",
			ultimaColeta: null,
			penultimaColeta: null,
			anchorEl: null,
			rows: [],
			index: 0,
			carregado: false,
			consumo: 0,

			updateInterval: 60000, // 1 minuto
			//toastId: "Toast1",

			resumoData: [],
			gestor: this.props.data || {},
			gestores: [],
		};

		this.intervalId = 0;
	}

	async componentDidMount() {
		this.handleSelectGestor(this.props.data);
		this.getGestores();
		this.getUltimasColetas();
		this.getResumoDados();
		try {
			this.intervalId = setInterval(async () => {
				this.getUltimasColetas();
				this.getResumoDados();
			}, this.state.updateInterval);

			this.setState({
				textDescricaoGestor: this.getTextGestor(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	getGestores = () => {
		if (this.props.showMenu) {
			gestorService.getGestores("water", {}, (response) => {
				if (validateShowResponseToast(response, "", false)) {
					this.setState({
						gestores: response.data,
					});
				}
			});
		}
	};

	getUltimaColetaData = (attribute, simbol = undefined, defaultValue = "") => {
		try {
			if (this.state.ultimaColeta && this.state.ultimaColeta[attribute]) {
				if (simbol) {
					return `${floorTo2(this.state.ultimaColeta[attribute])}${simbol}`;
				} else {
					return floorTo2(this.state.ultimaColeta[attribute]);
				}
			} else {
				return `${defaultValue}${simbol}`;
			}
		} catch (error) {
			return "----";
		}
	};

	updateUIData = (responseData) => {
		try {
			const data = convertData(responseData, this.state.gestor)
			const ultimaColeta = data[1]
			const consumo = ultimaColeta.consumo
			if (data.length > 0) {
				this.setState({
					consumo,
					ultimaColeta: ultimaColeta,
					penultimaColeta: this.state.ultimaColeta,
					index: this.state.index + 1
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	getGestorData = (attribute, defaultValue = null) => {
		try {
			if (this.state.gestor && this.state.gestor[attribute]) {
				return this.state.gestor[attribute];
			}
			return defaultValue;
		} catch (error) {
			return defaultValue;
		}
	};

	getUltimasColetas = () => {
		if (this.state.gestor) {
			coletaService.getUltimasColetasAgua(
				{ gestor_id: this.state.gestor.id },
				(response) => {
					if (validateShowResponseToast(response, "", false, false)) {
						this.setState({
							rows: response.data,
						});
						this.updateUIData(response.data);
					}
				}
			);
		}
	};

	getResumoDados = () => {
		if (this.state.gestor) {
			coletaService.getColetasAgua({
				gestor_id: this.state.gestor.id,
				data_inicial: moment().startOf('month').startOf('day'),
				data_final: moment()
			},
				(response) => {
					if (response.status === 200) {
						const data = response.data;

						if (data) {
							let lastWeek = data.groupedByWeek.length - 1;
							let lastDay = data.groupedByDay.length - 1;

							const resumoData = {
								consumptionMonth: this.formatTextNaN(data.groupedByMonth[0]?.consumptionSum),
								consumptionWeek: this.formatTextNaN(data.groupedByWeek[lastWeek]?.consumptionSum),
								consumptionDay: this.formatTextNaN(data.groupedByDay[lastDay]?.consumptionSum),
							}

							this.setState({
								resumoData: resumoData,
								carregado: true,
							});
						};
					};
				}
			)
		}
	}


	getUltimaColetaFormatedDate = () => {
		try {
			return moment(this.state.ultimaColeta.data_coleta).format(
				"DD/MM/YYYY HH:mm:ss"
			);
		} catch (error) {
			return "";
		}
	};

	getTextUltimaDataColeta = () => {
		if (this.state.ultimaColeta && this.state.ultimaColeta.data_coleta) {
			return `Última atualização em ${this.getUltimaColetaFormatedDate()}`;
		}
		return "";
	};

	getTextGestor = () => {
		if (this.state.gestor.id) {
			return `Monitor gestor: ${this.state.gestor.id_equipamento} - ${this.state.gestor.fazenda}`;
		} else {
			return "Nenhum gestor selecionado";
		}
	};

	formatTextNaN = (text) => {
		if (isNaN(text)) {
			return "0";
		}
		return text;
	};

	calculateSpare(){
		return Math.round(this.state.gestor.outorga - this.formatTextNaN(this.state.resumoData.consumptionMonth));
	}

	handleCloseMenu = () => {
		this.setState({
			anchorEl: null,
		});
	};

	handleSelectGestor = (gestor) => {
		if (gestor) {
			this.setState(
				{
					gestor,
					resumoData: [],
				},
				() => {
					this.getUltimasColetas();
				}
			);
		}
	};

	render() {
		return (
			<Grid container spacing={1}>
				<Grid container spacing={2}>
					<Grid
						container
						style={{ paddingLeft: 25 }}
						alignContent="center"
						justifyContent="flex-start"
						item
						xs={12}
						md={this.props.showMenu ? 4 : 6}
					>
						<Typography style={{ color: colors.BLUE }}>
							{this.getTextGestor()}
						</Typography>
					</Grid>
					<Grid
						container
						style={{ paddingRight: 50 }}
						alignContent="center"
						justifyContent={this.props.showMenu ? "center" : "flex-end"}
						item
						xs={12}
						md={this.props.showMenu ? 4 : 6}
					>
						<Typography style={{ color: colors.BLUE }}>
							{this.getTextUltimaDataColeta()}
						</Typography>
					</Grid>
					{this.props.showMenu ? (
						<Grid
							container
							style={{ paddingRight: 40 }}
							justifyContent="flex-end"
							item
							xs={12}
							md={4}
						>
							<div>
								<IconButton
									onClick={(e) => this.setState({ anchorEl: e.currentTarget })}
									onMouseOver={(e) =>
										this.setState({ anchorEl: e.currentTarget })
									}
								>
									<OfflineBolt style={{ color: colors.GREEN }} />
									<Typography style={{ color: colors.GREEN }}>
										{" "}
										Gestores
									</Typography>
								</IconButton>
								<Menu
									id="simple-menu"
									anchorEl={this.state.anchorEl}
									keepMounted
									open={Boolean(this.state.anchorEl)}
									onClose={this.handleCloseMenu}
									MenuListProps={{
										onMouseLeave: () => {
											this.setState({ anchorEl: false });
										},
									}}
								>
									{this.state.gestores.map((gestor) => {
										return (
											<MenuItem
												onClick={() => {
													this.handleSelectGestor(gestor);
												}}
											>
												{`${gestor.id_equipamento} - ${gestor.fazenda || ""}`}
											</MenuItem>
										);
									})}
								</Menu>
							</div>
						</Grid>
					) : (
						<div />
					)}
				</Grid>
				<Grid container item xs={12} spacing={6}>

					<Grid item xs={12} sm={6} md={6}>
						<GaugeChart
							title="Consumo Mensal (m³)"
							label="m³"
							max={floorTo2(this.getGestorData("outorga", 200) * 1.1)}
							value={this.formatTextNaN(this.state.resumoData.consumptionMonth)}
							greenFrom={0}
							greenTo={this.getGestorData("outorga", 200) * 0.9}
							yellowFrom={this.getGestorData("outorga", 200) * 0.9}
							yellowTo={this.getGestorData("outorga", 200)}
							redFrom={this.getGestorData("outorga", 200)}
							redTo={this.getGestorData("outorga", 200) * 1.1}
							chartSize={this.state.chartSize}
						>
						{() => (
								<Grid container>
									<Typography
										variant="h5"
										xs={12}
										align="center"
										style={{
											color: "white",
											width: "100%",
											fontSize: "calc(0.80em + 1vw)",
										}}
									>
										{`Disponibilidade = ${this.calculateSpare()} m³.
										`}
									</Typography>
								</Grid>
							)}
						</GaugeChart>
					</Grid>
					<Grid item xs={12} sm={6} md={6}>
						<GaugeChart
							title="Consumo (m³)"
							label="m³/h"
							max={this.getGestorData("potencia_instalada", 200)}
							value={this.state.consumo}
							greenFrom={0}
							greenTo={this.getGestorData("potencia_instalada", 200)}
							chartSize={this.state.chartSize}
						>
						{() => (
								<Grid container>
									<Typography
										variant="h5"
										xs={12}
										align="center"
										style={{
											color: "white",
											width: "100%",
											fontSize: "calc(0.80em + 1vw)",
										}}
									>
										{`A bomba esta trabalhando com ${
											Math.round((this.state.consumo/this.state.gestor.potencia_instalada)*100)
										}% da capacidade total.
										`}
									</Typography>
								</Grid>
							)}
						</GaugeChart>
					</Grid>
					<Grid item xs={12} sm={12} md={12}>
						{this.state.carregado ? (
							<Quadro
								data={this.state.resumoData}
								gestorName={this.state.gestor.id_equipamento}
							></Quadro>
						) : (
							<div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: 'center',
								height: '100%'
							}}>
								<CircularProgress
									style={{ color: colors.ORANGE_POWER }}
									size={100}
								/>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		);
	}

	styles = {
		grid: {
			backgroundColor: colors.LIGHT_GREY,
		},

		typography: {
			backgroundColor: colors.PRIMARY,
			color: colors.SECONDARY,
			padding: 5,
			textAlign: "center",
			fontSize: "calc(5px + 1vw)",
		},

		bottomTypography: {
			backgroundColor: colors.DARK_GREY,
			color: "yellow",
			padding: 5,
			textAlign: "center",
			fontSize: "calc(5px + 1vw)",
		},
	};
}

export default ChartGestorOnWater;
