import React from "react";
import Chart from "react-google-charts";
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import { colors } from "../../constants/dc_constants";
import "./style.css";

export class GaugeChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.boxRef = React.createRef();
  }

  styles = {
    box: {
      height: "45vh",
      backgroundColor: colors.PRIMARY,
    },
    chart: {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "center",
      alignItems: "center",
      height: "25vh",
      width: "30vw",
    },
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
  }

  makeData = () => {
    let value = Number(this.props.value);
    let showValue = 0;
    try {
      value = Math.floor(value * 100) / 100;
      showValue = this.props.showValue || value;
      showValue = Math.floor(showValue * 100) / 100;
    } catch (error) {}
    return [
      ["Label", "Value"],
      [this.props.label, { v: value, f: showValue }],
    ];
  };

  render() {
    return (
      <Box
        ref={this.boxRef}
        border={2}
        borderColor={colors.DARK_GREY}
        style={this.styles.box}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              backgroundColor: colors.LIGHT_GREY,
            }}
          >
            <Typography variant="h5" align="center">
              {this.props.title}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            container
            item
            xs={12}
            alignContent="center"
            justifyContent="center"
            style={{
              backgroundColor: colors.LIGHT_GREY,
            }}
          >
            <Grid item>
              <Chart
                className="chart_gauge"
                style={this.styles.chart}
                chartType="Gauge"
                loader={<div>Carregando Gráfico</div>}
                data={this.makeData()}
                options={{
                  ...this.props,
                  minorTicks: 5,
                  animation: {
                    duration: 100,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Divider style={{ width: "100%" }} />
          <Grid item xs={12}></Grid>
          <Grid container>
            {this.props.children && <>{this.props.children()}</>}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default GaugeChart;
