import moment from "moment"
import { format } from "path-browserify"


function floorTo2(value){
    return Math.floor(value * 100) / 100
}

export function makeSum(rows, sumCols=[], headRows=[], rowId){
	let ret = {}
	headRows.forEach(hr => {
		if(sumCols.indexOf(hr.id) > -1){
			ret[hr.id] = 0
		}else{
			ret[hr.id] = '----'
		}
	})
	ret[rowId] = "Totais"
	rows.forEach(row => {
		sumCols.forEach(sc => {
			ret[sc] += row[sc]
		})
	})
	return ret
}

export function makeDaily(dadosColetas){
	let dias = {};
	let dia = {
		key: "",
		data_coleta: "",
		potencia_ativa_total: 0,
		posto_horario: "",
	};
	let dataString = "";

	dadosColetas.forEach(dc => {
		dataString = moment(dc.data_coleta).format('DD-MM') + "";
		dia= {key: dataString, data_coleta: dc.data_coleta, potencia_ativa_total: dc.potencia_ativa_total, posto_horario: dc.posto_horario};

		if (!dias[dataString]) {
			dias[dataString] = dia;
		} else if (dc.potencia_ativa_total > dias[dataString].potencia_ativa_total) {
			dias[dataString] = dia;
		}
	});

	return(objetoParaVetor(dias));
}

export function sumDaily(dadosColetas){
	let dias = {};
	let dia = {
		key: "",
		data_coleta: "",
		energia_consumida_kwh: 0,
		posto_horario: "",
	};

	dadosColetas.forEach((item) => {
		const dataString = moment(item.data_coleta).format('DD-MM') + "-" + item.posto_horario;
		const energiaConsumida = floorTo2(item.energia_consumida_kwh);

		dia= {key: dataString, data_coleta: item.data_coleta, energia_consumida_kwh: energiaConsumida, posto_horario: item.posto_horario};

		if (dias[dataString]) {
			dias[dataString].energia_consumida_kwh += energiaConsumida;
		} else {
			dias[dataString] = dia;
		}
	});

	return(objetoParaVetor(dias));
}

export function formatDate(dataList){
	return dataList.map(item => {
		return {
			...item,
			data_coleta: item.date,
		};
	});
}

function objetoParaVetor(objeto) {
	var valores = Object.values(objeto);
	return valores;
}

export function convertTime(totalMinutes) {
	let hours = Math.floor(totalMinutes / 60);
	let minutes = totalMinutes % 60;
	return { hours, minutes };
}

export function convertTimeEneel(hours, minutes) {
	return (hours + (minutes * (1 / 60))).toFixed(2);
}