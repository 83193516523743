import React from "react"
import { withStyles } from "@material-ui/core/styles"
import moment from "moment"
import LineChart from "../../components/LineChart"

import {
  Grid,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  IconButton,
  Button,
} from "@material-ui/core"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  SearchRounded,
} from "@material-ui/icons"
import { INTERVALOS, colors } from "../../constants/dc_constants"
import { formatDateTime } from "../../util/utils"
import {
  makeProgressDialog,
  closeProgressDialog,
} from "../../default/dialogs/DialogRefInterface"
import { coletaService } from "../../services/coleta_service"
import { validateShowResponseToast } from "../../helpers/toast_alerts"
import { toast } from "react-toastify"
import { selectOptions } from "../../constants/headRows"
import CustomDateTimePicker from "../../default/custom/CustomDateTimePicker"
import { DatePicker } from '@material-ui/pickers'
import { AirbnbSlider } from "../../default/custom/AirbnbSlider"
import { gestorService } from "../../services/gestor_service"

const TEXT_NO_DATA = "Nenhum dado para mostrar";
const ABNT_CHART = [["potencia"], ["fator_potencia"], ["consumoKwh"]];
const DEFAULT_CHART = [["potencia"], ["fator_potencia"], ["consumoKwh"], ["tensao_tensao"], ["corrente"], ["frequencia"]];

class ChartGestor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.chartValues
        ? this.props.chartValues.selected
        : this.props.data,
      filter: this.props.chartValues ? false : true,
      gestor: null,
      rows: [],
      grid_chart: 1,
      slide_value: 0,
      data_inicio: moment(moment().subtract(30, "days")),
      data_fim: moment(),
      toastId: "Toast1",
      chart_type: "LineChart",
      dateSelected: null,
      interval: this.props.chartValues ? 47 : 95,
      chart_size: 500,
      scrollText: TEXT_NO_DATA,
      chart_refs: [],
      gestores: [],
      gestorSelect: [],
      chartSelecteds: this.props.data && this.props.data.equipamento == "ABNT" ? ABNT_CHART : DEFAULT_CHART,
      graphicsHidden: true,
      hasInjected: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeInterval = this.handleChangeInterval.bind(this);
    this.top = this.state.selected ? -10 : 62;
    this.state.hasInjected= this.state.selected?.demanda_geracao ? true : false;
  }

  getGestores = () => {
    gestorService.getGestores("power", {}, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  componentWillMount() { }
  componentDidMount() {
    this.getGestores();

    if (this.state.selected) {
      this.setState({ gestor: this.state.selected.id });
      this.getColetas();
    }

    if (this.props.chartValues) {
      this.setState({
        gestor: this.props.chartValues.selected.id,
        selected: this.props.chartValues.selected,
      });
      this.getColetas();
    }
  }
  nameChart = (name) => {
    if (name[0] === "tensao_tensao") {
      return "Tensão(V)";
    } else if (name[0] === "corrente") {
      return "Corrente(A)";
    } else {
      return "Demanda";
    }
  };

  getColetas = () => {
    if (this.state.selected) {
      if (
        moment(this.state.data_inicio).add(32, "days") < moment(this.state.data_fim)
      ) {
        toast.error("O intervalo de datas deve ser menor que 1 mês");

        return;
      }

      this.setState({
        graphicsHidden: false
      });

      makeProgressDialog(null, "Carregando dados...").current.open();

      const data = {
        gestor_id: this.state.gestor
          ? this.state.gestor
          : this.state.selected.id,
        data_inicial: moment(this.state.data_inicio).format("YYYY-MM-DD HH:mm:ss"),
        data_final: moment(this.state.data_fim).format("YYYY-MM-DD HH:mm:ss"),
        offset: 0,
        limit: 1000,
      };

      const attr = [
        "data_coleta",
        "energia_consumida_kw_acc",
        "energia_consumida_kwar_acc",
        "energia_consumida_kwh",
        "energia_consumida_kvarh",
        "posto_horario",
        "posto_reativo",
        "index_abs",
        "quadrante",
        "createdAt",
      ];

      selectOptions.forEach((selOpt) => {
        selOpt.headRows.forEach((hr) => {
          if(hr.id != 'ufer'){
            attr.push(hr.id);
          }
        });
      });

      if (this.state.hasInjected) attr.push(
        "energia_injetada_kwh", "energia_injetada_kvarh", "energia_injetada_kwh_acc", "energia_injetada_kvarh_acc"
      );
      data.attr = attr;
      coletaService.getColetasPagination(data, (response) => {

        if (validateShowResponseToast(response, "", true, true)) {
          this.setState({
            rows: response.data,
            slide_value: response.data.length,
          });
          this.addSliderValue(0);
        }

        closeProgressDialog();
      });
    } else {
      makeProgressDialog(null, "Carregando dados...").current.open();
    }
  };

  getCurrentLastValue = (slide_value = this.state.slide_value) => {
    return (
      this.state.rows[slide_value + this.state.interval] ||
      this.state.rows[this.state.rows.length - 1]
    );
  };

  getCurrentData = () => {
    const limit =
      this.state.slide_value + this.state.interval <= this.state.rows.length
        ? this.state.slide_value + this.state.interval
        : this.state.rows.length;

    return this.state.rows.slice(this.state.slide_value, limit + 1);
  };

  //  1 direita, -1 esquerda
  addSliderValue = (side) => {
    const newSlideValue = this.state.slide_value + this.state.interval * side;
    this.handleChangeSlide(null, newSlideValue);
  };

  handleChange(e) {
    const { name, value } = e.target;

    if (name == 'gestor') {
      const selectedValue = this.state.gestores.filter((e) => {
        if (e.id === value) {
          return e;
        }
      });

      let localChartsSelecteds = DEFAULT_CHART;

      if (selectedValue &&
        selectedValue.length > 0 &&
        selectedValue[0].equipamento == "ABNT") {
        localChartsSelecteds = ABNT_CHART;
      }

      let injected = selectedValue[0].demanda_geracao ? true : false;

      this.setState({
        [name]: value,
        selected: selectedValue,
        chartSelecteds: localChartsSelecteds,
        graphicsHidden: true,
        hasInjected: injected
      });
    } else {
      this.setState({ [name]: value });
    }
  }

  handleChangeInterval(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.handleChangeSlide(e, -1);
    });
  }

  notify = () => {
    if (!toast.isActive(this.state.toastId)) {
      this.setState({
        toastId: toast.warn("Não há mais registros"),
      });
    }
  };

  handleChangeSlide = async (e, newValue) => {
    if (newValue <= 0) {
      newValue = this.state.slide_value;
    } else if (
      newValue >= this.state.rows.length &&
      this.state.rows.length > 0
    ) {
      newValue = newValue - this.state.interval;

      this.notify();
    }

    let text = this.state.scrollText;

    try {
      text = this.state.rows.length > 0 ? this.state.scrollText : TEXT_NO_DATA;
      text = `Mostrando de ${formatDateTime(
        this.state.rows[newValue].data_coleta
      )} até ${formatDateTime(
        this.getCurrentLastValue(newValue).data_coleta
      )} de ${this.state.rows.length} registros`;
    } catch (erro) { }
    this.setState(
      {
        slide_value: newValue,
        scrollText: text,
      },
      () => {
        this.state.chart_refs.forEach((chart) => {
          if (chart.current) {
            chart.current.updateSelecteds();
          }
        });
      }
    );
  };

  render() {
    const ultimas12Faturas = () => {
      const date = new Date();
      const faturas = [];
      for (let i = 0; i < 12; i++) {
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - i, 0);
        faturas.push(moment(lastDay));
      }
      return faturas;
    };

    const handleChangeDateByLastMonth = (date) => {
      const dataFinal = date.format(`YYYY-MM-DD 23:59`);
      const dataInicial = date.format(`YYYY-MM-01 00:00`);

      this.setState({ data_inicio: dataInicial, data_fim: dataFinal });
    };

    return !this.props.chartValues ? (
      <Grid container spacing={2}
        style={{ height: "100vh" }}>
        <Grid container item spacing={2}
          style={{ height: "min-content" }}>
            {!this.props.data &&
          <Grid item xs={12} md={6}>
            <InputLabel align="left" htmlFor="gestor">
              Selecione o Medidor :
            </InputLabel>
            <Select
              fullWidth
              align="left"
              value={`${this.state.gestor}` || ""}

              name="gestor"
              onChange={this.handleChange}
              >
              {
                this.state.gestores.map((ges) => {
                  this.state.farm = ges.fazenda;
                  this.state.nameGestor = `${ges.id_equipamento} - ${ges.nome_bombeamento}`;
                  this.state.device = ges.equipamento;

                  return (
                    <MenuItem key={ges.id} value={ges.id}>
                      {" "}
                      {`${ges.id_equipamento} (${this.state.device}) - ${ges.fazenda}`}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
          }
          <Grid item xs={12} md={3}>
            <InputLabel align="left" htmlFor="age-simple">
              Dê
            </InputLabel>
            <CustomDateTimePicker
              fullWidth
              iconcolor={`${colors.ORANGE_POWER}`}
              variant="inline"
              format="DD/MM/YYYY HH:mm"
              name="data_inicio"
              value={this.state.data_inicio}
              onChange={this.handleChange}
              KeyboardButtonProps={{
                "aria-label": "Mudar data",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputLabel align="left" htmlFor="age-simple">
              Até
            </InputLabel>
            <CustomDateTimePicker
              fullWidth
              iconcolor={`${colors.ORANGE_POWER}`}
              variant="inline"
              format="DD/MM/YYYY HH:mm"
              name="data_fim"
              value={this.state.data_fim}
              onChange={this.handleChange}
              KeyboardButtonProps={{
                "aria-label": "Mudar data",
              }}

            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography style={{ fontSize: 16 }} align="left">
              <label style={{ marginLeft: "7px" }}>Selecionar intervalo pelos meses: </label><br />

              {ultimas12Faturas()
                .reverse()
                .map((date, index) => {
                  return (
                    <span key={index}>
                      <Button
                        style={{
                          textDecoration:
                            this.state.dateSelected === date ? "underline" : "none",
                          textDecorationColor: "green",
                        }}
                        size="small"
                        onClick={(event) => {
                          event.preventDefault();
                          handleChangeDateByLastMonth(date);
                          this.setState({ dateSelected: date });
                        }}
                      >
                        {moment(date).format("MMMM")}
                      </Button>
                      {index < 11 && " | "}
                    </span>
                  );
                })}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: colors.ORANGE_POWER, color: "white", marginTop: 10, boxShadow: "none" }}
              onClick={this.getColetas}
            >
              <SearchRounded style={{ color: colors.WHITE }} /> Filtrar
            </Button>
          </Grid>
        </Grid>
        {(this.state.selected && !this.state.graphicsHidden) &&
          <Grid item container xs={12} md={12}>
            <Grid item container xs={12} md={12} spacing={1}
              style={{ position: 'sticky', top: this.top, zIndex: 100, backgroundColor: "#FAFAFA" }}>
              <Grid item container xs={12} md={9}>
                <Grid item xs={12}>
                  <Typography align="center">{this.state.scrollText}</Typography>
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          this.addSliderValue(-1);
                        }}
                      >
                        <KeyboardArrowLeft color="primary" />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <AirbnbSlider
                        max={this.state.rows.length}
                        value={this.state.slide_value}
                        name="slide_value"
                        onChange={this.handleChangeSlide}
                        color="primary"
                        aria-labelledby="range-slider"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        onClick={() => {
                          this.addSliderValue(1);
                        }}
                      >
                        <KeyboardArrowRight color="primary" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={3}>
                <InputLabel align="left" htmlFor="age-simple">
                  Intervalo
                </InputLabel>
                <Select
                  fullWidth
                  align="left"
                  value={this.state.interval}
                  name="interval"
                  onChange={this.handleChangeInterval}
                >
                  {INTERVALOS.map((i) => {
                    return <MenuItem key={`int_${i.value}`} value={i.value}>{`Ver ${i.desc}`}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Grid>
            <Grid container item xs={12} spacing={1}>
              {[...Array(this.state.chartSelecteds.length).keys()].map((i) => {
                let chartRef = React.createRef();
                this.state.chart_refs.push(chartRef);

                return (
                  <Grid key={i} item xs={12}>
                    <LineChart
                      ref={chartRef}
                      chartType={this.state.chart_type}
                      data={this.getCurrentData()}
                      selected={this.state.selected}
                      chartSize={this.state.chart_size}
                      chartSelecteds={this.state.chartSelecteds[i]}
                      filter={this.state.filter}
                      device={this.state.device}
                      hasInjected={this.state.hasInjected}
                    ></LineChart>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>}
      </Grid>

    ) : (
      <Grid container item xs={12} spacing={2}>
        {[...Array(this.state.chartSelecteds.length).keys()].map((i) => {
          let chartRef = React.createRef();
          this.state.chart_refs.push(chartRef);

          return (
            <Grid item xs={12}>
              <Typography
                style={{ fontWeight: "bold", fontSize: "25px" }}
                align="left"
              >
                {this.nameChart(this.state.chartSelecteds[i])}
              </Typography>
              <LineChart
                ref={chartRef}
                chartType={this.state.chart_type}
                data={this.getCurrentData()}
                selected={this.state.selected}
                chartSize={this.state.chart_size}
                chartSelecteds={this.state.chartSelecteds[i]}
              ></LineChart>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  root: {},
});

export default withStyles(styles)(ChartGestor);
