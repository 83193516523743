import React from "react";
import { createStyles, makeStyles } from '@material-ui/core'
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { colors } from './constants/dc_constants';

const useStylesLogin = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        height: '100vh'
      },
      body: {
        backgroundRepeat: 'no-repeat',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
    }
  })
)

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '.MuiDialogContent-root:first-child': {
        paddingTop: 0,
      }
    },
  }),
)

export const CustomButton = withStyles({
  root: {
    backgroundColor: colors.ORANGE_POWER,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: colors.ORANGE_POWER,
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: colors.ORANGE_POWER,
      boxShadow: 'none',
    },
    '&:focus': {
      backgroundColor: colors.ORANGE_POWER,
      boxShadow: 'none',
    }
  },
  label: {
    textTransform: 'uppercase'
  }
})(props => <Button {...props} />)

export const GlobalStylesLogin = () => {
  useStylesLogin()
  return null
}

export const GlobalStyles = () => {
  useStyles()
  return null
}
