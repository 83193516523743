import { getAxiosInstance } from "./service";

export const concessionariaService = {
	getConcessionaria,
	createConcessionaria,
	updateConcessionaria,
	deleteConcessionaria,
};

//ADD concessionária
async function createConcessionaria(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	response = await getAxiosInstance("/concessionarias")
		.post(undefined, data)
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}

//Atualiza Concessionária
async function updateConcessionaria(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	const id = data.id;
	response = await getAxiosInstance(`/concessionarias/${id}`)
		.put(undefined, data)
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}

//Deleta concessionária
async function deleteConcessionaria(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	const id = data.id;
	response = await getAxiosInstance(`/concessionarias/${id}`)
		.delete(undefined, data)
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}

//Busca concessionárias
async function getConcessionaria(handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	response = await getAxiosInstance(`/concessionarias`)
		.get()
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}
