require("dotenv").config()

import React from "react"
import { render } from "react-dom"
import App from "./app/App"
import "./index.css"
import { BrowserRouter } from "react-router-dom"
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles"
import { colors } from "./app/constants/dc_constants"
import { applyMiddleware } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { Provider } from "react-redux"
import promise from 'redux-promise'

import reducers from "./app/reducers/reducers"

const theme = createTheme({
  palette: {
    primary: { 500: colors.PRIMARY },
    secondary: {
      main: colors.SECONDARY,
    },
  },
  action: colors.GREEN,
  status: {
    danger: "orange",
  },
})

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
const store = configureStore(
  {reducer: reducers}
)

render(
  <BrowserRouter>
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
)
