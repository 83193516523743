import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { logService } from '../services/log_service'
import { validateShowResponseToast } from '../helpers/toast_alerts'
import { List, ListItem, ListItemText, ListItemIcon, Typography, Paper } from '@material-ui/core'
import { KeyboardArrowRight } from '@material-ui/icons'
import moment from 'moment'
import { colors, logStatus } from '../constants/dc_constants'

const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        minHeight: 400
    },
    typography: {
        color: colors.PRIMARY,
        marginTop: 10,
        marginBottom: 10
    },
    listitemtext: {
        color: colors.PRIMARY
    },
    listitemicon: {
        color: colors.SECONDARY
    }
})

class Logs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            updateInterval: 5000
        }
        this.intervalId = 0
    }

    getColorByStatus = (status) => {
        switch (status) {
            case logStatus.LOG_SUCCESS:
                return colors.GREEN
            case logStatus.LOG_PARTIAL_SUCCESS:
                return colors.SECONDARY
            case logStatus.LOG_FAIL:
                return 'red'
            default:
                break;
        }
    }

    getLogs = () => {
        logService.getLogs(response => {
            if (validateShowResponseToast(response, "", false)) {
                this.setState({
                    data: response.data
                })
            }
        })
    }

    componentDidMount() {
        try {
            this.intervalId = setInterval(async () => {
                this.getLogs()
            }, this.state.updateInterval);
            this.getLogs()
        } catch (e) {
            console.log(e);
        }
    }
    
    componentWillUnmount() {
        clearInterval(this.intervalId)
    }

    render() {
        const { classes } = this.props
        return (
            <Paper className={classes.card}>
                <Typography className={classes.typography} variant="h5">Histórico</Typography>
                <List className={classes.root}>
                    {
                        this.state.data.map(dt => {
                            return <div>
                                <ListItem alignItems="flex-start">
                                    <ListItemIcon>
                                        <KeyboardArrowRight style={{ color: this.getColorByStatus(dt.status) }}></KeyboardArrowRight>
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.listitemtext}
                                        style={{ color: this.getColorByStatus(dt.status) }}
                                        primary={`${moment(dt.createdAt).format("DD/MM/YYYY HH:mm:ss")} ->  ${dt.message}`}
                                    />
                                </ListItem>
                            </div>
                        })
                    }

                </List>
            </Paper>
        )

    }
}

export default withStyles(styles)(Logs)