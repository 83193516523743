import React from 'react'
import PropTypes from 'prop-types'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import { DateRangeRounded } from '@material-ui/icons'
import moment from 'moment'

export default class CustomDateTimePicker extends React.Component{

	handleChangeDate = (value, name) => {
	   return {
		   target: {
			   value: moment(value).format('YYYY-MM-DD HH:mm:ss'),
			   name
		   }
	   }
	}

	render(){
		const color = this.props.iconcolor || 'primary'
		const { onChange } = this.props

		return <KeyboardDateTimePicker
			{...this.props}
			onChange={(e) => onChange(this.handleChangeDate(e, this.props.name))}
			autoOk={true}
			ampm={false}
			minDateMessage="Data e hora está fora do valor mínimo permitido"
			maxDateMessage="Data e hora está fora do valor máximo permitido"
			invalidLabel="Inválido"
			invalidDateMessage="Data e hora é inválida"
			keyboardIcon={<DateRangeRounded style={{color: color}}/>}
		>
		</KeyboardDateTimePicker>
	}
}

CustomDateTimePicker.propTypes = {
	onChange: PropTypes.func.isRequired
}
