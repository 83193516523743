import string_similarity from 'string-similarity'
import { sortByDate } from './utils'
import moment from 'moment'
import { calculateKWFromColeta, calculateKWAparente } from './gestor_utils'
import { ControlCameraTwoTone } from '@material-ui/icons'
const FATOR_CONVERSAO = 16384
const ABNT_FATOR_NORMALIZADOR_HORA = 4;
const ABB_NAME = 'ABB'
const ABNT_NAME = 'ABNT'

function floorTo2(value){
    return Math.floor(value * 100) / 100
}

/** Este metodo altera os atributos de coleta */
function convertTensao(coleta, tp) {
    const values = ['tensao_l1_neutro',  'tensao_l2_neutro', 'tensao_l3_neutro',
    'tensao_l1_l2', 'tensao_l2_l3',  'tensao_l3_l1', ]
    values.forEach(vl => {
        coleta[vl] = floorTo2((coleta[vl] * tp) / FATOR_CONVERSAO)
    })
}

/** Este metodo altera os atributos de coleta */
function convertCorrente(coleta, tc) {
    const values = ['corrente_i1',  'corrente_i2', 'corrente_i3',  'corrente_n', ]
    values.forEach(vl => {
        coleta[vl] = floorTo2((coleta[vl] * tc) / FATOR_CONVERSAO)
    })
}

/** Este metodo altera os atributos de coleta */
function convertFatorPotencia(coleta, mult = 1) {
    const values = [ 'fator_potencia_total', ]
    values.forEach(vl => {
        coleta[vl] = floorTo2((coleta[vl] * mult) / FATOR_CONVERSAO)
    })
}

/** Este metodo altera os atributos de coleta */
function convertFrequencia(coleta, mult = 100) {
    const values = ['frequencia']
    values.forEach(vl => {
        coleta[vl] = floorTo2((coleta[vl] * mult) / FATOR_CONVERSAO)
    })
}

export function convertLastDemandData(data) {
    let converted_data = {
        fator_potencia_total: 0,
        potencia_ativa_total: 0,
        potencia_injetada: 0,
    };

    converted_data.fator_potencia_total = floorTo2(Math.cos(Math.atan(data.energia_consumida_kvarh/data.energia_consumida_kwh)));
    converted_data.potencia_ativa_total = floorTo2(data.energia_consumida_kwh * ABNT_FATOR_NORMALIZADOR_HORA);
    converted_data.potencia_injetada = (data.energia_injetada_kwh*4) ?? 0;

    return converted_data;
}

export function convertData(coletas, gestor) {
    if(! gestor) {
        return coletas
    }

    coletas = sortByDate(coletas, 'data_coleta');

    if (gestor.equipamento.toLowerCase() == ABB_NAME.toLowerCase()) {
        if(gestor.gateway.toLowerCase() == "ABS".toLowerCase()) return convertAbbAbsData(coletas, gestor);
        else return convertAbbData(coletas, gestor);
    } else {
        return convertAbntData(coletas, gestor);
    }
}

// Somente realiza a conversao se o equipamento for ABB
export function convertAbbData(coletas, gestor) {
    try{
        if (string_similarity.compareTwoStrings(ABB_NAME.toLowerCase(), gestor.equipamento.toLowerCase()) < 0.85) {
            return coletas
        }
        const tp1 = gestor.transf_potencia1
        const tc = gestor.tensao_corrente
        let lastColeta = null
        coletas = coletas.map(coleta => {
            coleta.data_coleta = moment.utc(coleta.data_coleta).format('YYYY-MM-DD HH:mm:ss')
            convertTensao(coleta, tp1)
            convertCorrente(coleta, tc)
            convertFatorPotencia(coleta)
            convertFrequencia(coleta)
            if(coleta.potencia_ativa_total === 0 && coleta.potencia_reativa_total === 0){
                coleta.potencia_ativa_total = calculateKWFromColeta(coleta, lastColeta, 'energia_consumida_kw_acc')
                coleta.potencia_reativa_total = calculateKWFromColeta(coleta, lastColeta, 'energia_consumida_kwar_acc')
                coleta.potencia_aparente_total =  calculateKWAparente(
                    coleta.potencia_ativa_total,
                    coleta.potencia_reativa_total
                )
            }
            coleta.potencia_injetada = (coleta.energia_injetada_kwh*4) ?? 0;
            lastColeta = coleta
            return coleta
        })
        return coletas
    }catch(error){
        return coletas
    }
}

export function convertAbbAbsData(coletas, gestor) {
    try{
        if (string_similarity.compareTwoStrings(ABB_NAME.toLowerCase(), gestor.equipamento.toLowerCase()) < 0.85) {
            return coletas
        }
        const tp1 = gestor.transf_potencia1
        const tc = gestor.tensao_corrente
        let lastColeta = null
        coletas = coletas.map(coleta => {
            coleta.data_coleta = moment.utc(coleta.data_coleta).format('YYYY-MM-DD HH:mm:ss');
            lastColeta = coleta

            coleta.potencia_injetada = (coleta.energia_injetada_kwh*4) ?? 0;
            return coleta
        })
        return coletas
    }catch(error){
        return coletas
    }
}

// Somente realiza a conversao se o equipamento for ABNT
// Fator de Potência, Potência Aparente, Potencia Ativa
export function convertAbntData(coletas, gestor) {
    try{
        if (string_similarity.compareTwoStrings(ABNT_NAME.toLowerCase(), gestor.equipamento.toLowerCase()) < 0.85) {
            return coletas
        }
        let lastColeta = null
        coletas = coletas.map(coleta => {

            coleta.data_coleta = moment.utc(coleta.data_coleta).format('YYYY-MM-DD HH:mm:ss')
            coleta.fator_potencia_total = floorTo2(Math.cos(Math.atan(coleta.energia_consumida_kvarh/coleta.energia_consumida_kwh)));
            coleta.potencia_ativa_total = floorTo2(coleta.energia_consumida_kwh * ABNT_FATOR_NORMALIZADOR_HORA);
            coleta.potencia_reativa_total = floorTo2(coleta.energia_consumida_kvarh * ABNT_FATOR_NORMALIZADOR_HORA);
            coleta.potencia_aparente_total = floorTo2(coleta.potencia_ativa_total / coleta.fator_potencia_total);

            //verificar se o quadrante esta correto
            if( coleta.quadrante == 3 || coleta.quadrante == 4){
                coleta.fator_potencia_total = coleta.fator_potencia_total * -1;
            }

            if(coleta.potencia_ativa_total === 0 && coleta.potencia_reativa_total === 0){
                coleta.potencia_ativa_total = calculateKWFromColeta(coleta, lastColeta, 'energia_consumida_kw_acc')
                coleta.potencia_reativa_total = calculateKWFromColeta(coleta, lastColeta, 'energia_consumida_kwar_acc')
                coleta.potencia_aparente_total =  calculateKWAparente(
                    coleta.potencia_ativa_total,
                    coleta.potencia_reativa_total
                )
            }

            coleta.potencia_injetada = (coleta.energia_injetada_kwh*4) ?? 0;
            lastColeta = coleta
            return coleta
        })
        return coletas
    }catch(error){
        return coletas
    }
}