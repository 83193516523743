import React from "react"
import styles from "./TelaDashboard.module.css"
import ComplexCard from "./ComplexCard"
import { gestorService } from "../../services/gestor_service";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import moment from "moment";
import { alertaService } from "../../services/alerta_service";
import { sortAlertLogs } from "../../util/utils";
import { FormControl, InputLabel, NativeSelect } from "@material-ui/core";

class TelaDashboard extends React.Component {
	constructor(props) {
		super(props);
		var user = JSON.parse(localStorage.getItem("user"));
		var permission = user["permissao"];

		this.state = {
			gestores: [],
			online: [],
			hasEditPermission: permission >= 3,
			statusEquipment: "1",
			alertRows: [],
			users: [],
			user_id: "",
		};

		this.handleChangeClient = this.handleChangeClient.bind(this);
	}

	getGestores = (statusEquipment) => {
		gestorService.getGestores("water", { statusEquipments: this.state.statusEquipment }, (response) => {
			if (validateShowResponseToast(response, "", false)) {
				const gestores = response.data;
				let users = [{ info: response.data[0].usuario, meters: [] }];
				let i = 0;
				gestores.forEach(gestor => {
					if (gestor.usuario.id == users[i].info.id) {
						users[i].meters.push(gestor);
					} else {
						i++;
						users.push({ info: gestor.usuario, meters: [gestor] })
					}
				});

				this.setState({
					gestores: users[0].meters,
					online: gestores.coletas,
					users: users,
					user_id: users[0].info.id
				});

				this.getLogAlerta(users[0].meters)
			}
		});
	};

	getLogAlerta = (gestores) => {
		let requests = gestores.map(gestor =>
			new Promise((resolve) => {
				alertaService.getAlerta(gestor.id, (response) => {
					if (response && response.data && response.data[0] && response.data[0].logs) {
						const logs = response.data[0].logs.map(item => {
							let alertText = `${gestor.fazenda} ${item.descricao} às ${moment(item.datahora_coleta).format('HH:mm')} | ${moment(item.datahora_coleta).format('DD/MM/YYYY')}`;
							return { text: alertText, time: item.datahora_coleta };
						});
						resolve(logs);
					} else {
						resolve([]);
					}
				});
			})
		);

		Promise.all(requests).then(results => {
			let rows = results.flat();
			const rowsSorted = rows.sort((a, b) => moment(b.time).diff(a.time)).slice(0, 5);
			this.setState({
				alertRows: rowsSorted
			});
		});
	};

	componentDidMount() {
		this.getGestores();
	}

	handleChangeClient(e) {
		const user = this.state.users.find(user => user.info.id == e.target.value);

		this.setState({
			user_id: e.target.value,
			gestores: user.meters,
		}, () => {
			this.getLogAlerta(user.meters)
		})
	}

	render() {
		return (
			<div className={styles.container}>
				<div className={styles.header}>
					<div className={styles.headerInfo}>
						<div className={styles.headerTitle}>Dashboard</div>
						<div className={styles.headerDate}>{moment().format('LL')}</div>
					</div>
					{this.state.hasEditPermission &&
						<div>
							<FormControl>
								<InputLabel shrink={true}>
									<span style={{ fontSize: 18 }}>Selecione o cliente</span>
								</InputLabel>
								<NativeSelect
									style={{ width: "12rem", height: "1.4rem" }}
									fullWidth
									id="Selecione o cliente"
									label="Selecione o cliente"
									value={this.state.user_id}
									name="user_id"
									onChange={this.handleChangeClient}
								>
									{
										this.state.users.map((user) => {
											return (
												<option key={user.info.id} value={user.info.id}>
													{user.info.nome}
												</option>
											)
										})
									}
								</NativeSelect>
							</FormControl>
						</div>
					}
				</div>
				<div className={styles.cardContainer}>
					{this.state.gestores.map((gestor) => {
						return (
							<ComplexCard
								key={gestor.id}
								gestor={gestor}
							/>
						)
					})}
				</div>
				<div className={styles.alertsContainer}>
					<b>Alertas</b>
					<ul>
						{this.state.alertRows.map(row => {
							return (
								<li>{row.text}</li>
							)
						})}
					</ul>
				</div>
			</div>
		)
	}
}

export default TelaDashboard