import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Grid,
} from "@material-ui/core";
import { toast } from "react-toastify";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeigth: 600,
    maxWidth: 700,
    minWidth: 700,
  },
  formControl: {
    minWidth: "200px",
  },
  container: {
    marginTop: "15px",
  },
});

class FechamentoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nome: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const data = this.props.data;
    this.setState({ ...data });
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action(onClose) {
    if (this.state.nome === "") {
      toast.info("Descrição não pode ficar em branco!");
      return;
    }
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state, onClose);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <Grid item xs={12} className={classes.container}>
            <TextField
              id="tf_nome"
              label="Descrição do Fechamento"
              name="nome"
              fullWidth
              type="text"
              value={this.state.nome}
              onChange={this.handleChange}
            ></TextField>
          </Grid>
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(FechamentoForm);
