export function buildCustomSymbol(color = '#201E29', circleColor = '#FFE1D6') {
    const svgIcon = `<svg
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    version="1.1"
    id="Laag_1"
    x="0px"
    y="0px"
    viewBox="0 0 33 50"
    style="enable-background:new 0 0 33 50;"
    xml:space="preserve"
    sodipodi:docname="novo.svg"
    inkscape:version="0.92.3 (2405546, 2018-03-11)"><metadata
    id="metadata13"><rdf:RDF><cc:Work
        rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
          rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title></dc:title></cc:Work></rdf:RDF></metadata><defs
    id="defs11" />
  <style
    type="text/css"
    id="style2">
   .st0{fill:${color};}
  </style>
  <g
    id="g6">
   <path
    class="st0"
    d="M33,17.8C33,8,25.6,0,16.5,0C7.4,0,0,8,0,17.8c0,1.9,0.3,3.8,0.8,5.5c0,0,1.4,6.7,15.6,26.7   c8.1-11.5,12.1-18.6,13.9-22.5C32,24.7,33,21.3,33,17.8L33,17.8z M16.3,29.2c-5.8,0-10.5-5-10.5-11.3c0-6.2,4.7-11.3,10.5-11.3   c5.8,0,10.4,5,10.4,11.3C26.8,24.2,22.1,29.2,16.3,29.2L16.3,29.2z M16.3,29.2"
    id="path4" />
  </g>
  <ellipse
    id="path3726"
    cx="16.313559"
    cy="17.478815"
    rx="12.076271"
    ry="12.394068"
    style="fill:${circleColor}; stroke:black; stroke-width:1;" /></svg>`;
    return `data:image/svg+xml;charset=UTF-8;base64,${btoa(svgIcon)}`;
  }