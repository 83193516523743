import { getAxiosInstance } from "./service";

export const outorgaService = {
    createOutorga,
	updateOutorga,
}

//CREATE OUTORGA
async function createOutorga(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	response = await getAxiosInstance("/outorgas")
		.post(undefined, data)
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}

async function updateOutorga(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	response = await getAxiosInstance(`/outorgas`)
		.put(undefined, data)
		.catch((error) => {
			errorResponse = error.response || undefined;
		});
	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}