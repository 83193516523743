import { Button, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  Add,
  Delete,
  FilterList,
  PrintRounded,
  RemoveRedEye,
  EventAvailable,
} from "@material-ui/icons";
import React from "react";
import { colors } from "../../constants/dc_constants";
import { headRowsResumo } from "../../constants/headRows";
import {
  makeActionFormDialog,
  makeAlertDialog,
} from "../../default/dialogs/DialogRefInterface";
import ObjectTable from "../../default/table/ObjectTable";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { gestorService } from "../../services/gestor_service";
import { resumoService } from "../../services/resumo_service";
import FechamentoView from "./FechamentoView";
import moment from "moment";
import { template_fechamento } from "../../default/templates_pdf/template_relatorio";
import browserHistory from "../../helpers/browser_history"

class TableResumoFechamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headRows: headRowsResumo,
      gestores: [],
      rows: [],
      gestor: null,
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      this.handleFilter();
    });
  };

  handleFilter = () => {
    let query = {};
    if (this.state.gestor) query.gestor_id = this.state.gestor;
    this.getResumos(query);
  };

  handleShow = (resumoData) => {
    const Instance = (props) => (
      <FechamentoView
        data={props.data}
        gestor={props.data?.gestor}
        dataInicial={props.data?.data_inicial}
        dataFinal={props.data?.data_final}
      />
    );

    makeActionFormDialog(
      Instance,
      "",
      false,
      resumoData,
      "",
      () => {}
    ).current.open();
  };

  handlePrintPDF = (resumoData) => {
    this.exportPDF(resumoData);
  };

  handleDelete = (resumoData) => {
    makeAlertDialog(
      () => {
        resumoService.deleteResumo(resumoData, (response) => {
          if (validateShowResponseToast(response, "", true, true)) {
            this.handleFilter();
          }
        });
      },
      "Atenção!",
      "Deseja realmente excluir este dado?"
    ).current.open();
  };

  handleNew = () => {
    browserHistory.push("/dashboard/fechamentos/novo");
  };

  getGestores = () => {
    gestorService.getGestores("power",{}, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  getResumos = (query) => {
    resumoService.getResumos(query, (response) => {
      if (this.state.gestor){
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          rows: response.data,
        });
      }
    }
    });
  };

  exportPDF = (resumoData) => {
    const pdfName = String(resumoData.nome)
      .toLocaleLowerCase()
      .replace(" ", "_");
    template_fechamento(resumoData, {
      orientation: "p",
      title: " ",
      data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
      equipamento: resumoData.gestor.id_equipamento,
      local: resumoData.gestor.fazenda,
      regiao: resumoData.gestor.regiao,
      data_inicio: resumoData.data_inicial,
      data_final: resumoData.data_final,
    }).save(pdfName);
  };

  componentDidMount() {
    this.getGestores();
    this.getResumos({ limit: 1000 });
  }

  selectedToolActions = [];

  toolActions = [];

  actions = [
    {
      title: "Gráficos Online",
      color: colors.GREEN,
      MaterialIcon: RemoveRedEye,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.handleShow(selected);
      },
    },
    {
      title: "Imprimir",
      color: colors.GREEN,
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.handlePrintPDF(selected);
      },
    },
    {
      title: "Excluir",
      color: colors.GREEN,
      MaterialIcon: Delete,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.handleDelete(selected);
      },
    },
  ];

  iconHead = [
    {
      MaterialIcon: EventAvailable,
      color: colors.ORANGE_POWER,
    },
  ];

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <InputLabel align="left" htmlFor="gestor">
            Selecione o Medidor :{" "}
          </InputLabel>
          <Select
            fullWidth
            align="left"
            value={this.state.gestor || ""}
            name="gestor"
            onChange={this.handleChange}
          >
            {this.state.gestores.map((ges) => (
              <MenuItem
                key={ges.id}
                value={ges.id}
              >{`${ges.id_equipamento} - ${ges.fazenda}`}</MenuItem>
            ))}
          </Select>
        </Grid>

        {/*   ANTIGO BOTÃO DE FILTRAR (não é mais necessario)
        <Grid item md={2} xs={12}>
          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: colors.ORANGE_POWER,
              color: "white",
              marginTop: 10,
              boxShadow: "none"
            }}
            onClick={this.handleFilter}
          >
            <FilterList /> Filtrar
          </Button>
        </Grid>
        */}

        <Grid item md={3} xs={1}></Grid>

        <Grid item md={3} xs={12}>
          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: colors.ORANGE_POWER,
              color: "white",
              marginTop: 10,
              boxShadow: "none"
            }}
            onClick={this.handleNew}
          >
            <Add /> Novo Fechamento
          </Button>
        </Grid>

        <Grid item md={12}>
          <ObjectTable
            rowId="id"
            tableName="Histórico de Fechamentos"
            materialIconHead={this.iconHead}
            headRows={this.state.headRows}
            actions={this.actions}
            selectedToolActions={this.selectedToolActions}
            toolActions={this.toolActions}
            rowSize={40}
            rowsOptions={[20, 40, 80]}
            dense={false}
            rows={this.state.rows}
            order={"desc"}
          />
        </Grid>
      </Grid>
    );
  }
}

export default TableResumoFechamento;
