import React from "react";
import {
  ClearRounded,
  PrintRounded,
  CloudDownloadRounded,
} from "@material-ui/icons";

import { headRowsRelatorio } from "../constants/headRows";
import ObjectTable from "../default/table/ObjectTable";
import {
  makeProgressDialog,
  closeProgressDialog,
} from "../default/dialogs/DialogRefInterface";
import { template_relatorio } from "../default/templates_pdf/template_relatorio";
import moment from "moment";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import { colors } from "../constants/dc_constants";
import { gestorService } from "../services/gestor_service";

class TableRelatorioAneel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rows: [],
      headRows: headRowsRelatorio
    };

    this.objectTableRef = React.createRef();
  }

  getColetas = () => {
    const data = {
      gestorId:
        this.props.data && this.props.data.selected
          ? this.props.data.selected.id
          : 0,
      dataInicial: this.props.data.data_inicial,
      dataFinal: this.props.data.data_final,
    };
    makeProgressDialog(null, "Gerando Relatório...").current.open();
    gestorService.getRelatorioAneel(data, (response) => {
      if (
        validateShowResponseToast(
          response,
          "",
          true,
          true,
          `Dados de coletas carregados`
        )
      ) {
        this.setState({
          rows: response.data,
        });
      }
      closeProgressDialog();
    });
  };

  componentDidMount() {
    if (this.props.rows === undefined || this.props.rows === null) {
      this.getColetas();
    }
  }

  getDataHora = (index) => {
    if (this.state.rows[index]) {
      return moment(this.state.rows[index].data_hora).format(
        "DD/MM/YYYY - HH:mm:ss"
      );
    } else {
      return "Inválido";
    }
  };

  selectedToolActions = [
    {
      title: "Limpar Seleção",
      label: "Adicionar",
      MaterialIcon: ClearRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.updateTable({ selected: [] });
      },
      color: "secondary",
    },
  ];

  toolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      color: colors.GREEN,
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        template_relatorio(
          this.state.rows && this.state.rows.length > 0
            ? this.state.rows
            : this.props.rows || [],
          headRowsRelatorio,
          {
            orientation: "l",
            title: "Relatório: Análise de Demanda",
            data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
            equipamento: this.props.data.selected.equipamento,
            local: this.props.data.selected.fazenda,
            regiao: this.props.data.selected.regiao,
            data_inicio: this.getDataHora(0),
            data_final: this.getDataHora(this.state.rows.length - 1),
          }
        ).save(`relatorio_demanda_${moment().format("DD_MM_YYYY")}`);
      },
    },
    {
      title: "Baixar XLS",
      label: "Baixar",
      color: colors.GREEN,
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.getExportRowsXls(null, true, false);
      },
    },
  ];
  actions = [];

  render() {
    return (
      <ObjectTable
        rowId="id"
        tableName="Relatório Aneel"
        headRows={this.state.headRows}
        actions={this.actions}
        selectedToolActions={this.selectedToolActions}
        toolActions={this.toolActions}
        rowSize={40}
        rowsOptions={[40, 100, 200]}
        dense={true}
        ref={this.objectTableRef}
        rows={
          this.state.rows && this.state.rows.length > 0
            ? this.state.rows
            : this.props.rows || []
        }
        showCheckBox={false}
      />
    );
  }
}

export default TableRelatorioAneel;
