import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { FormControl, TextField, NativeSelect, Grid, InputLabel } from '@material-ui/core'
import { toast } from 'react-toastify'
import { filterAndDeleteValues } from '../helpers/form_helpers'

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	form: {
		minHeigth: 600,
		maxWidth: 700,
		minWidth: 700
	},
	formControl: {
		minWidth: "200px"
	},
	container: {
		marginTop: "15px"
	}
})

class FormUsuario extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			id: 0,
			nome: "",
			email: "",
			senha: "",
			usuario: "",
			confirm_senha: "",
			status: 1,
			permissao: 1,
			produto: 0,
			token: "",
		}

		this.handleChange = this.handleChange.bind(this)

	}

	componentDidMount() {
		const data = this.props.data
		this.setState({ ...data })
	}

	// Necessario em todos os form abertos em ActionFormDialog
	action() {
		const dataValues = filterAndDeleteValues(this.state, {nome: [''], email: [''], senha: [''], usuario: ['']})

		if (this.props.handleFormState && (this.state.senha.length == 0 || this.state.senha.length >= 3)) {
			if (this.props.handleFormState && this.state.confirm_senha === this.state.senha) {
				this.props.handleFormState(dataValues)
			} else {
				toast.warn("Senhas não conferem!")
			}
		} else {
			toast.warn("A senha precisa ter pelo menos 3 caracteres")
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	render() {
		const { classes } = this.props
		return (
			<form className={
				classes.form}
				noValidate
				onSubmit={e => { e.preventDefault() }}
			>
				<FormControl variant="outlined" fullWidth className={classes.formControl}>
					<Grid item xs={12} className={classes.container}>
						<TextField
							id="tf_nome"
							label="Nome Completo"
							name="nome"
							fullWidth
							type="text"
							value={this.state.nome}
							onChange={this.handleChange}
						>
						</TextField>
					</Grid>
					<Grid item xs={12} className={classes.container}>
						<TextField
							id="tf_usuario"
							label="Nome de Usuário"
							name="usuario"
							fullWidth
							type="text"
							value={this.state.usuario}
							onChange={this.handleChange}
						>
						</TextField>
					</Grid>
					<Grid item xs={12} className={classes.container}>
						<TextField
							id="tf_email"
							label="Email"
							name="email"
							fullWidth
							type="email"
							value={this.state.email}
							onChange={this.handleChange}
						>
						</TextField>
					</Grid>
					<Grid container className={classes.container} spacing={2}>
						<Grid item xs={6} className={classes.container}>
							<TextField
								id="tf_senha"
								label="Senha"
								name="senha"
								fullWidth
								type="password"
								value={this.state.senha}
								onChange={this.handleChange}
								inputProps={{ maxLength: 24 }}
							>
							</TextField>
						</Grid>
						<Grid item xs={6} className={classes.container}>
							<TextField
								id="tf_confirm_senha"
								label="Confirmar Senha"
								name="confirm_senha"
								fullWidth
								type="password"
								value={this.state.confirm_senha}
								onChange={this.handleChange}
								inputProps={{ maxLength: 24 }}
							>
							</TextField>
						</Grid>
						<Grid item xs={12} className={classes.container}>
							<TextField
								disabled={true}
								id="tf_token"
								label="Tokem"
								name="token"
								fullWidth
								type="text"
								value={this.state.token}
								onChange={this.handleChange}
							>
							</TextField>
						</Grid>
					</Grid>
					<Grid container spacing={2} className={classes.container}>
						<Grid item xs={12} md={4}>
						<FormControl fullWidth>
								<InputLabel >Status</InputLabel>
							<NativeSelect
								fullWidth
								value={this.state.status}
								name='status'
								onChange={this.handleChange}
							>
								<option value={1}>Ativo</option>
								<option value={0}>Inativo</option>
							</NativeSelect>
						</FormControl>
						</Grid>
						<Grid item xs={12} md={4} >
							<FormControl fullWidth>
								<InputLabel >Tipo de Usuário</InputLabel>
								<NativeSelect
									fullWidth
									value={this.state.permissao}
									name='permissao'
									onChange={this.handleChange}
								>
									<option value={1}>1 - Proprietários ( Clientes )</option>
									<option value={2}>2 - Adminstrador Médio</option>
									<option value={3}>3 - Administrador Alto</option>
								</NativeSelect>
							</FormControl>
						</Grid>
						<Grid item xs={12} md={4} >
							<FormControl fullWidth>
								<InputLabel >Produto</InputLabel>
								<NativeSelect
									fullWidth
									value={this.state.produto}
									name='produto'
									onChange={this.handleChange}
								>
									<option value={0}>Energia</option>
									<option value={1}>Agua</option>
									<option value={2}>Energia + Agua</option>
								</NativeSelect>
							</FormControl>
						</Grid>
					</Grid>

				</FormControl>
			</form>
		)

	}
}

export default withStyles(styles)(FormUsuario)