import React from 'react'
import {Grid, DialogTitle, Dialog, CircularProgress} from '@material-ui/core'

class ProgressDialog extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			open : this.props.open || false,
			timeAutoClose : this.props.timeAutoClose || 0,
			title :  this.props.title || "",
			handleFinish : this.props.handleFinish || undefined
		}
		this.handleCancel = this.handleCancel.bind(this)
	}

	closeAfterTime(time){
		setTimeout( () =>
			this.setState({open: false})
		, time);
	}

	update_ref_state(handleFinish=null, title='', timeAutoClose=0){
		this.setState({handleFinish:handleFinish, title:title, timeAutoClose:timeAutoClose})
		if(timeAutoClose > 0){
			this.closeAfterTime(timeAutoClose)
		}
	}

	handleCancel(e){
		this.setState({open: false})
	}

	open(){
		this.setState({open: true})
		 if(this.state.timeAutoClose > 0){
			setTimeout(this.handleCancel, this.state.timeAutoClose)
		}
	}

	close(){
		this.setState({open: false})
	}

	render(){
		return (
			<div>
			  <Dialog
				disableEscapeKeyDown={true}
				open={this.state.open}
				onClose={this.handleCancel}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle align="center" id="alert-dialog-title">{this.state.title}</DialogTitle>
				<Grid
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justifyContent="center"
					style={{ minHeight: '250px', minWidth: '300px'}}
				>
					<CircularProgress size={120} thickness={2}></CircularProgress>
				</Grid>
			  </Dialog>
			</div>
		)
	}
}

export default ProgressDialog
