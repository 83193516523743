import React from 'react'
import {DialogContent, DialogActions, DialogContentText,
  Button, DialogTitle, Dialog} from '@material-ui/core'
import { colors } from '../../constants/dc_constants'

class AlertDialog extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      open : this.props.open ? this.props.open : false,
      title : this.props.title ? this.props.title : "",
      msg : this.props.msg ? this.props.msg : "",
      handleAgree : this.props.handleAgree ? this.props.handleAgree : () => {console.log("undefined")}
    }
    this.handleCancel = this.handleCancel.bind(this)
    this.handleAgreeAndClose = this.handleAgreeAndClose.bind(this)
  }

  update_ref_state(handleAgree, title="", msg=""){
    this.setState({handleAgree : handleAgree, title : title, msg : msg})
  }

  handleCancel(e){
    this.setState({open: false})
  }

  handleAgreeAndClose(e){
    if(this.state.handleAgree) this.state.handleAgree()
    this.handleCancel()
  }

  open(e){
    this.setState({open: true})
  }

  render(){
    return (
      <div>
        <Dialog
          disableEscapeKeyDown={true}
          open={this.state.open}
          onClose={this.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.state.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.msg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="default">
              Cancelar
            </Button>
            <Button onClick={this.handleAgreeAndClose} style={{color: colors.ORANGE_POWER}} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default AlertDialog
