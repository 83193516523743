import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { userService } from '../services/user_service';
import { toast } from 'react-toastify'
import { validateShowResponseToast } from '../helpers/toast_alerts';
import { authConstants, colors } from '../constants/dc_constants';
import { CustomButton } from '../GlobalStyles'

class FormRecover extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			senha: '',
			confirm_senha: '',
		};

		this.handleClick = this.handleClick.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
	}

	switch_route = (route) => {
		if (this.props.browserHistory) {
			this.props.browserHistory.push(route)
		}
	}

	requestRecover = () => {
		const data = { senha: this.state.senha }
		data.token = this.props.match.params.token
		if (this.state.senha !== '' && this.state.senha === this.state.confirm_senha) {
			userService.recoverSenha(data, response => {
				if (validateShowResponseToast(response, "", false)) {
					toast.success("Nova senha cadastrada com sucesso!")
				}
			})
		} else {
			toast.warn("Senhas não conferem")
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleKeyDown(e) {
		if (e.key === 'Enter') {
			this.requestRecover()
		}
	}

	handleClick(e) {
		e.preventDefault();
		this.requestRecover()
	}

	render() {
		const { classes } = this.props;
		return (
			<Container component="main" maxWidth={false}>
				<CssBaseline />
				<div className={classes.formContainer}>
					<div className={classes.iconRight}></div>
					<div className={classes.paper}>
						<img
              className={classes.logo}
              src="/static/images/logo.png"
              alt="Logo iCrop Power"
            >
            </img>
						<form className={classes.form} noValidate>
							<TextField
                className={classes.textStyle}
                variant="outlined"
                required
                fullWidth
                name="senha"
								label="Senha"
								type="password"
								id="senha"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={this.state.senha}
                autoComplete=""
                autoFocus
              />
              <TextField
                className={classes.textStyle}
                variant="outlined"
                required
                fullWidth
                name="confirm_senha"
								label="Confirmar Senha"
								type="password"
								id="confirm_senha"
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
                value={this.state.confirm_senha}
              />
							<CustomButton
								fullWidth
								onClick={this.handleClick}
								variant="contained"
							>
								Trocar Senha
							</CustomButton>
							<Grid container justifyContent="center" style={{marginTop: 20}}>
								<Grid item xs={6}>
									<Link onClick={e => this.switch_route('/')} variant="body2" style={{color: colors.ORANGE_POWER, cursor: 'pointer'}}>
										Voltar para login
									</Link>
								</Grid>
							</Grid>
						</form>
					</div>
				</div>
			</Container>
		);
	}
}

const styles = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '0',
    alignItems: 'center',
    minHeight: '100vh'
  },
  formContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 0 20px rgba(0,0,0,0.2)'
  },
  iconRight: {
    backgroundColor: "#1e2142",
    backgroundImage: 'url("/static/images/icon-power.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 130,
    width: 180,
    height: 350,
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  paper: {
    width: 400,
    height: 350,
    backgroundColor: colors.WHITE,
    padding: 40,
    borderRadius: '0 6px 6px 0',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      borderRadius: 6,
      maxWidth: 400,
      width: '100%'
    },
  },
  submit: {
    backgroundColor: colors.ORANGE_POWER,
    boxShadow: 'none'
  },
  textStyle: {
    backgroundColor: colors.WHITE,
    marginBottom: 10,
    width: '100%'
  },
  logo: {
    width: '60%',
    minWidth: '120px',
    marginBottom: 15
  }
});

export default withStyles(styles)(FormRecover);
