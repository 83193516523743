import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Redirect, useLocation } from "react-router-dom";

export default function TokenLogin() {
  const [loaded, setLoaded] = useState(false);
  const search = useLocation().search;

  useEffect(() => {
    const queryToken = new URLSearchParams(search).get("token");
    if (queryToken) {
      const token = { token: queryToken };
      localStorage.setItem("user", JSON.stringify(token));
      setLoaded(true);
    }
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div>
        <img
          alt="logo"
          style={{ margin: 10 }}
          width={"60%"}
          src="/static/images/logo.png"
        ></img>
        {loaded ? (
          <Redirect to={"/#/dashboard"} />
        ) : (
          <Typography component="h1" variant="h5">
            Entrando...
          </Typography>
        )}
      </div>
    </Container>
  );
}
