import React from "react";
import { Typography, Container } from "@material-ui/core";
import moment from "moment";

const styles = {
  container: {
    maxWidth: 400,
    minWidth: 400,
  },
};

export default class ShowGestor extends React.Component {
  render() {
    const gestor = this.props.data;
    const nomeCliente = gestor.usuario ? gestor.usuario.nome : "";
    const concessionariaNome = gestor.concessionaria ? gestor.concessionaria.nome : "";
    return (
      <Container id="qr_container" style={styles.container}>
        <Typography>Fazenda: {gestor.fazenda}</Typography>
        <Typography>Cliente: {nomeCliente}</Typography>
        <Typography>Intermediante: {gestor.intermediante}</Typography>
        <Typography>Concessionária: {concessionariaNome}</Typography>
        <Typography>Região: {gestor.regiao}</Typography>
        <Typography>
          Coordenadas: Lat: {gestor.lat} Long: {gestor.long}
        </Typography>
        <Typography>Local de medição: {gestor.nome_bombeamento}</Typography>
        <Typography>Id do Equipamento: {gestor.id_equipamento}</Typography>
        <Typography>Tipo de Equipamento: {gestor.equipamento}</Typography>
        {gestor.host_equip &&
          <Typography> Endereço Host: {gestor.host_equip}</Typography>}
        {gestor.host_port &&
          <Typography> Porta Host: {gestor.host_port}</Typography>
        }
        {gestor.codigo_esn &&
          <Typography>Codigo ESN (satélite): {gestor.codigo_esn}</Typography>
        }
        {gestor.connection &&
          <Typography>Metodo de conexão: {gestor.connection}</Typography>
        }
        <Typography>
          Potência do Transformador: {gestor.potencia_transf}
        </Typography>
        <Typography>Potência Instalada: {gestor.potencia_instalada}</Typography>
        {gestor.transf_potencia1 &&
        <Typography>Primário TP: {gestor.transf_potencia1}</Typography>}
        {gestor.transf_potencia2 &&
        <Typography>Secundário TP: {gestor.transf_potencia2}</Typography>}
        {gestor.tensao_corrente &&
        <Typography>Primário TC: {gestor.tensao_corrente}</Typography>}
        {gestor.relacao_tp &&
        <Typography>RTP: {gestor.relacao_tp}</Typography>}
        {gestor.relacao_tc &&
        <Typography>RTC: {gestor.relacao_tc}</Typography>}
        {gestor.constante_eletronica &&      //Mostra a constante eletronica apenas quando esta variavel esta preenchida
          <Typography>Constante Eletronica: {gestor.constante_eletronica}</Typography>}
        {gestor.soma_cargas &&       //Mostra a soma das cargas apenas quando esta variavel esta preenchida
          <Typography>Soma das Cargas: {gestor.soma_cargas}</Typography>}
        <Typography>Demanda Contratada: {gestor.demanda_contratada}</Typography>
        {gestor.demanda_geracao &&
          <Typography>Demanda Contratada de Geração: {gestor.demanda_geracao}</Typography>}
        {gestor.potencia_usina &&
          <Typography>Potência da Usina: {gestor.potencia_usina}</Typography>}
        <Typography>
          Início do Horário de Ponta: {gestor.horario_ponta_inicio}
        </Typography>
        <Typography>
          Duração do Horário de Ponta: {gestor.horario_ponta_duracao}
        </Typography>
        <Typography>
          Criado em: {moment(gestor.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </Typography>
        <Typography>
          Horário Reservado: Dê {gestor.horario_reservado_inicio} até{" "}
          {gestor.horario_reservado_fim}
        </Typography>
      </Container>
    );
  }
}
