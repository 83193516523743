import React from "react"
import { headRowsConcessionaria } from "../../constants/headRows";
import ObjectTable from "../../default/table/ObjectTable";
import { AddCircle, Delete, EditRounded, ElectricalServices } from "@mui/icons-material";
import { colors } from "../../constants/dc_constants";
import { RemoveRedEye } from "@material-ui/icons";
import { makeActionFormDialog, makeAlertDialog } from "../../default/dialogs/DialogRefInterface";
import ShowConcessionaria from "./ShowConcessionaria";
import { concessionariaService } from "../../services/concessionaria_service";
import { validRequiredFields, validateShowResponseToast } from "../../helpers/toast_alerts";
import FormConcessionaria from "./FormConcessionaria";
import { requiredConcessionariaFields } from "../../constants/equipmentConstants";

class TableConcessionaria extends React.Component{
	constructor(props) {
		super(props);

		this.state = {
			rows: [],
			headRows: headRowsConcessionaria,
		};
	}

	getConcessionarias = () => {
    concessionariaService.getConcessionaria((response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          rows: response.data,
        });
      }
    });
  };

	componentDidMount(){
		this.getConcessionarias();
	}

  toolActions = [
    {
      title: "Adicionar",
      label: "Adicionar",
      color: colors.ORANGE_POWER,
      MaterialIcon: AddCircle,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormConcessionaria,
          "Adicionar Concessionaria",
          false,
          null,
          "Salvar",
          (data) => {
            let requiredFields = requiredConcessionariaFields;
            if (validRequiredFields(data, requiredFields)) {
              concessionariaService.createConcessionaria(data, (response) => {
                if (validateShowResponseToast(response, "Concessionária")) {
                  this.getConcessionarias();
                }
              });
            }
          }
        ).current.open();
      },
    },
  ];

	actions = [
    {
      title: "Ver",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: RemoveRedEye,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ShowConcessionaria,
          "Dados da concessionária",
          false,
          selected,
          "",
          () => {}
        ).current.open();
      },
    },
    {
      title: "Editar",
      label: "Editar",
      color: colors.GREEN,
      MaterialIcon: EditRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormConcessionaria,
          "Editar Concessionária",
          false,
          selected,
          "Salvar",
          (data) => {
            let requiredFields = requiredConcessionariaFields;
            if (validRequiredFields(data, requiredFields)) {
              concessionariaService.updateConcessionaria(data, (response) => {
                if (validateShowResponseToast(response, "", true, true)) {
                  this.getConcessionarias();
                }
              });
            }
          }
        ).current.open();
      },
    },
    {
      title: "Excluir",
      label: "Excluir",
      color: colors.RED,
      MaterialIcon: Delete,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeAlertDialog(
          () => {
            concessionariaService.deleteConcessionaria(selected, (response) => {
              if (validateShowResponseToast(response)) {
                this.getConcessionarias();
              }
            });
          },
          "Atenção!",
          "Deseja realmente excluir esta concessionária?"
        ).current.open();
      }
    }
  ];

	iconHead = [
    {
      MaterialIcon: ElectricalServices,
      color: colors.ORANGE_POWER,
    },
  ];

	render() {
		return (
			<ObjectTable
        rowId="id"
        tableName="Concessionárias"
        materialIconHead={this.iconHead}
        headRows={this.state.headRows}
        actions={this.actions}
        selectedToolActions={[]}
        toolActions={this.toolActions}
        rowSize={10}
        rowsOptions={[10, 20, 40]}
        dense={true}
        rows={this.state.rows || []}
      />
		)
	}
}

export default TableConcessionaria;