import React from "react";
import { EditRounded, AddCircle, People } from "@material-ui/icons";

import { headRowsUsuario } from "../constants/headRows";
import ObjectTable from "../default/table/ObjectTable";
import { makeActionFormDialog } from "../default/dialogs/DialogRefInterface";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import FormUsuario from "./FormUsuario";
import { userService } from "../services/user_service";
import { colors } from "../constants/dc_constants";

class TableUsuario extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      headRows: headRowsUsuario,
    };
    this.objectTableRef = React.createRef();
  }

  getUsuarios = () => {
    userService.getUsuarios({}, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          rows: response.data || [],
        });
      }
    });
  };

  componentDidMount() {
    this.getUsuarios();
  }

  selectedToolActions = [];

  toolActions = [
    {
      title: "Adicionar",
      label: "Adicionar",
      color: colors.GREEN,
      MaterialIcon: AddCircle,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormUsuario,
          "Adicionar Usuário",
          false,
          null,
          "Salvar",
          (data) => {
            userService.createUsuario(data, (response) => {
              if (validateShowResponseToast(response)) {
                this.getUsuarios();
              }
            });
          }
        ).current.open();
      },
    },
  ];
  actions = [
    {
      title: "Editar",
      label: "Editar",
      color: colors.GREEN,
      MaterialIcon: EditRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormUsuario,
          "Editar Usuário",
          null,
          selected,
          "Salvar",
          (data) => {
            userService.updateUsuario(data, (response) => {
              if (validateShowResponseToast(response)) {
                this.getUsuarios();
              }
            });
          }
        ).current.open();
      },
    },
  ];

  iconHead = [
    {
      MaterialIcon: People,
      color: colors.DARK_GREY,
    },
  ];

  render() {
    return (
      <ObjectTable
        rowId="id"
        materialIconHead={this.iconHead}
        tableName="Usuários"
        headRows={this.state.headRows}
        actions={this.actions}
        selectedToolActions={this.selectedToolActions}
        toolActions={this.toolActions}
        rowSize={10}
        rowsOptions={[5, 10, 20]}
        ref={this.objectTableRef}
        rows={this.state.rows || []}
        dense={false}
      />
    );
  }
}

export default TableUsuario;
