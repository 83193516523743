const INITIAL_STATE = {
  open: true,
  openSettings: null,
  openFilters: null,
}

export default function(state = INITIAL_STATE, action){
  switch(action.type){
    case "DBA_DASHBOARD_OPEN_MENU":
      return {...state, open: action.payload.open}
    case "DBA_DASHBOARD_OPEN_SETTINGS":
      return {...state, openSettings: action.payload.openSettings}
    default:
      return state
  }
}
