import React from "react";
import {
	Grid,
	Typography,
  Container,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';
import GaugeChartNew from "./GaugeChartNew";
import { colors } from "../../constants/dc_constants";
import { gestorService } from "../../services/gestor_service";
import { floorTo2 } from "../../util/utils";
import CircularProgress from '@mui/material/CircularProgress';
import { convertLastDemandData } from "../../util/abb_convert";
import { formatFullDateTime } from "../../util/utils";

class ChartGestorOn extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
      data: null,
			chartSize: 400,
			fatorPotencia: 0,
			fatorPotenciaShowChart: 0,
			potenciaAtiva: 0,
			potenciaReativa: 0,
			potenciaAparente: 0,
			potenciaInjetada: 0,
			tipoFatorPotencia: "",
			anchorEl: null,
			gestor: this.props.data || {},
		};
	}

	async componentDidMount() {
    const data = {
      modbusHost: this.props.data.host_equip,
      modbusPort: this.props.data.host_port,
      rtp: this.props.data.relacao_tp,
      rtc: this.props.data.relacao_tc,
      ke: this.props.data.constante_eletronica,
    }

    gestorService.absAbntLastDemand(data, (response) => {
      if (response && response.data) {
        this.setState({
          data: response.data,
        });
      } else {
        console.error("Erro ao buscar dados do gestor");
      }
    });
	};

  componentDidUpdate(prevProps, prevState) {
		if (prevState.data !== this.state.data && this.state.data) {
			this.handleDataConverted(this.state.data[0]);
		}
	}

  handleDataConverted = (data) => {
    const data_converted = convertLastDemandData(data);
    this.setState({
      fatorPotencia: data_converted.fator_potencia_total,
      potenciaAtiva: data_converted.potencia_ativa_total,
      potenciaInjetada: data_converted.potencia_injetada,
    });
  };

  getGestorData = (attribute, defaultValue = null) => {
		try {
			if (this.state.gestor && this.state.gestor[attribute]) {
				return this.state.gestor[attribute];
			}
			return defaultValue;
		} catch (error) {
			return defaultValue;
		}
	};

  formatTextNaN = (text) => {
		if (isNaN(text)) {
			return "0";
		}
		return text;
	};

	render() {
    let { data } = this.state;

    if (!data) {
      return (
        <Container
          maxWidth="sm"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <CircularProgress
            style={{ color: colors.ORANGE_POWER }}
            size={100}
          />
        </Container>
      )
    }

    if (typeof data === 'string') {
      return (
        <Container
          maxWidth="sm"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
        >
          <Alert severity="warning">{data}</Alert>
        </Container>
      )
    }

		return (
      <>
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid
              container
              alignContent="center"
              justifyContent="flex-start"
              item
              xs={12}
              md={6}
            >
              <Typography style={{ color: colors.GREEN }}>
                Monitor gestor: {this.state.gestor.id_equipamento} - {this.state.gestor.fazenda}
              </Typography>
            </Grid>
            <Grid
              container
              alignContent="center"
              justifyContent="flex-end"
              item
              xs={12}
              md={6}
            >
              <Typography style={{ color: colors.GREEN }}>
                Data coleta: <strong>{formatFullDateTime(data[0].timeStamp)}</strong>
              </Typography>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="xl">
          <Grid sx={{ flexGrow: 1 }} container spacing={2}>
            <Grid item xs={12} sm={6}>
              <GaugeChartNew
                title="Potência Ativa (kW)"
                label="kW"
                max={floorTo2(
                  this.getGestorData("demanda_contratada", 200) * 1.05 * 1.2
                )}
                value={this.state.potenciaAtiva}
                greenFrom={0}
                greenTo={this.getGestorData("demanda_contratada", 200)}
                yellowFrom={this.getGestorData("demanda_contratada", 200)}
                yellowTo={this.getGestorData("demanda_contratada", 200) * 1.05}
                redFrom={this.getGestorData("demanda_contratada", 200) * 1.05}
                redTo={this.getGestorData("demanda_contratada", 200) * 1.05 * 1.2}
                chartSize={this.state.chartSize}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <GaugeChartNew
                title="Fator de Potência"
                label="FP"
                min={0}
                max={2}
                value={this.state.fatorPotencia}
                redFrom={0}
                redTo={0.92}
                yellowColor="#DC3912"
                yellowFrom={1.08}
                yellowTo={2}
                greenFrom={0.92}
                greenTo={1.08}
                labelText={this.state.tipoFatorPotencia}
                labelColor={colors.GREEN}
                chartSize={this.state.chartSize}
                showValue={this.state.fatorPotenciaShowChart}
                majorTicks={[
                  "0 IND",
                  "0.25",
                  "0.50",
                  "0.75",
                  "1",
                  "-0.75",
                  "-0.50",
                  "-0.25",
                  "CAP 0",
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {this.state.gestor.demanda_geracao ?
              <GaugeChartNew
                title="Potência Injetada (kW)"
                label="kW"
                max={floorTo2(
                  this.getGestorData("demanda_geracao", 200) * 1.05 * 1.2
                )}
                value={this.state.potenciaInjetada}
                greenFrom={0}
                greenTo={this.getGestorData("demanda_geracao", 200)}
                yellowFrom={this.getGestorData("demanda_geracao", 200)}
                yellowTo={this.getGestorData("demanda_geracao", 200) * 1.05}
                redFrom={this.getGestorData("demanda_geracao", 200) * 1.05}
                redTo={this.getGestorData("demanda_geracao", 200) * 1.05 * 1.2}
                chartSize={this.state.chartSize}
              />
              :
              <GaugeChartNew
                title="Potência Aparente (kVA)"
                label="kVA"
                max={floorTo2(this.getGestorData("potencia_transf", 380) * 1.2)}
                value={this.state.potenciaAparente}
                greenFrom={0}
                greenTo={this.getGestorData("potencia_transf", 380)}
                redFrom={this.getGestorData("potencia_transf", 380)}
                redTo={this.getGestorData("potencia_transf", 380) * 1.2} // Potencia + 20%
                chartSize={this.state.chartSize}
              />
            }
            </Grid>
          </Grid>
        </Container>
      </>
		);
	}
}

export default ChartGestorOn;
