import React from "react";
import { Typography, Container } from "@material-ui/core";
import moment from "moment";

const styles = {
    container: {
      maxWidth: 400,
      minWidth: 400,
    },
  };
  
  export default class ShowConcessionaria extends React.Component {
    render() {
      const concessionaria = this.props.data;
      return (
        <Container id="qr_container" style={styles.container}>
					<Typography>Concessionária: {concessionaria.nome}</Typography>
					<Typography>Região: {concessionaria.regiao}</Typography>
          <Typography>Tarifa ponta: {concessionaria.tarifa_hp}</Typography>
					<Typography>Tarifa fora ponta: {concessionaria.tarifa_hfp}</Typography>
          <Typography>Tarifa reservado: {concessionaria.tarifa_hr}</Typography>
          <Typography>Tarifa demanda ativa: {concessionaria.tarifa_demanda_ativa}</Typography>
          <Typography>Tarifa demanda reativa: {concessionaria.tarifa_demanda_reativa}</Typography>
          <Typography>Tarifa fora ponta reativo: {concessionaria.tarifa_hfp_reativo}</Typography>
          <Typography>Tarifa ultrapassagem: {concessionaria.tarifa_ultrapassagem}</Typography>
        </Container>
      );
    }
  }
  