import { getAxiosInstance } from "../services/service";
export const alertaService = {
    createAlerta,
    getAlerta,
    updateAlerta,
    getAlertaLog

};

async function createAlerta(data, handleResponse, handleAction) {
    let response = undefined;
    let errorResponse = undefined;
    response = await getAxiosInstance("/alertas")
        .post(undefined, data)
        .catch((error) => {
            errorResponse = error.response || undefined;
        });
    if (handleResponse) handleResponse(response || errorResponse);
    if (handleAction) handleAction();
}

async function getAlerta(data, handleResponse, handleAction) {
    let response = undefined;
    let errorResponse = undefined;
    response = await getAxiosInstance(`/alertas?gestor_id=${data.data ? data.data.id : data}`)
        .get()
        .catch((error) => {
            errorResponse = error.response || undefined;
        });
    if (handleResponse) handleResponse(response || errorResponse);
    if (handleAction) handleAction();
}

async function getAlertaLog(data, handleResponse, handleAction) {
    let response = undefined;
    let errorResponse = undefined;
    response = await getAxiosInstance(`/alertas/${data.id}/logs`)
        .get()
        .catch((error) => {
            errorResponse = error.response || undefined;
        });
    if (handleResponse) handleResponse(response || errorResponse);
    if (handleAction) handleAction();
}

async function updateAlerta(data, handleResponse, handleAction) {
    let response = undefined;
    let errorResponse = undefined;
    const id = data.id || 0;
    response = await getAxiosInstance(`/alertas/${id}`)
        .put(undefined, data)
        .catch((error) => {
            errorResponse = error.response || undefined;
        });
    if (handleResponse) handleResponse(response || errorResponse);
    if (handleAction) handleAction();
}
