import React from 'react'

const alertDialogRef = React.createRef()
const progressDialogRef = React.createRef()
const objectListDialogRef = React.createRef()
const actionFormDialogRef = React.createRef()
const actionFormDialogRef2 = React.createRef()

export function makeAlertDialog(handleAgree=null, title="", msg=""){
  if(alertDialogRef.current){
    if(typeof alertDialogRef.current.update_ref_state !== "undefined"){
      alertDialogRef.current.update_ref_state(handleAgree, title, msg)
    }
  }
  return alertDialogRef
}

export function makeProgressDialog(handleFinish=null, title="", timeAutoClose=0){
  if(progressDialogRef.current){
    if(typeof progressDialogRef.current.update_ref_state !== "undefined"){
      progressDialogRef.current.update_ref_state(handleFinish, title, timeAutoClose)
    }
  }
  return progressDialogRef
}

export function closeProgressDialog(){
  if(progressDialogRef.current){
    progressDialogRef.current.close()
  }
}

export function makeObjectListDialog(ObjectTable, title="", handleAfterClose=undefined, data=undefined, rows=[], handleSave=undefined){
  if(objectListDialogRef.current){
    if(typeof objectListDialogRef.current.update_ref_state !== "undefined"){
      objectListDialogRef.current.update_ref_state(ObjectTable, title, handleAfterClose, data, rows, handleSave)
    }
  }
  return objectListDialogRef
}

export function makeActionFormDialog(ObjectForm, title="", fullScreen=false, data={}, btn_title="", handleFormState=undefined){
	if(actionFormDialogRef.current){
		if(typeof actionFormDialogRef.current.update_ref_state !== "undefined"){
		actionFormDialogRef.current.update_ref_state(ObjectForm, title, fullScreen, data, btn_title, handleFormState)
		}
	}
	return actionFormDialogRef
}

export function makeActionFormDialog2(ObjectForm, title="", fullScreen=false, data={}, btn_title="", handleFormState=undefined){
	if(actionFormDialogRef2.current){
    if(typeof actionFormDialogRef2.current.update_ref_state !== "undefined"){
      actionFormDialogRef2.current.update_ref_state(ObjectForm, title, fullScreen, data, btn_title, handleFormState)
    }
  }
  return actionFormDialogRef2
}
