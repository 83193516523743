import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { colors } from "../../constants/dc_constants";

const styleQuadro = {
  box: {
    height: "21vh",
    backgroundColor: colors.LIGHT_GREY,
    borderColor: colors.DARK_GREY
  },

  tableText: {
    fontSize: 18,
  },

  tableTextRed: {
    fontSize: 18,
    color: "#a60b00",
  },
};

export default function Quadro({ data, gestorName }) {
  return (
    <Grid border={2} style={styleQuadro.box}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Equipamento ({gestorName})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={styleQuadro.tableText}>Hoje</TableCell>
              <TableCell style={styleQuadro.tableText}>7d</TableCell>
              <TableCell style={styleQuadro.tableText}>30d</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={1}>
              <TableCell style={styleQuadro.tableText} align="left">
                Consumo em m³
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.consumptionDay.toFixed(2)}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.consumptionWeek.toFixed(2)}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.consumptionMonth.toFixed(2)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
