import { getAxiosInstance } from '../services/service'
import { makeQuery } from './service_util'

export const resumoService = {
    createResumo,
    getResumos,
    deleteResumo
}

async function createResumo(data, handleResponse){
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance('/resumos').post(undefined, data).catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
}

async function getResumos(data, handleResponse) {
    let response = undefined
    let errorResponse = undefined
    const query = makeQuery(data);
    response = await getAxiosInstance(`/resumos${query}`).get().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
}

async function deleteResumo(data, handleResponse) {
    let response = undefined
    let errorResponse = undefined
    response = await getAxiosInstance(`/resumos/${data.id}`).delete().catch(error => {
        errorResponse = error.response || undefined
    })
    if(handleResponse) handleResponse(response || errorResponse)
}



