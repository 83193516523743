import React from "react";
import { Typography, Container } from "@material-ui/core";

const styles = {
  container: {
    maxWidth: 400,
    minWidth: 400,
  },
};

export default class ShowGestorWater extends React.Component {
  render() {
    const gestor = this.props.data;
    const nomeCliente = gestor.usuario ? gestor.usuario.nome : "";
    console.log(gestor);
    return (
      <Container id="qr_container" style={styles.container}>
        <Typography>Fazenda: {gestor.fazenda}</Typography>
        <Typography>Cliente: {nomeCliente}</Typography>
        <Typography>Intermediante: {gestor.intermediante}</Typography>
        <Typography>Região: {gestor.regiao}</Typography>
        <Typography>
          Coordenadas: Lat: {gestor.lat} Long: {gestor.long}
        </Typography>
        <Typography>Local de medição: {gestor.nome_bombeamento}</Typography>
        <Typography>Id do Equipamento: {gestor.id_equipamento}</Typography>
        <Typography>MacID: {gestor.codigo_esn}</Typography>
        <Typography>Potência da bomba: {gestor.potencia_instalada}</Typography>
        <Typography>Vazão L/h: {gestor.vazao}</Typography>
        <Typography>Outorga: {gestor.outorga}</Typography>
        <Typography>Tipo de equipamento: {gestor.equipamento}</Typography>
        <Typography>Gateway: {gestor.gateway}</Typography>
      </Container>
    );
  }
}
