import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default function ExportXLS(csvData, fileName, type) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  let wb = XLSX.utils.book_new();
  try {
    if (type === 'relatorio_fiscal') {
      for (const [categoryName, categoryData] of Object.entries(csvData)) {
        if (Array.isArray(categoryData) && categoryData.length > 0) {
          const ws = XLSX.utils.json_to_sheet(categoryData);
          XLSX.utils.book_append_sheet(wb, ws, categoryName);
        }
      }
    } else if (type === 'dados_tabela') {
      const ws = XLSX.utils.json_to_sheet(csvData);
      XLSX.utils.book_append_sheet(wb, ws, 'data');
    } else {
      throw new Error('Tipo de exportação inválida');
    }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, fileName + fileExtension);
  } catch (error) {
    console.error('Erro ao exportar dados para Excel:', error);
  }
}