import { Grid, Input, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { colors, daysByMonth } from "../constants/dc_constants";
import { getMonthName } from "../util/utils";
import NumberFormat from "react-number-format";
const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	form: {
		minHeigth: 600,
		maxWidth: 775,
		minWidth: 775,
	},
	formControl: {
		minWidth: "220px",
	},
	container: {
		marginTop: "15px",
	},
	celHead: {
		marginBottom: 0,
		fontWeight: "bold",
		fontSize: 18,
		align: "center"
	},
	celBody: {
		marginBottom: 0,
		fontWeight: "bold",
		fontSize: 18,
		align: "",
	},
	th: {
		paddingInline: "0.3rem"
	}
});

class FormOutorga extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			outorga: [
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 1,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 2,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 3,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 4,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 5,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 6,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 7,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 8,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 9,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 10,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 11,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 12,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
			]
		};
		this.handleChange = this.handleChange.bind(this);
	}

	componentDidMount() {
		const data = this.props.data.outorgas;
		if (data.length > 0) {
			this.setState({
				outorga: data
			})
		}
	}

	handleChange(e, mes) {
		let { name, value } = e.target;
		const updatedOutorga = this.state.outorga;
		updatedOutorga[mes - 1][name] = value;
		this.setState({ outorga: updatedOutorga });
	}

	action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

	render() {
		const { classes } = this.props;
		return (
			<form
				className={classes.form}
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Grid item xs={12}>
					<Table
						style={{
							backgroundColor: colors.WHITE,
							fontSize: 14,
							padding: 20,
							width: "100%",
							border: `1px solid ${colors.LIGHT_GREY}`,
							borderRadius: 4,
							marginBottom: 10,
							color: colors.PRIMARY,
						}}
					>
						<Thead>
							<Tr>
								<Th></Th>
								<Th scope="col">
									<Typography
										className={classes.celHead}
									>
										Vazão
									</Typography>
								</Th>
								<Th scope="col">
									<Typography
										className={classes.celHead}
									>
										Volume
									</Typography>
								</Th>
								<Th scope="col">
									<Typography
										className={classes.celHead}
									>
										dias / mes
									</Typography>
								</Th>
								<Th scope="col">
									<Typography
										className={classes.celHead}
									>
										horas / dia
									</Typography>
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{
								this.state.outorga.map((mes) => {
									return (
										<Tr key={mes.mes}>
											<Th className={classes.th}>
												<Typography
													className={classes.celHead}
												>
													{getMonthName(mes.mes)}
												</Typography>
											</Th>
											<Th className={classes.th}>
												<TextField
												style={{width:"80%"}}
													id="vazao"
													name="vazao"
													type="number"
													inputProps={{ style: { textAlign: 'center' } }}
													value={mes.vazao}
													onChange={(e) => this.handleChange(e, mes.mes)}
												/>
											</Th>
											<Th className={classes.th}>
												<TextField
												style={{width:"80%"}}
													id="volume"
													name="volume"
													type="number"
													inputProps={{ style: { textAlign: 'center' } }}
													value={mes.volume}
													onChange={(e) => this.handleChange(e, mes.mes)}
												/>
											</Th>
											<Th className={classes.th}>
												<TextField
													style={{width:"80%"}}
													id="dias_mes"
													name="dias_mes"
													type="number"
													inputProps={{ style: { textAlign: 'center' } }}
													value={mes.dias_mes}
													onChange={(e) => {
														if(e.target.value >= 0 && e.target.value <= daysByMonth[mes.mes] ){
															this.handleChange(e, mes.mes)
														}
													}
													}
												/>
											</Th>
											<Th>
												<Input
													id="horas_dia"
													name= "horas_dia"
													type="time"
													value={mes.horas_dia}
													onChange={(e) => this.handleChange(e, mes.mes)}
													min="00:00"
													max="24:00"
													pattern="\d{2}:\d{2}"
													/>
											</Th>
										</Tr>
									)
								})
							}
						</Tbody>
					</Table>
				</Grid>
			</form>
		)
	}
}

export default withStyles(styles)(FormOutorga);