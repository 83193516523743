import moment from "moment";

// em funcionamento se maior que 10% da potencia instalada
// senão, fora de funcionamento
function emFuncionamento(potenciaInstalada, potenciaDemandada) {
  return potenciaDemandada > potenciaInstalada * 0.1;
}

export function calculaEconomiaDiaria(
  gestor,
  dataRelatorioAneel,
  dataInicial,
  dataFinal
) {
  let daysResult = {};
  let currentDate = moment(dataInicial).clone();
  while (currentDate.isSameOrBefore(moment(dataFinal).add(3, "minute"))) {
    /** hr: Horario Reservado, hp: Horario Ponta, hf: Horario Fora de Ponta */
    daysResult[currentDate.format("YYYY-MM-DD")] = {
      hfp_funcionamento: 0, // hora funcionamento horario ponta
      hfp_potencia: 0, // potencia em periodo fora de ponta
      hr_ocioso: 0, // hora ocioso horario reservado - disponivel
      kwh_economia: 0,
      tarifa_hfp: gestor?.concessionaria.tarifa_hfp,
      tarifa_hr: gestor?.concessionaria.tarifa_hr,
      potencial_economia: 0,
    };
    currentDate = currentDate.clone().add(1, "day");
  }
  dataRelatorioAneel.forEach((dta) => {
    if (
      dta.posto_horario === "R" &&
      !emFuncionamento(gestor?.potencia_instalada, dta.potencia_ativa)
    ) {
      daysResult[moment(dta.data_hora).format("YYYY-MM-DD")].hr_ocioso +=
        15 / 60; // horas ociosas em horario reservado
    } else if (
      dta.posto_horario === "F" &&
      emFuncionamento(gestor?.potencia_instalada, dta.potencia_ativa)
    ) {
      daysResult[moment(dta.data_hora).format("YYYY-MM-DD")].hfp_potencia +=
        dta.potencia_ativa; // Potencia em horario fora de ponta
      daysResult[
        moment(dta.data_hora).format("YYYY-MM-DD")
      ].hfp_funcionamento += 15 / 60; // horas de funcionamento em horario fora de ponta
    }
  });
  return Object.keys(daysResult).map((drKey) => {
    const horasFuncionamento =
      daysResult[drKey].hfp_funcionamento > daysResult[drKey].hr_ocioso
        ? daysResult[drKey].hr_ocioso
        : daysResult[drKey].hfp_funcionamento; // garante que houve gasto em horario de ponta suficiente para preencher o horario reservado ocioso

    // Gasto em horario fora de ponta
    daysResult[drKey].kwh_economia =
      (daysResult[drKey].hfp_potencia /
        (daysResult[drKey].hfp_funcionamento || 1) /
        4) *
      horasFuncionamento;

    daysResult[drKey].potencial_economia =
      daysResult[drKey].kwh_economia * gestor?.concessionaria.tarifa_hfp -
      daysResult[drKey].kwh_economia * gestor?.concessionaria.tarifa_hr; // Calcula a economia de acordo com o valor da tarifa

    return { date: drKey, ...daysResult[drKey] };
  }).filter((dia) => dia.potencial_economia > 0);
}
