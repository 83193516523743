import React from 'react';
import { IconButton, Typography } from "@material-ui/core";
import {
    TrendingUp,
    Speed,
    FlashAutoSharp,
    UpdateOutlined,
    FlashOn,
    TrendingFlat,
} from "@material-ui/icons";

import { colors } from "../constants/dc_constants";
import { WaterDrop } from '@mui/icons-material';

const CustomIconButton = (props) => {
    return (
        <IconButton
            style={{
                color: props.color || colors.ORANGE_POWER,
                background: "transparent",
                cursor: "default",
            }}
        >
        {getComponent(props.icon)}
        <Typography variant="h6" style={{marginLeft: 5, fontSize: 16}}>
            {props.text}
        </Typography>
        </IconButton>
    );
};

function getComponent(component) {
    switch (component) {
        case 'FlashOn':
            return <FlashOn />;
        case 'Speed':
            return <Speed />;
        case 'TrendingUp':
            return <TrendingUp />;
        case 'UpdateOutlined':
            return <UpdateOutlined />;
        case 'FlashAutoSharp':
            return <FlashAutoSharp />;
        case 'TrendingFlat':
            return <TrendingFlat />;
        case 'WaterDrop':
            return <WaterDrop/>;
        default:
            return <FlashOn />;
    }
}

export default CustomIconButton;