
import moment from 'moment'

/**
 * Number.prototype.format(n, x, s, c)
 * 
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
/* eslint-disable-next-line */
Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export function formatMoney(value, decimal = 2) {
    if (value >= 0) {
        return `R$${Number(value).format(decimal, 3, '.', ',')}`
    } else {
        return Number(value).format(decimal, 3, '.', ',').replace('-', '-R$')
    }
}


export function formatDecimal(value) {
    var setDecimal = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      };
      if (value === null || value === undefined) return " -- ";
      else return parseFloat(value).toLocaleString("de-DE", setDecimal);
}


export function formatValueByType(value, type) {
    switch (type) {
        case 'money':
            return formatMoney(value, 2)
        case 'money2':
            return formatMoney(value, 3)
        case 'date':
            let ret = value ? moment(value).format('DD/MM/YYYY') : ""
            return ret
        case 'date_time':
            if (value == "Sem retorno") return "Sem retorno";
            return value ? moment(value).format('DD/MM HH:mm:ss') : ""
        case 'status':
            let status = convertStatusToText(value)
            return status
        case 'number':
            return formatDecimal(value, -1)
        case 'precision2':
            return formatDecimal(value)
        case 'text':
            return value
        default:
            return value
    }
}

export function convertStatusToText(status) {
    if (status === 1) {
        return "ATIVO"
    } else {
        return "INATIVO"
    }
}