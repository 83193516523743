export  function authHeader() {
	// return authorization header with basic auth credentials
	let user = JSON.parse(localStorage.getItem('user'));

	if (user && user.authdata) {
		return { 'Authorization': user.token, 'Content-Type': 'application/json' }
	} else {
		return {}
	}
}

export function adminAuthHeader() {
	// return authorization header with basic auth credentials
	let admin = JSON.parse(localStorage.getItem('admin'));

	if (admin && admin.authdata) {
		return { 'Authorization': admin.token, 'Content-Type': 'application/json' }
	} else {
		return {}
	}
}
