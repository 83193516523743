import { Button, Grid, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  CloudDownloadRounded,
  PrintRounded,
  AttachMoney,
} from "@material-ui/icons";
import moment from "moment";
import React from "react";
import { headRowsEconomia } from "../../constants/headRows";
import CustomDatePicker from "../../default/custom/CustomDatePicker";
import {
  closeProgressDialog,
  makeProgressDialog,
} from "../../default/dialogs/DialogRefInterface";
import ObjectTable from "../../default/table/ObjectTable";
import { template_relatorio } from "../../default/templates_pdf/template_relatorio";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { gestorService } from "../../services/gestor_service";
import { calculaEconomiaDiaria } from "./relatorioEconomia";
import { colors } from "../../constants/dc_constants";

class TelaEconomia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gestores: [],
      gestor: null,
      tipoRelatorio: 1,
      tipoCorrente: 1,
      dataInicial: moment().subtract(1, "month").format("YYYY-MM-DD 00:00"),
      dataFinal: moment().format("YYYY-MM-DD 00:00"),
      relatorioData: [],

      totalEconomia: "",
      tableName: "Potencial de Economia Diário",
    };

    this.objectTableRef = React.createRef();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "gestor") {
      const gestorValue = this.state.gestores.find(
        (gtr) => gtr.id === Number(value)
      );
      this.setState({ [name]: gestorValue });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleClick = () => {
    this.getRelatorioEconomia();
  };

  getRelatorioEconomia = () => {
    const data = {
      gestorId: this.state.gestor ? this.state.gestor.id : 0,
      dataInicial: moment(this.state.dataInicial).format("YYYY-MM-DDT00:00:00"),
      dataFinal: moment(this.state.dataFinal).format("YYYY-MM-DDT23:59:59"),
    };
    makeProgressDialog(null, "Gerando Relatório...").current.open();
    gestorService.getRelatorioAneel(data, (response) => {
      if (validateShowResponseToast(response)) {
        const relEconomia = calculaEconomiaDiaria(
          this.state.gestor,
          response.data,
          this.state.dataInicial,
          this.state.dataFinal
        );
        const totalEconomia = relEconomia.reduce(
          (acc, value) => acc + value.potencial_economia,
          0
        );
        this.setState({
          relatorioData: relEconomia,
          totalEconomia,
          tableName: `Potencial de Economia Diária  -   Total: ${totalEconomia}`,
        });
      } else {
        this.setState({
          relatorioData: [],
        });
      }
      closeProgressDialog();
    });
  };

  getGestores = () => {
    gestorService.getGestores("power",{}, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getGestores();
  }

  actions = [];
  selectedToolActions = [];
  toolActions = [
    {
      title: "Imprimir",
      label: "Imprimir",
      color: colors.GREEN,
      MaterialIcon: PrintRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        template_relatorio(this.state.relatorioData, headRowsEconomia, {
          orientation: "l",
          title: "Relatório de Potencial de Economia Diária",
          data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
          equipamento: this.state.gestor.id_equipamento,
          local: this.state.gestor.fazenda,
          regiao: this.state.gestor.regiao,
          data_inicio: moment(this.state.dataInicial).format("DD/MM/YYYY"),
          data_final: moment(this.state.dataFinal).format("DD/MM/YYYY"),
          totalEconomia: this.state.totalEconomia,
        }).save(`relatorio_economico_${moment().format("DD_MM_YYYY")}`);
      },
    },

    {
      title: "Baixar XLS",
      label: "Baixar",
      color: colors.GREEN,
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        this.objectTableRef.current.getExportRowsXls(null, true, false);
      },
    },
  ];
  iconHead = [
    {
      MaterialIcon: AttachMoney,
      color: colors.GREEN,
    },
  ];

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item md={4}>
          <InputLabel align="left" htmlFor="gestor">
            Selecione o Gestor :
          </InputLabel>
          <Select
            fullWidth
            align="left"
            value={this.state.gestor ? this.state.gestor.id : 0}
            name="gestor"
            onChange={this.handleChange}
          >
            {this.state.gestores.map((ges) => (
              <MenuItem
                key={ges.id}
                value={ges.id}
              >{`${ges.id_equipamento} - ${ges.fazenda}`}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={3}>
          <InputLabel align="left" htmlFor="dataInicial">
            Data Inicial:{" "}
          </InputLabel>
          <CustomDatePicker
            //disableToolbar
            fullWidth
            variant="inline"
            iconcolor={colors.GREEN}
            format="DD/MM/YYYY"
            name="dataInicial"
            value={this.state.dataInicial}
            onChange={this.handleChange}
            KeyboardButtonProps={{
              "aria-label": "Mudar data",
            }}
          />
        </Grid>
        <Grid item md={3}>
          <InputLabel align="left" htmlFor="dataFinal">
            Data Final
          </InputLabel>
          <CustomDatePicker
            fullWidth
            //disableToolbar
            iconcolor={colors.GREEN}
            variant="inline"
            format="DD/MM/YYYY"
            name="dataFinal"
            value={this.state.dataFinal}
            onChange={this.handleChange}
            KeyboardButtonProps={{
              "aria-label": "Mudar data",
            }}
          />
        </Grid>
        <Grid item md={2}>
          <Button
            fullWidth
            variant="contained"
            style={{ backgroundColor: colors.ORANGE_POWER, color: "white", marginTop: 10 }}
            onClick={this.handleClick}
          >
            Filtrar
          </Button>
        </Grid>
        <Grid item md={12}>
          {this.state.relatorioData.length > 0 ? (
            <ObjectTable
              rowId="date"
              tableName={this.state.tableName}
              materialIconHead={this.iconHead}
              headRows={headRowsEconomia || []}
              rows={this.state.relatorioData}
              actions={this.actions}
              selectedToolActions={this.selectedToolActions}
              toolActions={this.toolActions}
              rowSize={40}
              rowsOptions={[20, 40, 80]}
              perPage={40}
              ref={this.objectTableRef}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  }
}

export default TelaEconomia;
