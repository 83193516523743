import React from "react"
import styles from "./ComplexCard.module.css"
import CustomPieChart from "../../components/CustomPieChart";
import { checkPump } from "../../util/gestor_utils";
import moment from "moment";
import { coletaService } from "../../services/coleta_service";
import MapaGestor from "../../_medidor/MapaGestor";
import browserHistory from "../../helpers/browser_history";
import { makeHoursWorking } from "../../util/utils";

class ComplexCard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			gestor: this.props.gestor,
			name: this.props.gestor.fazenda,
			gateway: this.props.gestor.gateway,
			outorga: this.props.gestor.outorgas.find((outorga) => outorga.mes == moment().format('M')),
			statusMsg: '',
			statusColor: '',
			statusFilter: '',
			lastUpdate: '',
			lastData: this.props.gestor.coletasAguas[0],
			fistData: this.props.gestor.primeiraColetaAgua[0],
			hoursWorking: 0,
			isDataLoaded: false,
		}
	}

	makeStatusMsg(status) {
		switch (status) {
			case -1:
				return ["Sem comunicação", "#000000", "brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(16%) hue-rotate(95deg) brightness(99%) contrast(102%)"];
			case 0:
				return ["Desligado", "#afafaf", "brightness(0) saturate(100%) invert(67%) sepia(21%) saturate(0%) hue-rotate(236deg) brightness(102%) contrast(84%)"];
			case 1:
				return ["Ligado", "#04de00", "brightness(0) saturate(100%) invert(46%) sepia(65%) saturate(2108%) hue-rotate(82deg) brightness(112%) contrast(120%)"];
		}
	}

	getDailyColets = async () => {
		await coletaService.getColetasAgua({
			gestor_id: this.props.gestor.id,
			data_inicial: moment().startOf('day'),
			data_final: moment(),
		},
			(response) => {
				if (response.status == 200) {
					const data = response.data.ungrouped;
					let working;
					if (data) {
						const hours = parseFloat(makeHoursWorking(data));
						this.setState({
							hoursWorking: hours,
							isDataLoaded: true,
						})
					}
				}
			})
	}

	async componentDidMount() {
		await this.getDailyColets();
		const lastColeta = this.props.gestor.coletasAguas[0];
		const status = lastColeta ? checkPump(lastColeta.data_coleta, lastColeta.bomba_ligada) : -1;
		const statusProps = this.makeStatusMsg(status);
		this.setState({
			...this.state,
			statusMsg: statusProps[0],
			statusColor: statusProps[1],
			statusFilter: statusProps[2],
			lastUpdate: lastColeta.data_coleta,
		}
		)
	}

	openMap(gestorId) {
		browserHistory.push(`/dashboard?gestor=${gestorId}`);
	}

	convertToDecimal = (timeStr) => {
		const [hours, minutes] = timeStr.split(':').map(Number);
		return hours + minutes / 60;
	};

	render() {
		const horasDecimal = this.convertToDecimal(this.state.outorga.horas_dia);
		const outorgaDiaria = (this.state.outorga.volume / this.state.outorga.dias_mes).toFixed()
		const horasMensais = (horasDecimal * this.state.outorga.dias_mes).toFixed()
		const volumeMensal = this.state.lastData.consumo_acc - (this.state.fistData?.consumo_acc || 0);

		return (
			<>
				<div className={styles.card}>
					<div className={styles.title} style={{ '--color': this.state.statusColor }}>{/*nome - status*/}
						<div className={styles.gestorName}>
							<img
								alt="pump-icon"
								width={"7%"}
								src="/static/images/pump-solid.svg"
								style={{ filter: this.state.statusFilter }}
							/>
							<div>{`${this.state.name} (${this.state.gateway})`}</div>
						</div>
						<div className={styles.status}>{this.state.statusMsg}</div>
					</div>
					{this.state.isDataLoaded &&
						<div className={styles.chartsContainer}>
							<div>
								<div>
									<img
										alt="meter-droplet-icon"
										width={"9%"}
										src="/static/images/meter-droplet-light.svg"
									/>
									{" "}Vol. Captado
								</div>
								<div className={styles.chart}>
									<CustomPieChart
										total={this.state.outorga.volume}
										value={volumeMensal}
										valueColor={"#595959"}
										restColor={"#F0E5FC"}
										unit={"m³"}
										pieHole={0.8}
									/>
								</div>
							</div>

							<div>
								<div>
									<img
										alt="timer-icon"
										width={"8%"}
										src="/static/images/timer-light.svg"
									/>
									{" "}Horas trabalhadas
								</div>
								<div className={styles.chart}>
									<CustomPieChart
										total={horasDecimal}
										value={this.state.hoursWorking}
										valueColor={"#FF8F00"}
										restColor={"#F0E5FC"}
										unit={"horas"}
										pieHole={0.8}
									/>
								</div>
							</div>

							<div>
								<div>
									<img
										alt="droplet-icon"
										width={"6%"}
										src="/static/images/droplet-light.svg"
									/>
									{" "}Vazão inst
								</div>
								<div className={styles.chart}>
									<CustomPieChart
										total={this.state.outorga.vazao}
										value={this.state.lastData.vazao}
										valueColor={"#2596AF"}
										restColor={"#F0E5FC"}
										unit={"m³/h"}
										pieHole={0.8}
									/>
								</div>
							</div>
						</div>
					}
					<div>{/*horario*/} Útima comunicação: {moment(this.state.lastUpdate).format('L')} | {moment(this.state.lastUpdate).format('LT')}</div>
					<div className={styles.detailsBox}>{/*detalhes*/}
						<b>Detalhes</b>
						<ul>
							<li>Outorga diária: {outorgaDiaria}m³| {this.state.outorga.horas_dia.substring(0, 2)} horas</li>
							<li>Outorga mensal: {this.state.outorga.volume}m³| {horasMensais} horas</li>
							<li>Dias captados: 6 a {this.state.outorga.dias_mes} dias</li>
							<li>Outorga válida até: 02/04/2025</li>
						</ul>
					</div>
					<div className={styles.mapLink}>
						<div style={{ width: "0.5rem" }}>
							<img
								alt="location-icon"
								width={"100%"}
								src="/static/images/location-dot-light.svg"
								style={{ filter: "invert(100%)" }}
							/>
						</div>
						<div onClick={() => this.openMap(this.state.gestor.id)}>Ver no mapa</div>
					</div>
				</div >
			</>
		)
	}
}

export default ComplexCard