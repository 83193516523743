import * as React from 'react';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import { gestorService } from "../services/gestor_service";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default class AbsAbntRealTimeData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      page: 0,
      rowsPerPage: 10,
    };
  };

  componentDidMount() {
    const data = {
      modbusHost: this.props.data.host_equip,
      modbusPort: this.props.data.host_port,
      rtp: this.props.data.relacao_tp,
      rtc: this.props.data.relacao_tc,
      ke: this.props.data.constante_eletronica,
    }

    gestorService.absAbntRealTimeData(data, (response) => {
      if (response && response.data) {
        this.setState({ data: response.data });
      } else {
        console.error("Erro ao buscar dados do gestor");
      }
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: +event.target.value,
      page: 0,
    });
  };

  render() {
    const gestor = this.props.data;
    let { data } = this.state;

    function formatDate(timestamp) {
      const formattedTime = moment(timestamp).format('YYYY-MM-DD HH:mm:ss');
      const [datePart, timePart] = formattedTime.split(" ");
      const [year, month, day] = datePart.split("-");
      return `${day}/${month}/${year} ${timePart}`;
    };

    if (!data) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress style={{ color: '#000000' }} />
        </Box>
      );
    }

    if (typeof data === "string") {
      return <Alert severity="warning">{data}</Alert>
    }

    data = data[0];

    const createData = (name, value) => {
      return { name, value };
    };

    const rows = [
      createData('Data/Hora', formatDate(data.timeStamp)),
      createData('Tempo de Fechamento de Demanda (em segundos)', data.tempo_fechamento_demanda),
      createData('Moddo SSU', <><strong>{data.dados_adicionais.modo_ssu}</strong></>),
      createData('Pulsos Energia Ativa', data.pulsos_energia_ativa),
      createData('Pulsos Energia Reativa', data.pulsos_energia_reativa),
      createData('Energia Consumida MWH', data.energia_consumida_mwh),
      createData('Energia Consumida KWH', data.energia_consumida_kwh),
      createData('Energia Injetada KWH', data.energia_injetada_kwh),
      createData('Energia Consumida WH', data.energia_consumida_wh),
      createData('Energia Consumida MVARH', data.energia_consumida_mvarh),
      createData('Energia Consumida KVARH', data.energia_consumida_kvarh),
      createData('Energia Injetada KVARH', data.energia_injetada_kvarh),
      createData('Energia Consumida VARH', data.energia_consumida_varh),
      createData('Alarmes', data.abnt_bits.alarmes),
      createData('Posto Reativo', data.abnt_bits.posto_reativo),
      createData('Posto Horário', data.abnt_bits.posto_horario),
      createData('Quadrante', data.abnt_bits.quadrante),
      createData('Status Energia', data.status_energia),
      createData('Status Comunicação', data.dados_adicionais.status_comunicacao),
    ];

    let page = this.state.page;
    let rowsPerPage = this.state.rowsPerPage;

    return (
      <div>
        <h3>{gestor.id_equipamento} - {gestor.fazenda}</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover key={row.name}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.value}</TableCell>
                  </TableRow>
                );
            })}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onPageChange={this.handleChangePage}
          onRowsPerPageChange={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}
