import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  Typography,
  ListItem,
  ListItemIcon,
  Checkbox,
  FormControlLabel,
  Slider,
  TextField
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Notifications, Telegram } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { colors } from "../../constants/dc_constants";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { alertaService } from "../../services/alerta_service";
import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { makeAlertDialog } from "../../default/dialogs/DialogRefInterface";
import { validRequiredFields, validateShowResponseToast } from "../../helpers/toast_alerts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const marks = [
  {
    value: -0.5,
    label: "0,5i",
  },
  {
    value: 0,
    label: "0,00",
  },
  {
    value: 0.5,
    label: "0,5c",
  },
];

function convertSlider(value) {
  if (value) {
    //return parseFloat(mapValues(Math.abs(value), 1, 0, 0, 1).toFixed(2));
    return parseFloat(Math.abs(value)).toFixed(2);
  } else {
    return 0;
  }
}

function mapValues(value, in_min, in_max, out_min, out_max) {
  return ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
}

export default function FormAlerta(data) {
  const classes = useStyles();
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values) => {
    values.gestor_id = data.data.id;
    values.codigos_telegram = values.codigos_telegram;
    let finalValues = {...values};
    const requiredAlertField = [{name: "Chat ID telegram", value: "codigos_telegram"}];
    if (validRequiredFields(finalValues, requiredAlertField)){
      setIsSubmitting(true);
      if (deviceToEdit.codigos_telegram) {
        finalValues.min_fator_capacitivo = convertSlider(finalValues.min_fator_capacitivo);
        finalValues.min_fator_indutivo = convertSlider(finalValues.min_fator_indutivo);
        makeAlertDialog(
          () => {
            alertaService.updateAlerta(finalValues, (response) => {
              validateShowResponseToast(response);
            });
          },
          "Reprogramar...",
          "Deseja Realmente realizar essa operação"
          ).current.open();
          setIsSubmitting(false);
        } else {
          finalValues.min_fator_capacitivo = convertSlider(finalValues.min_fator_capacitivo);
          finalValues.min_fator_indutivo = convertSlider(finalValues.min_fator_indutivo);
          finalValues.fazenda = data.data.fazenda;
          makeAlertDialog(
            () => {
              alertaService.createAlerta(finalValues, (response) => {
                validateShowResponseToast(response)
              });
            },
            "Programar...",
            "Deseja Realmente realizar essa operação"
            ).current.open();
            setIsSubmitting(false);
          }
        }
  };

  useEffect(() => {
    async function devices() {
      try {
        await alertaService.getAlerta(data, (response) => {
          let device;
          if (response.data[0]) {
            device = response.data[0];
            device.min_fator_indutivo = -device.min_fator_indutivo;
            setDeviceToEdit(device);
          } else {
            setDeviceToEdit({
              min_fator_indutivo: -0.05,
              min_fator_capacitivo: 0.5,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    devices();
  }, [data]);

  useEffect(() => {
    async function devices() {
      try {
        await alertaService.getAlerta(data, (response) => {
          let device;
          if (response.data[0]) {
            device = response.data[0];
            device.min_fator_indutivo = -device.min_fator_indutivo;
            setDeviceToEdit(device);
          } else {
            setDeviceToEdit({
              min_fator_indutivo: -0.05,
              min_fator_capacitivo: 0.5,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    devices();
  }, []);

  const schema = yup.object().shape({});

  const PrettoSlider = withStyles({
    root: {
      color: colors.BLUE,
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    markLabel: {
      color: "#d8d8d8",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

  return (
    <Grid container spacing={3}>
      <FormControl variant="outlined" fullWidth>
        <Formik
          enableReinitialize={true}
          initialValues={
            deviceToEdit
              ? {
                min_fator_capacitivo: convertSlider(
                  deviceToEdit.min_fator_capacitivo
                ),
                min_fator_indutivo: -convertSlider(
                  deviceToEdit.min_fator_indutivo
                ),
                ...deviceToEdit,
              }
              : {}
          }
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ submitForm, values, setFieldValue }) => (
            <Form className={classes.form}>
              <Grid item xs={12}>
                <Table
                  style={{
                    backgroundColor: colors.WHITE,
                    fontSize: 14,
                    padding: 20,
                    width: "100%",
                    border: `1px solid ${colors.LIGHT_GREY}`,
                    borderRadius: 4,
                    marginBottom: 10,
                    color: colors.PRIMARY,
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th scope="col">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                          <Notifications
                            style={{
                              color: colors.ORANGE_POWER,
                              marginRight: 10
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 24,
                            }}
                          >
                            Alertas{" "}
                          </Typography>
                        </div>
                      </Th>
                      <Th scope="col"></Th>
                      <Th scope="col"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th colspan="3" style={{paddingBottom: "20px"}}>
                      <div style={{
                        display: "inline-flex",
                        flexDirection: "column",
                        width: "100%",
                        padding: "20px",
                        border: `2px solid ${colors.LIGHT_GREY}`,
                        borderRadius: "4px"
                      }}>
                          <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}>
                            <Telegram style={{color: colors.ORANGE_POWER}} />
                            <Typography style={{ paddingRight: 5 }}>
                              Chat IDs do Telegram{" "}
                            </Typography>
                          </div>
                          <TextField
                            id="codigos_telegram"
                            variant="outlined"
                            name="codigos_telegram"
                            type="text"
                            placeholder="Ex. -585553644 "
                            required
                            value={values.codigos_telegram || ""}
                            size="small"
                            helperText="* Separar os IDs com ponto e vírgula (;). Ex: 123456789;987654321"
                            onChange={(e) => {
                              let code = e.target.value;
                              setFieldValue(
                                "codigos_telegram",
                                code
                              );
                            }}
                          ></TextField>
                        </div>
                      </Th>
                    </Tr>
                    {data.data.equipamento == "ABB" && (
                      <Tr>
                        <Th>
                          <Typography
                            style={{
                              marginBottom: 0,
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                            align="left"
                          >
                            Tensão{" "}
                          </Typography>
                        </Th>
                        <Th>
                          <Grid container style={{justifyContent:"space-around"}}>
                            <Grid container item xs={3}>
                              <Typography>
                                Crítica{" "}
                              </Typography>
                              <Field
                                type="checkbox"
                                name="tensao_critica"
                                component={Checkbox}
                                style={{
                                  color: colors.BLUE,
                                  marginTop: 13,
                                  marginLeft: -45,
                                }}
                                checked={Boolean(values.tensao_critica)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue(
                                    "tensao_critica",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            </Grid>
                            <Grid container item xs={3}>
                              <Typography>
                                Precária{" "}
                              </Typography>
                              <Field
                                type="checkbox"
                                name="tensao_precaria"
                                component={Checkbox}
                                style={{
                                  color: colors.BLUE,
                                  marginTop: 13,
                                  marginLeft: -45,
                                }}
                                checked={Boolean(values.tensao_precaria)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue(
                                    "tensao_precaria",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Th>
                      <Th align="right" width="30%">
                          <FormControlLabel
                            style={{
                              color: colors.BLUE,
                              gap: 10
                            }}
                            label="Alertar sobre tensão"
                            labelPlacement="start"
                            control={
                              <Checkbox
                                style={{
                                  color: colors.BLUE,
                                }}
                                color="default"
                                name="tensao_telegram"
                                edge="start"
                                checked={
                                  Boolean(values.tensao_precaria) ||
                                  Boolean(values.tensao_critica)
                                }
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue(
                                    "tensao_precaria",
                                    checked ? 1 : 0
                                  );
                                  setFieldValue(
                                    "tensao_critica",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            }
                          />
                        </Th>
                      </Tr>
                    )}
                    <Tr>
                      <Th width="25%">
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Potência{" "}
                        </Typography>
                      </Th>
                      <Th width="45%"></Th>
                      <Th align="right" width="30%">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 0
                          }}
                          label="Alertar sobre potência"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              value={"excesso_potencia"}
                              checked={Boolean(values.excesso_potencia)}
                              onChange={(e) => {
                                const { checked } = e.target;
                                setFieldValue(
                                  "excesso_potencia",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </Th>
                    </Tr>
                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Fator de Potência{" "}
                        </Typography>
                      </Th>
                      <Th>
                        <Grid container>
                          {/* <Field
                            name={"min_fator_indutivo"}
                            value={values.min_fator_indutivo}
                          />
                          <Field
                            name={"min_fator_capacitivo"}
                            value={values.min_fator_capacitivo}
                          /> */}

                          <Grid item xs={6}>
                            <Typography
                              style={{
                                marginBottom: 0,
                                fontSize: 14,
                              }}
                              align="left"
                            >
                              Indutiva
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography
                              style={{
                                marginBottom: 0,
                                fontSize: 14,
                              }}
                              align="right"
                            >
                              Capacitiva
                            </Typography>
                          </Grid>
                          <PrettoSlider
                            valueLabelDisplay="auto"
                            name={"min_fator_indutivo"}
                            step={0.01}
                            max={1}
                            min={-1}
                            value={[
                              values.min_fator_indutivo,
                              values.min_fator_capacitivo,
                            ]}
                            marks={marks}
                            onChange={(e, value) => {
                              setFieldValue("min_fator_indutivo", value[0]);
                              setFieldValue("min_fator_capacitivo", value[1]);
                            }}
                          />
                        </Grid>
                      </Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 0
                          }}
                          label="Alertar fator de potência"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              value={"fator_potencia"}
                              checked={Boolean(values.fator_potencia)}
                              onChange={(e) => {
                                const { checked } = e.target;
                                setFieldValue(
                                  "fator_potencia",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </Th>
                    </Tr>
                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Horário de Ponta{" "}
                        </Typography>
                      </Th>
                      <Th></Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 10
                          }}
                          label="Alertar horário de ponta"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              value={"uso_horario_ponta"}
                              edge="start"
                              checked={Boolean(values.uso_horario_ponta)}
                              onChange={(e) => {
                                const { checked } = e.target;
                                setFieldValue(
                                  "uso_horario_ponta",
                                  checked ? 1 : 0
                                );
                              }}
                            />
                          }
                        />
                      </Th>
                    </Tr>
                    <div style={{ padding: '8px 0' }} />
                    {data.data.equipamento == "ABNT" && (
                      <Tr>
                        <Th>
                          <Typography
                            style={{
                              marginBottom: 0,
                              fontWeight: "bold",
                              fontSize: 18,
                            }}
                            align="left"
                          >
                            Queda de energia{" "}
                          </Typography>
                        </Th>
                        <Th></Th>
                        <Th align="right">
                          <FormControlLabel
                            style={{
                              color: colors.BLUE,
                              gap: 10
                            }}
                            label="Alertar queda de energia"
                            labelPlacement="start"
                            control={
                              <Checkbox
                                style={{ color: colors.BLUE }}
                                color="default"
                                value={"queda_energia"}
                                edge="start"
                                checked={Boolean(values.queda_energia)}
                                onChange={(e) => {
                                  const { checked } = e.target;
                                  setFieldValue(
                                    "queda_energia",
                                    checked ? 1 : 0
                                  );
                                }}
                              />
                            }
                          />
                        </Th>
                      </Tr>
                    )}
                    <Tr>
                      <Th colspan="3" style={{ textAlign: "right" }}>
                        <Button
                          disabled={isSubmitting}
                          fullWidth
                          variant="contained"
                          style={{
                            backgroundColor: colors.ORANGE_POWER,
                            color: "white",
                            marginTop: 10,
                            width: 220,
                            height: 45,
                            fontSize: 12,
                            boxShadow: "none"
                          }}
                          onClick={submitForm}
                        >
                          Programar envio de alerta{" "}
                        </Button>
                      </Th>
                    </Tr>
                  </Tbody>
                </Table>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormControl>
    </Grid>
  );
}
