import React from "react"
import {
  EditRounded,
  LocationOff,
  BarChartRounded,
  ReceiptRounded,
  NetworkCheck,
  RemoveRedEye,
  FiberManualRecord,
  OfflineBolt,
  AddCircle,
  GavelOutlined

} from "@material-ui/icons"
import { Tooltip } from "@material-ui/core"
import { Grid, FormControl, InputLabel, NativeSelect } from "@material-ui/core";

import { headRowsGestorAgua } from "../constants/headRows"
import ObjectTable from "../default/table/ObjectTable"
import {
  makeActionFormDialog,
  makeAlertDialog,
} from "../default/dialogs/DialogRefInterface"
import ChartGestor from "../projectWater/_graficos/ChartGestor"
import { gestorService } from "../services/gestor_service"
import { validateShowResponseToast, validRequiredFields, validRequiredFieldsTable } from "../helpers/toast_alerts"
import { colors } from "../constants/dc_constants"
import { checkOnline } from "../util/gestor_utils"
import { requiredOutorgaFields, requiredWabsFields } from "../constants/equipmentConstants"
import WaterDropIcon from '@mui/icons-material/WaterDrop';
import FormGestorWater from "./FormGestorWater";
import ChartGestorOnWater from "../projectWater/_graficos/ChartGestorOn";
import RelatoriosWater from "../projectWater/_relatorios";
import ShowGestorWater from "./ShowGestorWater";
import FormOutorga from "./FormOutorga";
import { outorgaService } from "../services/outorga_service";

class TableGestor extends React.Component {
  constructor(props) {
    super(props);
    var user = JSON.parse(localStorage.getItem("user"));
    var permission = user["permissao"];

    this.state = {
      rows: [],
      online: [],
      headRows: headRowsGestorAgua,
      hasEditPermission: permission >= 3,
      statusEquipment: "1"
    };
  }

  getGestores = (statusEquipment) => {
    gestorService.getGestores("water", { statusEquipments: statusEquipment ?? this.state.statusEquipment }, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          rows: response.data,
          online: response.data.coletas,
        });
      }
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    this.getGestores(value);
  }

  componentDidMount() {
    const newHeadRows = [
      ...headRowsGestorAgua,
      {
        id: "status",
        disablePadding: true,
        label: "Status Conexão",
        type: "custom_row",
        handleCustom: (rowValue) => {
          if (!rowValue.coletasAguas[0]) {
            return (
              <Tooltip title="Sem dados" value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREY }} />
              </Tooltip>
            );
          }
          if (checkOnline(rowValue.coletasAguas[0])) {
            return (
              <Tooltip title="Online" value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREEN }} />
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Offline" value={rowValue.status}>
              <FiberManualRecord style={{ color: colors.RED }} />
            </Tooltip>
          );
        },
      },
    ];
    if (this.state.hasEditPermission) {
      this.actions.push({
        title: "Editar",
        label: "Editar",
        color: colors.GREEN,
        MaterialIcon: EditRounded,
        handleClick: (e, selected) => {
          e.stopPropagation();
          makeActionFormDialog(
            FormGestorWater,
            "Editar Medidor",
            null,
            selected,
            "Salvar",
            (data) => {
              let requiredFields = requiredWabsFields
              if (validRequiredFields(data, requiredFields)) {
                gestorService.updateGestor(data, (response) => {
                  if (validateShowResponseToast(response)) {
                    this.getGestores();
                  }
                });
              }
            }
          ).current.open();
        },
      });
      this.actions.push({
        title: "Editar outorga",
        label: "Editar outorga",
        color: colors.GREEN,
        MaterialIcon: GavelOutlined,
        handleClick: (e, selected) => {
          e.stopPropagation();
          makeActionFormDialog(
            FormOutorga,
            `Editar Outorga de ${selected.fazenda}`,
            null,
            selected,
            "Salvar",
            (dataOutorga) => {
              const requiredFields = requiredOutorgaFields;
              if (validRequiredFieldsTable(dataOutorga.outorga, requiredFields)) {
                if(selected.outorgas.length > 0){
                  outorgaService.updateOutorga(dataOutorga.outorga, (response) => {
                    if (validateShowResponseToast(response, "Outorga")) {
                      this.getGestores()
                    }
                  })
                } else {
                  outorgaService.createOutorga(dataOutorga.outorga, (response) => {
                    if (validateShowResponseToast(response, "Outorga")) {
                      this.getGestores()
                    }
                  })
                }
              }
            }
          ).current.open();
        }
      })
      this.actions.push({
        title: "Ativar/Desativar",
        label: "Ativar/Desativar",
        color: colors.GREEN,
        MaterialIcon: LocationOff,
        handleClick: (e, selected) => {
          e.stopPropagation();
          makeAlertDialog(
            () => {
              const data = { id: selected.id };
              gestorService.deleteGestor(data, (response) => {
                if (validateShowResponseToast(response)) {
                  this.getGestores();
                }
              });
            },
            "Atenção",
            `Deseja realmente ${selected.status == 1 ? "desativar" : "ativar"} este medidor?`
          ).current.open();
        },
      });
    }

    this.setState({
      headRows: newHeadRows,
    });
    this.getGestores();
  }

  selectedToolActions = [];

  iconAdd = () => (<AddCircle style={{ color: colors.BLUE }} />)

  toolActions = [
    {
      title: "Adicionar",
      label: "Adicionar",
      MaterialIcon: this.iconAdd,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormGestorWater,
          "Adicionar Medidor",
          false,
          null,
          "Continuar",
          (data) => {
            let requiredFields = requiredWabsFields;
            if (validRequiredFields(data, requiredFields)) {
              gestorService.createGestor(data, (response) => {
                if (validateShowResponseToast(response, "Gestor")) {
                  makeActionFormDialog(
                    FormOutorga,
                    "Definir outorga",
                    false,
                    response.data,
                    "Salvar",
                    (dataOutorga) => {
                      requiredFields = requiredOutorgaFields;
                      if (validRequiredFieldsTable(dataOutorga.outorga, requiredFields)) {
                        outorgaService.createOutorga(dataOutorga.outorga, (response) => {
                          if (validateShowResponseToast(response, "Outorga")) {
                            this.getGestores()
                          }
                        })
                      }
                    }
                  ).current.open();
                }
              });
            }
          }
        ).current.open();
      },
    },
  ];

  actions = [
    {
      title: "Gráficos Online",
      color: colors.GREEN,
      MaterialIcon: NetworkCheck,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ChartGestorOnWater,
          `Painel Online`,
          true,
          selected,
          null,
          null
        ).current.open();
      },
    },
    {
      title: "Ver Gráficos",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: BarChartRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ChartGestor,
          `Gráficos do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          selected,
          null,
          null
        ).current.open();
      },
    },
    {
      title: "Ver Relatório",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: ReceiptRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();

        makeActionFormDialog(
          () => RelatoriosWater(selected),
          `Relatorios do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          null,
          null,
          null,
        ).current.open();
      },
    },
    {
      title: "Ver",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: RemoveRedEye,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ShowGestorWater,
          "Dados do Medidor",
          false,
          selected,
          "",
          () => { }
        ).current.open();
      },
    }
  ];

  iconHead = [
    {
      MaterialIcon: () => <WaterDropIcon style={{ color: colors.BLUE }} />,
    },
  ];

  render() {
    return (
      <>
        {this.state.hasEditPermission && (
          <Grid container spacing={3}>
            <Grid item md={10}></Grid>
            <Grid item md={2}>
              <InputLabel shrink={false}>
                <span style={{ fontSize: 18, fontWeight: "bold" }}>Mostrar Equipamentos</span>
              </InputLabel>
              <NativeSelect
                fullWidth
                value={this.state.statusEquipment}
                name="statusEquipment"
                onChange={this.handleChange}
                style={{ backgroundColor: "#F7F7F8" }}
              >
                <option value={1}>Ativos</option>
                <option value={0}>Inativos</option>
              </NativeSelect>
            </Grid>
          </Grid>
        )
        }

        <ObjectTable
          rowId="id"
          tableName="Medidores"
          materialIconHead={this.iconHead}
          headRows={this.state.headRows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.state.hasEditPermission ? this.toolActions : []}
          rowSize={40}
          rowsOptions={[20, 40, 80]}
          dense={true}
          rows={this.state.rows || []}
        />
      </>

    );
  }
}

export default TableGestor;
