import React from "react";
import {
  Typography,
  Grid,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
} from "@material-ui/core";
import { colors } from "../../constants/dc_constants";

const styleQuadro = {
  box: {
    height: "68vh",
    backgroundColor: colors.LIGHT_GREY,
    borderColor: colors.DARK_GREY
  },

  tableText: {
    fontSize: 18,
  },

  tableTextRed: {
    fontSize: 18,
    color: "#a60b00",
  },
};

export default function Quadro({ data, gestorName }) {
  return (
    <Grid border={2} style={styleQuadro.box}>
      <Grid item xs={12}>
        <Typography variant="h5" align="center">
          Equipamento ({gestorName})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={styleQuadro.tableText}>Hoje</TableCell>
              <TableCell style={styleQuadro.tableText}>7d</TableCell>
              <TableCell style={styleQuadro.tableText}>30d</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* QUADRO DO UFER */}
            <TableRow key={4}>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                Ufer Reservado
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_dia_reservado}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_7d_reservado}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_30d_reservado}
              </TableCell>
            </TableRow>
            <TableRow key={5}>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                Ufer Fora de Ponta
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_dia_fora_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_7d_fora_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_30d_fora_ponta}
              </TableCell>
            </TableRow>
            <TableRow key={6}>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                Ufer Hora de Ponta
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_dia_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_7d_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableTextRed} align="left">
                {data.ufer_30d_ponta}
              </TableCell>
            </TableRow>

            {/* QUADRO DO CONSUMO */}
            <TableRow key={1}>
              <TableCell style={styleQuadro.tableText} align="left">
                kWh Reservado
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_dia_reservado}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_7d_reservado}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_30d_reservado}
              </TableCell>
            </TableRow>
            <TableRow key={2}>
              <TableCell style={styleQuadro.tableText} align="left">
                kWh Fora de Ponta
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_dia_fora_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_7d_fora_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_30d_fora_ponta}
              </TableCell>
            </TableRow>
            <TableRow key={3}>
              <TableCell style={styleQuadro.tableText} align="left">
                kWh Hora de Ponta
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_dia_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_7d_ponta}
              </TableCell>
              <TableCell style={styleQuadro.tableText} align="left">
                {data.kw_30d_ponta}
              </TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
