export function makeQuery(data){
    let strRet = "?"
    const keysData = Object.keys(data)
    keysData.forEach(key => {
        const simb = keysData.indexOf(key) === keysData.length - 1 ? "" : "&"
        if(data[key] !== null && data[key] !== ""){
            let value = data[key]
            if(typeof value === 'boolean'){
                value = Number(value)
            }
            strRet = strRet.concat(`${key}=${value}${simb}`)
        }
    })
    return strRet
}