import React from "react"
import Chart from "react-google-charts";

class CustomPieChart extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			options: {},
			data: [],
			fulltext: "",
		}
	}

	componentDidMount() {
		let value = this.props.value;
		let rest = (this.props.total - this.props.value) || 0;
		if(rest <= 0){
			rest = 0;
			value = this.props.total;
		}
		this.setState({
			options: {
				chartArea: {
					width: "90%",
					height: "90%",
				},
				width: "100%",
				height: "100%",
				backgroundColor: "transparent",
				legend: "none",
				pieHole: this.props.pieHole,
				is3D: false,
				pieSliceText: 'none',
				tooltip: { trigger: 'none' },
				slices: {
					0: { color: this.props.valueColor },
					1: { color: this.props.restColor }
				}
			},
			fulltext: this.props.unit == "%" ? 
				`${((this.props.value * 100) / this.props.total).toFixed(1)} %`
				: 
				`${this.props.value.toFixed(1)} ${this.props.unit}`,
			data: [
				['teste', 'teste'],
				["value", value],
				["rest", rest]
			],

		})
	}

	render() {
		return (
			<div style={{ height: "inherit", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<div style={{ position: "relative" }}>
				<Chart
					chartType="PieChart"
					data={this.state.data}
					options={this.state.options}
				/>
				<div style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: "14px",
						textWrap: "nowrap"
          }}>
						{this.state.fulltext}
					</div>
					</div>
			</div>
		)
	}
}

export default CustomPieChart