import React from "react";
import Chart from "react-google-charts";
import { Grid, Typography, Box, Divider } from "@material-ui/core";
import { colors } from "../../constants/dc_constants";
import "./style.css";

export class GaugeChartNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.boxRef = React.createRef();
  }

  styles = {
    box: {
      minHeight: "calc(45vh - 40px)",
      backgroundColor: colors.LIGHT_GREY,
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column",
    },
    chart: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      maxHeight: "30vh", /* Use here to change chart height */
      height: "100%",
    },
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateSize);
  }

  makeData = () => {
    let value = Number(this.props.value);
    let showValue = 0;
    try {
      value = Math.floor(value * 100) / 100;
      showValue = this.props.showValue || value;
      showValue = Math.floor(showValue * 100) / 100;
    } catch (error) {}
    return [
      ["Label", "Value"],
      [this.props.label, { v: value, f: showValue }],
    ];
  };

  render() {
    return (
      <Box
        ref={this.boxRef}
        border={1}
        borderColor={colors.LIGHT_GREY}
        style={this.styles.box}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              <strong>{this.props.title}</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        <Grid container style={{ flexGrow: 1 }}>
          <Grid
            item xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Chart
              className="chart_gauge"
              style={this.styles.chart}
              chartType="Gauge"
              loader={<div>Carregando Gráfico</div>}
              data={this.makeData()}
              options={{
                ...this.props,
                minorTicks: 5,
                animation: {
                  duration: 100,
                },
              }}
            />
          </Grid>
          {/* <Divider style={{ width: "100%" }} />
          <Grid item xs={12}></Grid>
          <Grid container>
            {this.props.children && <>{this.props.children()}</>}
          </Grid> */}
        </Grid>
      </Box>
    );
  }
}

export default GaugeChartNew;
