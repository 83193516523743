import moment from 'moment'

export function sortByDate(data = [], attr = 'data_coleta') {
	try {
		return data.sort((a, b) => {
			if (moment(a[attr]).isAfter(moment(b[attr]))) {
				return 1
			} else if (moment(a[attr]).isBefore(moment(b[attr]))) {
				return -1
			}
			return 0
		})
	} catch (ex) {
		return data
	}
}

export function formatValueByType(value, type) {
	switch (type) {
		case 'money':
			return formatDecimal(value, 2)
		case 'money2':
			return formatDecimal(value, 3)
		case 'date':
			let ret = value ? moment(value).format('DD/MM/YYYY') : ""
			return ret
		case 'date_time':
			return value ? moment(value).format('DD/MM HH:mm:ss') : ""
		case 'status':
			let status = convertStatusToText(value)
			return status
		case 'precision2':
			return formatDecimal(value)
		case 'text':
			return value
		default:
			return value
	}
}

export function formatFullDateTime(dateTime) {
	try {
		return moment(dateTime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss");
	} catch (error) {
		return "Invalid date time.";
	}
};

export function formatDateTime(dateTime, ignoreHours, breakHourLine, monthly) {
	ignoreHours = ignoreHours == true;
	const formatType = ignoreHours ? 'DD/MM' : 'DD/MM-HH:mm';
	let data = moment(dateTime).format(formatType);

	if (breakHourLine) {
		data = data.replace(":", "\n");
	}

	if (monthly) {
		data = moment(dateTime).format('MMMM');
	}
	return data;
}

export function convertStatusToNumber(status) {
	if (status === "ATIVO" || status === "ATIVA" ||
		status === "ABERTO" || status === "ABERTA"
		|| status === 1) {
		return 1
	} else {
		return 0
	}
}

export function convertStatusToText(status) {
	if (status === 1) {
		return "ATIVO"
	} else {
		return "INATIVO"
	}
}

export function formatCPF(cpf) {
	//retira os caracteres indesejados...
	cpf = cpf.replace(/[^\d]/g, "");
	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function clearCPF(cpf) {
	return cpf.replace(/-/g, '').replace(/\./g, '')
}

export function clearAllMask(value) {
	return value.replace(/-/g, '')
		.replace(/\./g, '')
		.replace(/\(/g, '')
		.replace(/\)/g, '')
		.replace(/-/g, '')
		.replace(/\//g, '')
}

export function formatName(value) {
	let name = value.split(" ")
	name = `${name[0]} ${name[1] && name[1].length > 3 ? name[1] : name[2] || ""}`
	return name
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
/* eslint-disable-next-line */
Number.prototype.format = function (n, x, s, c) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
		num = this.toFixed(Math.max(0, ~~n));
	return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export function formatMoney(value, decimal = 2) {
	if (value >= 0) {
		return `R$${Number(value).format(decimal, 3, '.', ',')}`
	} else {
		return Number(value).format(decimal, 3, '.', ',').replace('-', '-R$')
	}
}

export function formatDecimal(value) {
	var setDecimal = {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2,
	};
	if (value === null || value === undefined)
		return " -- ";
	else
		return parseFloat(value).toLocaleString("de-DE", setDecimal);
}

export function floorTo2(value) {
	return Math.floor(value * 100) / 100
}

export function getMonthName(monthNumber) {
	return moment().month(monthNumber - 1).format('MMMM');
}

export function makeConsumptionByDay(groupedByDay, outorga) {
	const numberOfDays = moment(groupedByDay[0].date).daysInMonth();
	const dailyVolume = [["dia", "Volume captado (m³)", "Volume acumulado (m³)", "Volume outorgado (m³)"]]
	let acc = 0;
	let activeDay = moment(groupedByDay[0].date).format('D');
	for (var i = 1; i <= numberOfDays; i++) {
		if (i == activeDay) {
			acc += parseFloat(groupedByDay[0].consumptionSum.toFixed(2))
			dailyVolume.push([i + '', parseFloat(groupedByDay[0].consumptionSum.toFixed(2)), acc, outorga])
			groupedByDay.shift();
			activeDay = groupedByDay.length > 0 ? moment(groupedByDay[0].date).format('D') : 0;
		} else {
			dailyVolume.push([i + '', 0, acc, outorga])
		}
	};
	return dailyVolume;
}

export function makePumpedDays(groupedByDay, outorga) {
	let pumpedDays = [];
	let overPumpedDays = [];
	let date = '';

	groupedByDay.forEach(day => {
		date = moment(day.date).format('L');
		if (day.consumptionSum > outorga) {
			overPumpedDays.push(date);
		} else {
			pumpedDays.push(date);
		}
	})
	const days = { pumpedDays, overPumpedDays }
	return days;
}

export function makeFlowWater(ungrouped, flowOutorga) {
	const flowData = [['data-hora', 'Vazão instantânea (m³/h)', 'Vazão outorgada (m³/h)']];
	ungrouped.forEach(item => {
		flowData.push([
			moment(item.data_coleta).format('DD/MM HH:mm'),
			item.vazao,
			flowOutorga
		])
	})
	return flowData;
}

export function makeHoursWorking(ungroupedData) {
	const working = ungroupedData.filter(item => item.bomba_ligada);
	return (moment.duration(working.length * 10, 'minutes')).asHours().toFixed(1);
}

export function addOutorgaToReport(data, volume, vazao) {
	const reportData = data.map(item => ({
		...item,
		volumeOutorgado: volume,
		vazaoOutorgada: vazao
	}))
	return reportData;
}

export function makePeriods(firstDate){
	let periods = [];
	let month = moment(firstDate).startOf('month');
	let i = 1;
	while (moment().startOf('month').isSameOrAfter(month)){
		periods.push({
			id: i,
			date: month.clone(),
			dateLabel: month.format('MM/YYYY')
		});
		month.add(1,'month');
		i++;
	}
	return periods;
}