import React from "react";
import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { History } from "@material-ui/icons";
import { headRowsAlerts } from "../../constants/headRows";
import ObjectTable from "../../default/table/ObjectTable";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { gestorService } from "../../services/gestor_service";
import { colors } from "../../constants/dc_constants";
import FormAlerta from "./FormAlerta";
import { alertaService } from "../../services/alerta_service";

class TelaAlertasWater extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gestores: [],
      gestor: null,
      headRows: headRowsAlerts,
      rows: [],
    };

    this.objectTableRef = React.createRef();
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "gestor") {
      const gestorValue = this.state.gestores.find(
        (gtr) => gtr.id === Number(value)
      );

      this.setState({ [name]: gestorValue });
      this.getLogAlerta(gestorValue);
    } else {
      this.setState({ [name]: value });
    }
  };

  formUpdateAlerts = () => {
    return (
      <>
        <Grid item md={12}>
          <FormAlerta data={this.state.gestor} />
        </Grid>

        <ObjectTable
          rowId="id"
          tableName="Histórico últimos sete dias"
          materialIconHead={this.iconHead}
          headRows={this.state?.headRows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.toolActions}
          rowSize={40}
          rowsOptions={[20, 40, 80]}
          dense={false}
          rows={this.state?.rows}
          order={"desc"}
        />
      </>
    );
  };

  getGestores = () => {
    gestorService.getGestores("water",{}, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  getLogAlerta = (gestor) => {
    alertaService.getAlerta(gestor.id, (response) => {
      let device = response.data[0];
      if (device) {
        alertaService.getAlertaLog(device, (response) => {
          this.setState({
            rows: response.data,
          });
        });
      } else {
        this.setState({
          rows: [],
        });
      }
    });
  };

  componentDidMount() {
    this.getGestores();
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.formUpdateAlerts();
      // alternative: store keyValue as state to trigger re-render
    }
  }
  selectedToolActions = [];

  toolActions = [];

  actions = [];

  iconHead = [
    {
      MaterialIcon: History,
      color: colors.BLUE,
    },
  ];

  render() {
    return (
      <Grid container justifyContent="center" spacing={3}>
        <Grid item md={4}>
          <InputLabel align="left" htmlFor="gestor">
            Selecione o Medidor :
          </InputLabel>
          <Select
            fullWidth
            align="left"
            value={this.state.gestor ? this.state.gestor.id : 0}
            name="gestor"
            onChange={this.handleChange}
          >
            {this.state.gestores.map((ges) => (
              <MenuItem
                key={ges.id}
                value={ges.id}
              >{`${ges.id_equipamento} - ${ges.fazenda}`}</MenuItem>
            ))}
          </Select>
        </Grid>

        {/* <Grid item md={2}>
          <Button
              fullWidth
              variant="contained"
              style={{ backgroundColor: "green", color: "white", marginTop: 10 }}
              onClick={this.handleClick}
          >
            Filtrar
          </Button>
        </Grid> */}

        {this.state?.gestor ? this.formUpdateAlerts() : ""}
      </Grid>
    );
  }
}

export default TelaAlertasWater;
