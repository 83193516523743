import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  FormControl,
  Typography,
  Checkbox,
  FormControlLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Notifications, Telegram } from "@material-ui/icons";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { colors } from "../../constants/dc_constants";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { alertaService } from "../../services/alerta_service";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { makeAlertDialog } from "../../default/dialogs/DialogRefInterface";
import { validRequiredFields, validateShowResponseToast } from "../../helpers/toast_alerts";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(3),
  },
}));

export default function FormAlerta(data) {
  const classes = useStyles();
  const [deviceToEdit, setDeviceToEdit] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (values) => {
    values.gestor_id = data.data.id;
    values.codigos_telegram = values.codigos_telegram;
    values.min_fator_capacitivo = 0; //No banco de dados, esses campos estão programados com 0.92 como
    values.min_fator_indutivo = 0;   //valores padrão, por isso aqui defino os valores deles como 0
    values.ultrapassagem_outorga = 1;
    values.fazenda = data.data.fazenda;
    const requiredAlertField = [{name: "Chat ID telegram", value: "codigos_telegram"}];
    if (validRequiredFields(values, requiredAlertField)){
      setIsSubmitting(true);
    if (deviceToEdit.codigos_telegram) {
      makeAlertDialog(
        () => {
          alertaService.updateAlerta(values, (response) => {
            validateShowResponseToast(response)
          });
        },
        "Reprogramar...",
        "Deseja Realmente realizar essa operação"
      ).current.open();
      setIsSubmitting(false);
    } else {
      makeAlertDialog(
        () => {
          alertaService.createAlerta(values, (response) => {
            validateShowResponseToast(response)
          });
        },
        "Programar...",
        "Deseja Realmente realizar essa operação"
      ).current.open();
      setIsSubmitting(false);
    }
    }
  };

  useEffect(() => {
    async function devices() {
      try {
        await alertaService.getAlerta(data, (response) => {
          let device;
          if (response.data[0]) {
            device = response.data[0];
            setDeviceToEdit(device);
          } else {
            setDeviceToEdit({});
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    devices();
  }, [data]);

  useEffect(() => {
    async function devices() {
      try {
        await alertaService.getAlerta(data, (response) => {
          let device;
          if (response.data[0]) {
            device = response.data[0];
            setDeviceToEdit(device);
          } else {
            setDeviceToEdit({});
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
    devices();
  }, []);

  const schema = yup.object().shape({});

  return (
    <Grid container spacing={3}>
      <FormControl variant="outlined" fullWidth>
        <Formik
          enableReinitialize={true}
          initialValues={
            deviceToEdit
              ? {
                  ...deviceToEdit,
                }
              : {}
          }
          onSubmit={onSubmit}
          validationSchema={schema}
        >
          {({ submitForm, values, setFieldValue }) => (
            <Form className={classes.form}>
              <Grid item xs={12}>
                <Table
                  style={{
                    backgroundColor: colors.WHITE,
                    fontSize: 14,
                    padding: 20,
                    width: "100%",
                    border: `1px solid ${colors.LIGHT_GREY}`,
                    borderRadius: 4,
                    marginBottom: 10,
                    color: colors.PRIMARY
                  }}
                >
                  <Thead>
                    <Tr>
                      <Th scope="col">
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                          <Notifications
                            style={{
                              color: colors.BLUE,
                              marginRight: 10
                            }}
                          />
                          <Typography
                            style={{
                              fontWeight: "bold",
                              fontSize: 24,
                            }}
                          >
                            Alertas{" "}
                          </Typography>
                        </div>
                      </Th>
                      <Th scope="col"></Th>
                      <Th scope="col"></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                  <Tr>
                      <Th colspan="3" style={{ paddingBottom: "20px" }}>
                        <div style={{
                          display: "inline-flex",
                          flexDirection: "column",
                          width: "100%",
                          padding: "20px",
                          border: `2px solid ${colors.LIGHT_GREY}`,
                          borderRadius: "4px"
                        }}>
                          <div style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}>
                            <Telegram style={{ color: colors.BLUE }} />
                            <Typography style={{ paddingRight: 5 }}>
                              Chat IDs do Telegram{" "}
                            </Typography>
                          </div>
                          <TextField
                            id="codigos_telegram"
                            variant="outlined"
                            name="codigos_telegram"
                            type="text"
                            placeholder="Ex. -585553644 "
                            required
                            value={values.codigos_telegram || ""}
                            size="small"
                            helperText="* Separar os IDs com ponto e vírgula (;). Ex: 123456789;987654321"
                            onChange={(e) => {
                              let code = e.target.value;
                              setFieldValue(
                                "codigos_telegram",
                                code
                              );
                            }}
                          ></TextField>
                        </div>
                      </Th>
                    </Tr>
                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Funcionamento{" "}
                        </Typography>
                      </Th>

                      <Th></Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 10
                          }}
                          label="Alerta se a bomba ligou ou desligou"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              edge="start"
                              checked={true}
                              disabled
                            />
                          }
                        />
                      </Th>
                    </Tr>

                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Dias captados{" "}
                        </Typography>
                      </Th>

                      <Th></Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 10
                          }}
                          label="Alerta sobre extrapolação dos dias captados"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              edge="start"
                              checked={true}
                              disabled
                            />
                          }
                        />
                      </Th>
                    </Tr>

                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Horas captadas{" "}
                        </Typography>
                      </Th>

                      <Th></Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 10
                          }}
                          label="Alerta sobre extrapolação de horas de captação"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              edge="start"
                              checked={true}
                              disabled
                            />
                          }
                        />
                      </Th>
                    </Tr>

                    <Tr>
                      <Th>
                        <Typography
                          style={{
                            marginBottom: 0,
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                          align="left"
                        >
                          Ultrapassagem de volume{" "}
                        </Typography>
                      </Th>

                      <Th></Th>
                      <Th align="right">
                        <FormControlLabel
                          style={{
                            color: colors.BLUE,
                            gap: 10
                          }}
                          label="Alerta sobre ultrapassagem de volume captado"
                          labelPlacement="start"
                          control={
                            <Checkbox
                              style={{
                                color: colors.BLUE,
                              }}
                              color="default"
                              edge="start"
                              checked={true}
                              disabled
                            />
                          }
                        />
                      </Th>
                    </Tr>

                    <Tr>
                      <Th colspan="3" style={{ textAlign: "right" }}>
                        <Button
                          disabled={isSubmitting}
                          fullWidth
                          variant="contained"
                          style={{
                            backgroundColor: colors.BLUE,
                            color: "white",
                            marginTop: 10,
                            width: 220,
                            height: 45,
                            fontSize: 12,
                            boxShadow: "none"
                          }}
                          onClick={submitForm}
                        >
                          Programar envio de alerta{" "}
                        </Button>
                      </Th>
                    </Tr>
                  </Tbody>
                </Table>
              </Grid>
            </Form>
          )}
        </Formik>
      </FormControl>
    </Grid>
  );
}
