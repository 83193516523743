import React from 'react'
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge'
import { colors } from '../../constants/dc_constants'

export function Action(props) {
  const { title, badgeCount, MaterialIcon, handleClick, row, color = 'primary' } = props
  return (
    <Tooltip title={title}>
      <Badge badgeContent={badgeCount} color="secondary" overlap="rectangular">
        <IconButton onClick={e => handleClick(e, row)}>
          <MaterialIcon style={{ color: colors.BLUE || "primary"}} />
        </IconButton>
      </Badge>
    </Tooltip>
  )
}

export function ActionTooltip(props) {
  const { title, label, handleClick, MaterialIcon, color, selected } = props
  return (
    <Tooltip title={title}>
      <IconButton aria-label={label} onClick={e => handleClick(e, selected)}>
        <MaterialIcon style={{ color: color || "primary"}} />
      </IconButton>
    </Tooltip>
  )
}

export function IconHead(props) {
  const { MaterialIcon, color } = props
  return (
    <IconButton>
      <MaterialIcon style={{ color: color || "primary"}} />
    </IconButton>
  )
}
