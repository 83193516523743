import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import ChartGestor from "./ChartGestor";

export default function TokenLogin() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {}, []);

  return <ChartGestor></ChartGestor>;
}
