import React from "react"
import Chart from "react-google-charts"
import { Card, Grid, Typography, Menu, Button, FormControl, NativeSelect } from "@material-ui/core"
import { selectOptions, selectOptionsABNT, selectOptionsWater } from "../constants/headRows"
import { formatDateTime } from "../util/utils"
import { withStyles } from "@material-ui/core/styles"
import { FilterListRounded } from "@material-ui/icons"
import FilterGestor from "../projectPower/_graficos/FilterGestor"
import { colors } from "../constants/dc_constants"
import { calculateTensaoIntervals } from "../util/gestor_utils"

const COLOR_CRITICO = "#f7dcdc"
const COLOR_PRECARIO = "#fcfbbb"
const COLOR_ADEQUADO = "#d3f5e4"
const COLOR_DEFAULT = "blue"
const COLOR_DEFAULT2 = "green"
const COLOR_PONTA = "red"
const COLOR_F_PONTA = "#0077ff"
const COLOR_RESERVADO = "green"
const COLOR_EXTRA = "purple"
const COLOR_CONTRACTED_DEMAND = "black"
const COLOR_CONTRACTED_DEMAND_TOLERANCE = "gray"
const COLOR_INJECTED_POWER = "#d1c700"

// breakHourLine -> quebra a linha na legenda das horas
// slantedText -> faz com que o texto da data fique com uma angulação de 15 graus, ideal para não ficar quebrando as legendas quando tem muitas
// isStacked -> agrupa os gráficos
// canBeStackedAndNormal -> faz com que o gráfico possa alternar entre esses dois estados
// isGroupped -> agrupar as informações de registros com mesma data, útil para usar com o isStacked

class LineChart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currentLabels: [],
      currentData: [],
      currentXLabel: "Valor",
      currentYLabel: "Data e Hora",
      options: [],
      typeChart: "bars",

      openModal: false,
      series: [],
      extraLabel: [],
      extraData: [],
      vAxis: {},
      yLabel: "",
      isStacked: this.props.isStacked,
    };

    this.handleSelectData = this.handleSelectData.bind(this);
    this.handleOpenFilter = this.handleOpenFilter.bind(this);
  }

  componentDidMount() {
    var filterSelection = selectOptions;

    if (this.props.device == "water") {
      filterSelection = selectOptionsWater
    } else {
      if (this.props.selected &&
        this.props.selected.length > 0 &&
        this.props.selected[0].equipamento == "ABNT") {
        var filterSelection = selectOptionsABNT;
      }

      if (this.props.hasInjected) {
        filterSelection.map((filter) => {
          if (filter.id === "potencia" && filter.headRows.length < 3) {
            filter.headRows.push({
              id: "potencia_injetada_ativa",
              showChart: false,
              disablePadding: false,
              label: "Potência Injetada",
            })
          }
          if (filter.id === "consumoKwh" && filter.headRows.length < 3) {
            filter.headRows.push({
              id: "energia_injetada_kwh",
              showChart: false,
              disablePadding: false,
              label: "Energia injetada",
            })
          }
        })
      }
    }

    this.setState({
      labels: this.labels,
      options: [...filterSelection].map((so) => {
        const isSelected =
          this.props.chartSelecteds &&
          this.props.chartSelecteds.indexOf(so.id) != -1;

        so["selected"] = isSelected;
        so["unique"] = isSelected;
        so.headRows = so.headRows.map((hr) => {
          hr = { ...JSON.parse(JSON.stringify(hr)) };
          hr.showChart = isSelected;
          return hr;
        });
        return { ...so };
      }),
    });
  }

  getModalStyle = () => {
    const top = 50;
    const left = 50;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  };

  updateSelecteds = () => {
    let series = {};
    let extraData = [];
    let extraLabel = [];
    let labels = ["Data da Coleta"];
    let ids = ["data_coleta"];
    let type = "bars";
    let data = [];
    let yLabel = "";
    let lVAxis = {
      viewWindow: {
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER,
      },
    };

    let pontoSeries = [];

    let pontoLabels = ["Ponta", "Fora de Ponta", "Reservado"];
    let ultrapassagemLabels = ["Atingida", "Não atingida"];
    let includesContractedDemand = ["potencia", "potenciaD", "demandaAnual"]
    let showDemand = false;
    const demandaContratada = this.props.selected[0]?.demanda_contratada || this.props.selected?.demanda_contratada
    let multiplyInjected = false;

    this.state.options.forEach((opt) => {
      if (opt.selected) {
        showDemand = includesContractedDemand.includes(opt.id);
        if (opt.id === "tensao_tensao" || opt.id === "tensao_neutro") {
          series = {
            0: { type: "area", color: COLOR_CRITICO, visibleInLegend: true, areaOpacity: 1, },
            1: { type: "area", color: COLOR_ADEQUADO, visibleInLegend: true, areaOpacity: 1 },
            2: { type: "area", color: COLOR_PRECARIO, visibleInLegend: true, areaOpacity: 1 },
            3: { type: "area", color: COLOR_CRITICO, visibleInLegend: true, areaOpacity: 1 },
          };
          extraLabel = [
            "Crítico",
            "Adequado",
            "Precário",
            "Crítico",
          ];

          // const extraD =
          // opt.id === "tensao_tensao"
          //   ? calculateTensaoIntervals(
          //     this.props.selected[0].transf_potencia1 * Math.sqrt(3)
          //     )
          //   : calculateTensaoIntervals(this.props.selected.transf_potencia1);

          extraData = [
            440,
            399,
            353.4,
            342,
          ];

          type = "line";
        } else if (opt.id === "corrente") {
          series = {
            0: {
              color: COLOR_CONTRACTED_DEMAND_TOLERANCE,
              type: "line",
              visibleInLegend: true,
            },
          };
          extraLabel = ["Somatório de Carga (A)"];
          extraData = [this.props.selected[0]?.soma_cargas ?? this.props.selected.soma_cargas];
        } else if (opt.id === "potencia_ativa") {
          series = {
            0: {
              lineWidth: 5,
              color: COLOR_DEFAULT,
              visibleInLegend: true,
            },
          };
          extraLabel = ["Somatório de KWA"];
          extraData = [this.props.selected.soma_kwa];
        } else if (opt.id === "frequencia") {
          series = {
            0: {
              lineWidth: 5,
              color: COLOR_DEFAULT,
              visibleInLegend: true,
            },
            1: {
              lineWidth: 5,
              color: COLOR_DEFAULT2,
              visibleInLegend: true,
            },
          };
          extraLabel = ["Faixa 1 (58Hz)", "Faixa 2 (62Hz)"];
          extraData = [58, 62];
          type = "line";

        } else if (opt.id === "demandaAnual") {
          labels = labels.concat(ultrapassagemLabels);
          labels = labels.concat(["Demanda contratada"]);
          labels = labels.concat(["Tolerância demanda contratada"]);

          pontoSeries = [
            {
              color: COLOR_DEFAULT,
            },
            {
              color: colors.ORANGE_POWER,
            },
            {
              color: COLOR_CONTRACTED_DEMAND,
              type: "line",
              visibleInLegend: false,
            },
            {
              color: COLOR_CONTRACTED_DEMAND_TOLERANCE,
              type: "line",
            },
            { color: 'black', type: 'line', visibleInLegend: false }
          ]
        } else if (opt.id === "potencia" || opt.id === "fator_potencia" || opt.id === "potenciaD" ||
          opt.id === "consumoD" || opt.id === "consumoKwh") {

          labels = labels.concat(pontoLabels);
          if (opt.id === "fator_potencia") labels = ["Data da Coleta", "Ponta", "FP", "Fora de Ponta", "FP", "Reservado", "FP"];
          pontoSeries = [
            {
              color: COLOR_PONTA,
            },
            {
              color: COLOR_F_PONTA,
            },
            {
              color: COLOR_RESERVADO,
            },
          ];

          if (showDemand && this.props.selected && demandaContratada > 0) {
            labels = labels.concat(["Demanda contratada"]);
            labels = labels.concat(["Tolerância demanda contratada"]);

            pontoSeries = pontoSeries.concat({
              color: COLOR_CONTRACTED_DEMAND,
              type: "line",
              visibleInLegend: false,
            })
            pontoSeries = pontoSeries.concat({
              color: COLOR_CONTRACTED_DEMAND_TOLERANCE,
              type: "line",
            })
          }

          pontoSeries = pontoSeries.concat({
            color: COLOR_EXTRA,
          })

          if (opt.id === "potencia" && this.props.hasInjected) {
            pontoSeries = pontoSeries.concat({
              color: COLOR_INJECTED_POWER,
            })
          }
          if (opt.id === "consumoKwh" && this.props.hasInjected) {
            multiplyInjected = 1;
            pontoSeries = pontoSeries.concat({
              color: COLOR_INJECTED_POWER,
            })
          }
        }

        if (extraLabel.length > 0) {
          labels = labels.concat(extraLabel);
        }

        yLabel = opt.yLabel;
      }

      opt.headRows.forEach((hr) => {
        if (hr.showChart) {
          if (!hr.transformPFR) {
            labels.push(hr.label);
            ids.push(hr.id);
          } else {
            ids.push(`PFR:${hr.id}`);
          }
        }
      });
    });

    if (pontoSeries) {
      pontoSeries.map((ps) => (series[Object.keys(series).length] = ps));
    }

    this.props.data.forEach((dt) => {
      let lData = [];

      ids.forEach((id) => {
        if (id === "data_coleta") {
          lData.push(formatDateTime(dt[id], this.props.ignoreHours, this.props.breakHourLine, this.props.monthly));
          extraData.forEach((ed) => {
            lData.push(ed);
          });

        } else if (id.includes('PFR')) {
          let elementName = id.split(":")[1];
          const pValue = dt.posto_horario;
          let p = null, f = null, r = null;

          let value = dt[elementName];

          if (lVAxis.viewWindow.min > value)
            lVAxis.viewWindow.min = value * (value > 0 ? 0.8 : 1.2);
          if (lVAxis.viewWindow.max < value)
            lVAxis.viewWindow.max = value * 1.2;

          let valorFP;
          if (elementName == "fator_potencia_total") {
            lVAxis.viewWindow.min = -0.1;
            lVAxis.viewWindow.max = 0.1;

            lVAxis.ticks = [
              // { v: -1, f: '0' },
              // { v: -0.4, f: '-0.6' },
              { v: -0.08, f: '-0.92' },
              { v: 0, f: '1' },
              { v: 0.08, f: '0.92' },
              // { v: 0.4, f: '0.6' },
              // { v: 1, f: '0' }
            ]
            if (value != null) {
              valorFP = value;
              value = value >= 0 ? 1 - value : -1 - value;
            }
          }

          if (pValue === "F") {
            f = value;
          }
          else if (pValue === "R") {
            r = value;
          } else if (pValue === "P") {
            p = value;
          }

          if (elementName == "fator_potencia_total") {
            lData.push(p);
            lData.push(`Data: ${lData[0]}\nFator potência: ${valorFP}`);
            lData.push(f);
            lData.push(`Data: ${lData[0]}\nFator potência: ${valorFP}`);
            lData.push(r);
            lData.push(`Data: ${lData[0]}\nFator potência: ${valorFP}`);
          } else {
            lData.push(p);
            lData.push(f);
            lData.push(r);
          }

          if (showDemand && this.props.selected && demandaContratada > 0) {
            lData.push(demandaContratada);
            lData.push(demandaContratada * 1.05);
          }
        } else if (id == "energia_injetada_kwh") {
          lData.push(-dt[id])
        } else if (id == "potencia_injetada_ativa") {
          lData.push(-dt[id])
        } else {
          const dtValue = dt[id];

          if (lVAxis.viewWindow.min > dtValue)
            lVAxis.viewWindow.min = dtValue * (dtValue > 0 ? 0.8 : 1.2);
          if (lVAxis.viewWindow.max < dtValue)
            lVAxis.viewWindow.max = dtValue * 1.2;

          if (id == "max4") {
            if (dtValue > demandaContratada) {
              lData.push(dtValue);
              lData.push(null);
            } else {
              lData.push(null);
              lData.push(dtValue);
            }
            lData.push(demandaContratada);
            lData.push(demandaContratada * 1.05);
            lData.push(null);
          } else {
            lData.push(dtValue);
          }
        }
      });

      data.push(lData);
    });

    if (this.props.isGroupped === true) {
      data = this.joinData(data);
    }

    labels = labels.map((lb) => {
      if (lb === "Data da Coleta") {
        return lb;
      } else if (lb == "FP") {
        return { role: 'tooltip', type: 'string', label: "FP" }
      } else {
        return { type: "number", label: lb };
      }
    });

    if (yLabel == "Volts") {
      lVAxis.viewWindow.min = 300;
      lVAxis.viewWindow.max = 425;

    }

    this.setState({
      currentData: data,
      currentLabels: labels,
      series: series,
      extraData: extraData,
      extraLabel: extraLabel,
      vAxis: lVAxis,
      yLabel: yLabel,
      typeChart: type,
    });
  };

  joinData(dados) {
    const resultado = {};

    for (const linha of dados) {
      const data = linha[0];
      const valores = linha.slice(1);

      if (!(data in resultado)) {
        resultado[data] = valores;
      } else {
        for (let i = 0; i < valores.length; i++) {
          if (valores[i] !== null) {
            resultado[data][i] = valores[i];
          }
        }
      }
    }

    return Object.entries(resultado).map(([data, valores]) => [data, ...valores]);
  }

  handleOpenFilter(e) {
    this.setState({ openModal: e.currentTarget });
  }

  handleCloseFilter = () => {
    this.setState({ openModal: null });
  };

  handleSelectData(e) {
    this.updateSelecteds();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  render() {
    return (
      <Card
        style={{
          border: `1px solid ${colors.LIGHT_GREY}`,
          boxShadow: "none"
        }}
      >
        <Grid container>
          {this.props.filter ? (
            <Grid
              container
              item
              xs={12}
              style={{ backgroundColor: colors.WHITE, padding: 10 }}
            >
              <Grid item xs={10} style={{ alignItems: "flex-start" }}>
                <Typography
                  style={{ color: colors.PRIMARY, marginTop: 20, fontSize: 25 }}
                >
                  {" "}
                  {this.state.yLabel}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <Button
                  size="medium"
                  color="default"
                  startIcon={<FilterListRounded />}
                  onClick={(e) => this.handleOpenFilter(e)}
                >
                  Filtro
                </Button>
              </Grid>
              <Menu
                id="simple-menu"
                anchorEl={this.state.openModal}
                keepMounted
                open={Boolean(this.state.openModal)}
                onClose={() => {
                  this.setState({ openModal: false });
                }}
                MenuListProps={{ onMouseLeave: this.handleCloseFilter }}
              >
                <FilterGestor
                  data={this.state.options}
                  handleSelectData={this.handleSelectData}
                />
              </Menu>
            </Grid>
          ) : (
            <></>
          )}
          {this.props.canBeStackedAndNormal ? (
            <Grid
              container
              item
              xs={12}
              style={{ backgroundColor: colors.WHITE, padding: 10 }}
            >
              <Grid item xs={10} style={{ alignItems: "flex-start" }}>
                <Typography
                  style={{ color: colors.PRIMARY, marginTop: 20, fontSize: 25 }}
                >
                  {" "}
                  {this.state.yLabel}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
                <FormControl fullWidth >
                  <NativeSelect
                    fullWidth
                    id="Selecione o agrupamento"
                    label="Selecione o agrupamento"
                    value={this.state.isStacked}
                    name="isStacked"
                    onChange={this.handleChange}
                  >
                    <option value={true}>Agrupado</option>
                    <option value={false}>Não agrupado</option>
                  </NativeSelect>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            {this.state.currentLabels.length > 1 &&
              this.props.data.length > 0 ? (
              <Chart
                height={this.props.chartSize}
                chartType={"ComboChart"}
                loader={<div>Carregando Dados</div>}
                data={[this.state.currentLabels, ...this.state.currentData]}
                chartEvents={[
                  {
                    eventName: "ready",
                    callback: ({ chartWrapper, google }) => {
                      const chart = chartWrapper.getChart();
                      if (this.props.onChartReady) {
                        this.props.onChartReady(chart);
                      }
                    },
                  },
                ]}
                options={{
                  interpolateNulls: false,
                  height: this.props.chartSize,
                  isStacked: this.state.isStacked,
                  selectionMode: "multiple",
                  seriesType: this.state.typeChart,
                  legend: {
                    textStyle: {
                      fontSize: 16,
                      color: colors.PRIMARY,
                    },
                    position: 'top'
                  },
                  chartArea: {
                    left: 120,
                    top: 40,
                  },
                  backgroundColor: colors.WHITE,
                  hAxis: {
                    title: "Tempo",
                    slantedText: this.props?.slantedText ?? true,
                    slantedTextAngle: 15,
                    textStyle: {
                      fontSize: 10,
                      color: colors.PRIMARY,
                    },
                  },
                  series: this.state.series,
                  vAxis: {
                    minValue: this.state.vAxis.viewWindow.min,
                    maxValue: this.state.vAxis.viewWindow.max,
                    title: this.state.yLabel,
                    ticks: this.state.vAxis.ticks,

                    textStyle: {
                      fontSize: 14,
                      color: colors.PRIMARY,
                    },
                  },
                  bar: { groupWidth: '90%' },
                  chart: {
                    title: ``,
                    subtitle: "",
                    color: colors.PRIMARY,
                  },
                  width: '105%',
                  tooltip: { // Ajusta a posição do tooltip
                    position: 'bottom',
                    isHtml: this.state.yLabel == "Fator Potência" ? true : false,
                  },
                }}
              />
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                style={{ paddingBottom: 20 }}
              >
                <Typography style={{ color: colors.GREY }}>
                  Selecione os dados para serem mostrados
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default LineChart;
