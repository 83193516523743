import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ActionTooltip, IconHead } from './actions';
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { colors } from "../../constants/dc_constants";

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  spacer: {
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.primary,
  },
  title: {
    flex: '0 0 auto',
  },
}));

function ObjectTableToolbar(props) {
  const classes = useToolbarStyles();
  const { selected, numSelected, selectedToolActions, toolActions, tableName, iconHead } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          numSelected > 1 ? (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} Selecionados
            </Typography>) : (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} Selecionado
            </Typography>
          )
        ) : (
          <>
            <div style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}>
              { iconHead ?
                iconHead.map((selActs, index) => {
                  return (
                    <IconHead
                      key={index}
                      MaterialIcon={selActs.MaterialIcon}
                      color={selActs.color}
                    />
                  )
                }) : ""
              }
              <Typography variant="h6" color={"primary"}>  {tableName} </Typography>
            </div>
          </>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {
          numSelected > 0 ? (
            <Toolbar >{
              selectedToolActions.map(selActs => {
                return (
                  <ActionTooltip
                    title={selActs.title}
                    label={selActs.label}
                    handleClick={(e) => selActs.handleClick(e, selected)}
                    MaterialIcon={selActs.MaterialIcon}
                    color={colors.ORANGE_POWER}
                  />
                )
              })
            }
            </Toolbar>
          ) : (
            <Toolbar
            >{
                toolActions.map(acts => {
                  return (
                    <ActionTooltip
                      key={acts.title}
                      title={acts.title}
                      label={acts.label}
                      handleClick={acts.handleClick}
                      MaterialIcon={acts.MaterialIcon}
                      color={acts.color}
                      selected={selected}
                    />
                  )
                }
                )
              }
            </Toolbar>
          )
        }
      </div>
    </Toolbar>
  );
};

ObjectTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default ObjectTableToolbar