import React from "react";
import { Box, Container, Grid, Typography, Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  periodo: {
    width: "100%",
    border: "5px solid black",
    borderRadius: "10px",
    transitionDuration: "0.3s",
    height: "5vw",
    align: "center",
  },
  description: {
    border: "5px solid black",
    borderRadius: "10px",
    transitionDuration: "0.3s",
    height: "5vw",
    paddingLeft: "2vh",
    paddingTop: "0.8vh",
  },
  total: {
    border: "5px solid black",
    borderRadius: "10px",
    transitionDuration: "0.3s",
    height: "5vw",
    backgroundColor: "black",
    marginTop: 31,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  headTable: {
    backgroundColor: "black",
    borderCollapse: "collapse",
    padding: "none",
    margin: "none",
  },

  table: {
    colspan: "0",
    marginTop: 31,
    borderRadius: "10px",
    overflow: "hidden",
    fontSize: "14px",
    width: "100%",
  },

  rowTable: {
    backgroundColor: "write",
    borderCollapse: "separate",
    fontSize: "14px",
  },
});

class Esqueleton extends React.Component {
  constructor(props) {
    super(props);
  }

  //     this.state = {

  //         fechamentoData: {
  //             kw_total: 0,
  //             kvar_total: 0,
  //             kw_ponta: 0,
  //             kw_fora_ponta: 0,
  //             kw_reservado: 0,
  //             kvar_ponta: 0,
  //             kvar_fora_ponta: 0,
  //             kvar_reservado: 0,
  //             kw_hp_max: 0,
  //             kw_hfp_max: 0,
  //             kw_hr_max: 0,
  //         },

  //         amountValues: {
  //             tarifa_hp_total: 0,
  //             tarifa_hfp_total: 0,
  //             tarifa_hr_total: 0,
  //             tarifa_total_kw: 0,
  //             tarifa_hfp_kvar: 0,
  //             tarifa_hp_kvar: 0,
  //             tarifa_hr_kvar: 0,
  //             tarifa_total_kvar: 0,
  //             total: 0,

  //         }
  //     }
  // }

  // showNumberValue = (value) => {
  //     if (value === null || value === undefined) return " -- ";
  //     else return parseFloat(value.toFixed(2));
  // };

  // calculateValues = () => {

  //     console.log(this.props)

  //     if (this.props.gestor.selected && this.props.data) {
  //         let tarifa_hfp = 0
  //         let tarifa_hp = 0
  //         let tarifa_hr = 0

  //         let tarifa_hfp_kvar = 0
  //         let tarifa_hp_kvar = 0
  //         let tarifa_hr_kvar = 0
  //         let tarifa_total_kw = 0
  //         let tarifa_total_kvar = 0

  //         tarifa_hp = this.props.data.kw_ponta * this.props.gestor.selected.tarifa_hp;
  //         tarifa_hfp = this.props.data.kw_fora_ponta * this.props.gestor.selected.tarifa_hfp;
  //         tarifa_hr = this.props.data.kw_reservado * this.props.gestor.selected.tarifa_hr;
  //         tarifa_total_kw = tarifa_hp + tarifa_hfp + tarifa_hr;

  //         tarifa_hp_kvar = this.props.data.kvar_ponta * this.props.gestor.selected.tarifa_hp;
  //         tarifa_hfp_kvar = this.props.data.kvar_fora_ponta * this.props.gestor.selected.tarifa_hfp;
  //         tarifa_hr_kvar = this.props.data.kvar_reservado * this.props.gestor.selected.tarifa_hr;
  //         tarifa_total_kvar = tarifa_hfp_kvar + tarifa_hp_kvar + tarifa_hr_kvar;

  //         this.setState({
  //             amountValues: {
  //                 tarifa_hp_total: tarifa_hp,
  //                 tarifa_hfp_total: tarifa_hfp,
  //                 tarifa_hr_total: tarifa_hr,
  //                 tarifa_total_kw: tarifa_total_kw,

  //                 tarifa_hfp_kvar: tarifa_hfp_kvar,
  //                 tarifa_hp_kvar: tarifa_hp_kvar,
  //                 tarifa_hr_kvar: tarifa_hr_kvar,
  //                 tarifa_total_kvar: tarifa_total_kvar,
  //                 total: tarifa_total_kvar + tarifa_total_kw,

  //             }
  //         })

  //     }
  // }

  // componentWillMount() {
  //     this.calculateValues();

  // }

  render() {
    const { classes } = this.props;
    return (
      <></>
      // <Container>
      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid align="left" item xs={9}>
      //             <img
      //                 alt="logo"
      //                 style={{ marginLeft: 1, marginTop: 13 }}
      //                 width={"30%"}
      //                 src="/static/images/logo.png"
      //             ></img>
      //         </Grid>
      //         <Grid spacing={3} item xs={3}>
      //             <Card className={classes.periodo}>
      //                 <Typography
      //                     style={{ fontWeight: "bold", marginTop: "2vh" }}
      //                     align="center"
      //                 > PERÍODO DO RELATÓRIO</Typography>

      //                 {"10/04/2021"}  a  {"10/04/2021"}
      //             </Card>
      //         </Grid>
      //     </Grid>

      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid align="left" item xs={8}>
      //             <Typography
      //                 style={{ fontWeight: "bold" }}
      //                 align="left"
      //                 variant="h6"
      //             > Simulação de Fatura </Typography>
      //             <Card className={classes.description}>
      //                 <Typography
      //                     style={{}}
      //                     align="left"
      //                     variant="h6"
      //                 >  {"Equipamento : PC011"}
      //                 </Typography>
      //                 <Typography
      //                     style={{}}
      //                     align="left"
      //                     variant="h6"
      //                 >
      //                     {" Localidade do equipamento : Pombo"} </Typography>
      //             </Card>
      //         </Grid>
      //         <Grid item xs={4}>
      //             <Card className={classes.total}>
      //                 <Typography
      //                     style={{ fontWeight: "bold", marginTop: "1vh", color: "#ffffff" }}
      //                     align="center"
      //                     variant="h6"
      //                 >   TOTAL ENERGIA {`R$ 5.942,00`}</Typography>

      //                 <Typography style={{ color: "#ffffff" }} > Com impostos </Typography>
      //             </Card>
      //         </Grid>
      //     </Grid>

      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid item xs={6} spacing={1}>
      //             <Table cellspacing="0" cellpadding="0" className={classes.table}>
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>
      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   Demanda Ativa Registrada</Typography>
      //                         </Th>
      //                         <Th></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >DATA:{"10/05/2021 10:14"}  </Typography></Th>
      //                     </Tr>
      //                 </Thead>
      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >KW: {`${this.showNumberValue(this.props.data.kw_total)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Unit.: R${`${this.showNumberValue(this.props.gestor.selected.tarifa_hfp)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Total: R${`${this.showNumberValue(this.state.amountValues.tarifa_total_kw)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                 </Tbody>
      //             </Table>
      //         </Grid>

      //         <Grid item xs={6} spacing={1} >
      //             <Table cellspacing="0" cellpadding="0" className={classes.table} >
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>

      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   Demanda Reativa Excedente </Typography>
      //                         </Th>
      //                         <Th></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >DATA:{"10/05/2021 10:14"}  </Typography></Th>

      //                     </Tr>
      //                 </Thead>

      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >KWh:  {`${this.showNumberValue(this.props.data.kvar_total)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Unit.: R$ {`${this.showNumberValue(this.props.gestor.selected.tarifa_hfp)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Total: R$ {`${this.showNumberValue(this.state.amountValues.tarifa_total_kvar)}`} </Typography>
      //                         </Th>
      //                     </Tr>

      //                 </Tbody>
      //             </Table>
      //         </Grid>
      //     </Grid>

      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid item xs={12} spacing={1}>
      //             <Table cellspacing="0" cellpadding="0" className={classes.table}>
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>
      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   Consumo Ativo</Typography>
      //                         </Th>
      //                         <Th> <Typography
      //                             style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                             align="left"
      //                         >KWH </Typography></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >VALOR UNITÁRIO </Typography></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >TOTAL </Typography></Th>
      //                     </Tr>
      //                 </Thead>
      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado na ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kw_ponta)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R${`${this.showNumberValue(this.props.gestor.selected.tarifa_hp)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R${`${this.showNumberValue(this.props.data.kw_ponta * this.props.gestor.selected.tarifa_hp)}`}</Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado fora de ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kw_fora_ponta)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R${`${this.showNumberValue(this.props.gestor.selected.tarifa_hfp)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R${`${this.showNumberValue(this.props.data.kw_fora_ponta * this.props.gestor.selected.tarifa_hfp)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado reservado </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kw_reservado)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R$ {`${this.showNumberValue(this.props.gestor.selected.tarifa_hr)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R$ {`${this.showNumberValue(this.props.data.kw_reservado * this.props.gestor.selected.tarifa_hr)}`} </Typography>
      //                         </Th>
      //                     </Tr>

      //                 </Tbody>
      //             </Table>
      //         </Grid>
      //     </Grid>

      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid item xs={12} spacing={1}>
      //             <Table cellspacing="0" cellpadding="0" className={classes.table}>
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>
      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   Consumo Reativo</Typography>
      //                         </Th>
      //                         <Th> <Typography
      //                             style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                             align="left"
      //                         >KWH </Typography></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >VALOR UNITÁRIO </Typography></Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >TOTAL </Typography></Th>
      //                     </Tr>
      //                 </Thead>
      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado na ponta </Typography>
      //                         </Th>

      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kvar_ponta)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >  R$ {`${this.showNumberValue(this.props.gestor.selected.tarifa_hp)}`}</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >   R$ {`${this.showNumberValue(this.state.amountValues.tarifa_hp_kvar)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado fora de ponta </Typography>
      //                         </Th>

      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kvar_fora_ponta)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > R$ {`${this.showNumberValue(this.props.gestor.selected.tarifa_hfp)}`} </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >  R$ {`${this.showNumberValue(this.state.amountValues.tarifa_hfp_kvar)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Consumo registrado reservado </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kvar_reservado)}`}</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >  R$ {`${this.showNumberValue(this.props.gestor.selected.tarifa_hr)}`}  </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >  R$ {`${this.showNumberValue(this.state.amountValues.tarifa_hr_kvar)}`}</Typography>
      //                         </Th>
      //                     </Tr>

      //                 </Tbody>
      //             </Table>
      //         </Grid>
      //     </Grid>

      //     <Grid container style={{ marginTop: 20 }} spacing={3}>
      //         <Grid item xs={6} spacing={1}>
      //             <Table cellspacing="0" cellpadding="0" className={classes.table}>
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>
      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   DETALHAMENTO DEMANDA ATIVA</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >KWH </Typography></Th>
      //                     </Tr>
      //                 </Thead>
      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada na ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kw_hp_max)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada fora de ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kw_hfp_max)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada reservado</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kw_hr_max)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                 </Tbody>
      //             </Table>
      //         </Grid>

      //         <Grid item xs={6} spacing={1}>
      //             <Table cellspacing="0" cellpadding="0" className={classes.table}>
      //                 <Thead className={classes.headTable} >
      //                     <Tr >
      //                         <Th>
      //                             <Typography
      //                                 style={{ fontWeight: "bold", color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >   DETALHAMENTO DEMANDA REATIVA</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
      //                                 align="left"
      //                             >KWH </Typography></Th>
      //                     </Tr>
      //                 </Thead>
      //                 <Tbody className={classes.rowTable}>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada na ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kvar_ponta)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada fora de ponta </Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             > {`${this.showNumberValue(this.props.data.kvar_fora_ponta)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                     <Tr>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >Demanda registrada reservado</Typography>
      //                         </Th>
      //                         <Th>
      //                             <Typography
      //                                 style={{ marginBottom: 0, padding: 8 }}
      //                                 align="left"
      //                             >{`${this.showNumberValue(this.props.data.kvar_reservado)}`} </Typography>
      //                         </Th>
      //                     </Tr>
      //                 </Tbody>
      //             </Table>
      //         </Grid>
      //     </Grid>
      // </Container>
    );
  }
}
export default withStyles(styles)(Esqueleton);
