import { toast } from 'react-toastify'
import browserHistory from '../helpers/browser_history'
import { getMonthName } from '../util/utils';


export function validRequiredFields(data, fields) {
	let value = "";
	let text = "Verifique os campos que precisam ser preenchidos";

	for (let index = 0; index < fields.length; index++) {
		const element = fields[index];
		let fullText = text;

		value = data[element.value];

		if (element.name) {
			fullText += ` (${element.name})`
		}

		if (value == "" || value == undefined || value.length <= 0) {
			toast.warn(`${fullText}`);

			return false;
		}
	}

	return true;
}

export function validRequiredFieldsTable(table, fields){
	let value = "";
	let text = "Verifique os campos que precisam ser preenchidos";

	for(let line = 0; line < table.length; line++){
		for (let index = 0; index < fields.length; index++) {
			const element = fields[index];
			let fullText = text;
	
			value = table[line][element.value];
	
			if (element.name) {
				fullText += ` (${element.name} de ${getMonthName(table[line].mes)})`
			}
	
			if (value == "" || value == undefined || value.length <= 0) {
				toast.warn(`${fullText}`);
	
				return false;
			}
		}
	};
	return true;
}

function makeErrorsMessage(response){
	let errors = response.data.errors || []
	if(errors.length > 0){
		errors.forEach(error => {
			toast.warn(error)
		})
	}else{
		toast.warn("Falha ao realizar operação, verifique os dados e tente novamente")
	}
}

export function validateShowResponseToast(response, model=undefined, isShowSuccess=true, isShowFail=true,  successMsg=undefined){
	if(response){
		if(response.status === 200){
			if(isShowSuccess) toast.success(successMsg || "Operação realizada com sucesso!")
			return true
		}else if(response.status === 201){
			if(isShowSuccess) toast.success(`${model || ''} criado com sucesso!`)
			return true
		}else if(response.status === 400){
			if(isShowFail) makeErrorsMessage(response)
			return false
		}else if(response.status === 401){
			if(isShowFail) toast.warn("Você não tem permissão para realizar essa tarefa ou acessar esses dados!")
			browserHistory.push('/')
			return false
		}else if(response.status === 403){
			if(isShowFail) toast.warn("Você não tem autorização para realizar esse procedimento!")
			return false
		}else{
			if(isShowFail) toast.error("Erro interno! Se persistir contate o suporte técnico")
			return false
		}
	}else{
		if(isShowFail) toast.error("Falha ao acessar o Servidor! Verifique a conexão com a internet!")
		return false;
	}
}
