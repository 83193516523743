import { authHeader } from '../helpers/auth_header';
import { BASE_URL } from '../constants/urls'
import { getAxiosInstance } from '../services/service'

require('dotenv').config()

export const userService = {
	login,
	loginGoogle,
	logout,
	getAll,
	getUsuarios,
	createUsuario,
	updateUsuario,
	getUsuario,
	registerUsuario,
	recoverSenha,
	sendRecoverSenha,
};

async function sendRecoverSenha(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance(`/usuarios/recuperar/${data.email}/${data.url}`).get().catch(async error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function recoverSenha(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance(`/usuarios/recuperar/${data.token}`).post(undefined, data).catch(async error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function getUsuario(handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance('/usuario').get().catch(error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function getUsuarios(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance('/usuarios').get().catch(error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function updateUsuario(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance(`/usuarios/${data.id}`).put(undefined, data).catch(async error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function registerUsuario(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance(`/usuarios/register/${data.token}`).post(undefined, data).catch(async error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function createUsuario(data, handleResponse, handleAction){
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance('/usuarios').post(undefined, data).catch(async error => {
		errorResponse = error.response || undefined
	})
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

async function login(usuario, senha) {
	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify({ usuario, senha })
	};
	const response = await fetch(`${BASE_URL}/usuarios/login`, requestOptions);
	const user = await handleRes(response);
	if (user) {
		user.authdata = window.btoa(usuario);

		localStorage.setItem('user', JSON.stringify(user));
	}
	return user;
}

async function loginGoogle(data, handleResponse, handleAction) {
	let response = undefined
	let errorResponse = undefined
	response = await getAxiosInstance('/usuarios/login/google').post(undefined, data).catch(async error => {
		errorResponse = error.response || undefined
	})
	if(response && response.data){
		const user = response.data
		user.authdata = window.btoa(user.usuario);
		localStorage.setItem('user', JSON.stringify(user));
	}
	if(handleResponse) handleResponse(response || errorResponse)
	if(handleAction) handleAction()
}

function logout() {
	localStorage.removeItem('user');
}

// async function getAll() {
// 	const requestOptions = {
// 		method: 'GET',
// 		headers:  authHeader()
// 	};

// 	const response = await fetch(`${BASE_URL}/usuarios`, requestOptions);
// 	return handleRes(response);
// }

async function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader()
	}

	if (Object.keys(requestOptions.headers).length !== 0) {
		const response = await fetch(`${BASE_URL}/usuarios`, requestOptions);
		return handleRes(response);
	}
}

function handleRes(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text);
		if (!response.ok) {
			if (response.status === 401) {
				logout();
			}

			const error = (data && data.message) || response.statusText;
			return Promise.reject(error);
		}
		return data;
	});
}
