import jsPDF from "jspdf";
import "jspdf-autotable";

(function (API) {
  API.writeText = function (txt, options, x, y) {
    options = options || {};
    var fontSize = this.internal.getFontSize();
    var pageWidth = this.internal.pageSize.width;
    let txtWidth =
      (this.getStringUnitWidth(txt) * fontSize) / this.internal.scaleFactor;

    if (options.align === "center") {
      x = (pageWidth - txtWidth) / 2;
    } else if (options.align === "left") {
      x = 2;
    } else if (options.align === "right") {
      x = pageWidth - txtWidth - 2;
    }
    this.text(txt, x, y);
    return x + txtWidth;
  };
})(jsPDF.API);

export function makeTable(
  doc,
  cols = [],
  rows = [],
  initVerticalLine,
  fontSize = 8,
  custom = {},
  title
) {
  let tableSize = 0;
  if (title) {
    doc.setFontSize(18);
    doc.setFontType("bold");
    doc.writeText(title, { align: "center" }, 0, 10);
    doc.setFontType("normal");
    doc.setFontSize(16);
  }
  doc.autoTable({
    head: cols,
    body: rows,
    theme: "grid",
    headStyles: {
      fillColor: "#201F29",
    },
    styles: { halign: "center", fontSize: fontSize },
    margin: 2,
    startY: initVerticalLine,
    didDrawPage: (HookData) => {
      tableSize = HookData.table.height;
    },
    willDrawCell: (hook) => {
      if (hook.cell.section === "body") {
        if (hook.cell.raw === "NaN") {
          hook.cell.text[0] = "----";
        }
      }
    },
  });
  return tableSize;
}

export default jsPDF;
