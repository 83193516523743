import {
  Button,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import CustomDateTimePicker from "../../default/custom/CustomDateTimePicker";
import {
  closeProgressDialog,
  makeActionFormDialog,
  makeProgressDialog,
} from "../../default/dialogs/DialogRefInterface";
import { validateShowResponseToast } from "../../helpers/toast_alerts";
import { gestorService } from "../../services/gestor_service";
import { ArrowBack, PictureAsPdf, Save } from "@material-ui/icons";
import { template_fechamento, template_completo, htmlToPng } from "../../default/templates_pdf/template_relatorio";
import { colors } from "../../constants/dc_constants";
import FechamentoView from "./FechamentoView";
import { buildResumoFechamento } from "../../util/gestor_utils";
import browserHistory from "../../helpers/browser_history"
import { resumoService } from "../../services/resumo_service";
import FechamentoForm from "./FechamentoForm";

class TelaFechamento extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gestores: [],
      gestor: null,
      dataInicial: moment().subtract(1, "month").format("YYYY-MM-DD 00:00"),
      dataFinal: moment().format("YYYY-MM-DD 00:00"),
      fechamentoData: {
        kw_total: 0,
        kvar_total: 0,
        kw_ponta: 0,
        kw_fora_ponta: 0,
        kw_reservado: 0,
        kvar_ponta: 0,
        kvar_fora_ponta: 0,
        kvar_reservado: 0,
        kw_hp_max: 0,
        kw_hfp_max: 0,
        kw_hr_max: 0,
        kw_injetado_max_fora_ponta: 0,
        kw_injetado_max_ponta: 0,
        kw_injetado_ponta: 0,
        kw_injetado_fora_ponta: 0,
        kw_injetado_total: 0,
      },
      fazenda: "",
      nameGestor: "",
      device: "",
    };
    this.imgToPdf = React.createRef();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
    name == "gestor" ? this.changeGestor(value) : null;
  };

  changeGestor(value){
    const gestorSelecionado = this.state.gestores.find(gestor => gestor.id == value);
    this.setState({
      fazenda: gestorSelecionado.fazenda,
      nameGestor: `${gestorSelecionado.id_equipamento} - ${gestorSelecionado.nome_bombeamento}`,
      device: gestorSelecionado.equipamento
    })
  }

  handleClick = () => {
    this.getFechamentoData();
  };

  handleClickPDF = () => {
    this.exportPDF();
  };

  exportPDF = (
    headRows,
    isExportSelected = false,
    pdfName = `Fechamento_${moment().format("DD_MM_YYYY")}`,
    orientation = "p",
    title = "",
    rowsSum = [],
    rowId = ""
  ) => {
    this.setState(
      {
        isExportSelected: isExportSelected,
      },
      async () => {
        let emptyValues;
        let divRelatorioFechamentoPng = await htmlToPng(
          document.getElementById("pdf")
        );
        let somenteFechamento = true;

        const doc = template_completo({
          emptyValues,
          divRelatorioFechamentoPng,
          emptyValues,
          emptyValues,
          emptyValues,
          emptyValues,
          emptyValues,
          emptyValues,
          somenteFechamento,
          headerParams: {
            orientation: "p",
            title: " ",
            data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
            equipamento: this.state.nameGestor || "",
            local: this.state.fazenda  || "",
            regiao: "",
            data_inicio: moment(this.state.dataInicial).format(
              "DD/MM/YYYY - HH:mm:ss"
            ),
            data_final: moment(this.state.dataFinal).format("DD/MM/YYYY - HH:mm:ss"),
          },
        });

        doc.save(pdfName);
      }
    );
  };

  handleClickSave = () => {
    makeActionFormDialog(
      FechamentoForm,
      "Salvar Fechamento",
      false,
      null,
      "Salvar",
      (formData, onClose) => {
        const data = {
          ...this.state.fechamentoData,
          gestor_id: this.state.gestor,
          data_inicial: moment(this.state.dataInicial).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          data_final: moment(this.state.dataFinal).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          nome: formData.nome,
        };
        resumoService.createResumo(data, (response) => {
          if (validateShowResponseToast(response, "", true, true)) {
            onClose();
          }
        });
      }
    ).current.open();
  };

  getFechamentoData = () => {
    const queryData = {
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      gestorId: this.state.gestor,
    };
    makeProgressDialog(null, "Gerando Relatório...").current.open();

    gestorService.getResumoDados(queryData, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        const data = response.data;
        const resumoData = buildResumoFechamento(data);
        this.setState({
          fechamentoData: resumoData,
        });
      }
      closeProgressDialog();
    });
  };

  getGestores = () => {
    gestorService.getGestores("power",{}, (response) => {
      if (validateShowResponseToast(response, "", false, false)) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  componentDidMount() {
    this.getGestores();
  }

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6" align="left">
            Fechamento com Dados Históricos do Medidor
          </Typography>
        </Grid>
        <Grid item md={3} xs={12}>
          <InputLabel align="left" htmlFor="gestor">
            Selecione o medidor:{" "}
          </InputLabel>
          <Select
            fullWidth
            align="left"
            value={this.state.gestor || ""}
            name="gestor"
            onChange={this.handleChange}
          >
            {this.state.gestores.map((ges) => (
              <MenuItem
                key={ges.id}
                value={ges.id}
              >{`${ges.id_equipamento} - ${ges.fazenda}`}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={3} xs={12}>
          <InputLabel align="left" htmlFor="dataInicial">
            Data Inicial:{" "}
          </InputLabel>
          <CustomDateTimePicker
            fullWidth
            iconcolor={colors.BLUE}
            variant="inline"
            format="DD/MM/YYYY HH:mm"
            name="dataInicial"
            value={this.state.dataInicial}
            onChange={this.handleChange}
            KeyboardButtonProps={{
              "aria-label": "Mudar data",
            }}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <InputLabel align="left" htmlFor="dataFinal">
            Data Final:
          </InputLabel>
          <CustomDateTimePicker
            fullWidth
            iconcolor={colors.BLUE}
            variant="inline"
            format="DD/MM/YYYY HH:mm"
            name="dataFinal"
            value={this.state.dataFinal}
            onChange={this.handleChange}
            KeyboardButtonProps={{
              "aria-label": "Mudar data",
            }}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: colors.ORANGE_POWER,
              color: "white",
              marginTop: 10,
              boxShadow: "none"
            }}
            onClick={this.handleClick}
          >
            Gerar Fechamento
          </Button>
        </Grid>

        <Grid item id={"pdf"} ref={this.imgToPdf}>

          {this.state.fechamentoData.kw_total ? (
            <Grid item xs={12} align="left">
                <Button
                  variant="contained"
                  style={{
                    height: 40,
                    backgroundColor: colors.ORANGE_POWER,
                    color: "white",
                    marginTop: 14,
                    marginRight: 14,
                    boxShadow: "none"
                  }}
                  onClick={this.handleClickSave}
                >
                  <Save /> Salvar
                </Button>

                <Button
                  variant="contained"
                  style={{
                    height: 40,
                    backgroundColor: colors.DARK_GREY,
                    color: "white",
                    marginTop: 14,
                    boxShadow: "none"
                  }}
                  onClick={this.handleClickPDF}
                >
                  Exportar <PictureAsPdf style={{marginLeft: 7.5}}/>
                </Button>
            </Grid>
            ) : ""}

          <Grid item md={12} style={{borderBottom: `1px solid ${colors.LIGHT_GREY}`}}>
            <FechamentoView
              data={{
                ...this.state.fechamentoData,
              }}
              dataInicial={this.state.dataInicial}
              dataFinal={this.state.dataFinal}
              gestor={this.state.gestores.find(gestor => gestor.id == this.state.gestor)}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={2} md={2}>
            <Button
              fullWidth
              variant="contained"
              style={{
                backgroundColor: colors.GREY,
                color: "white",
                marginTop: 10,
                boxShadow: "none"
              }}
              onClick={() => browserHistory.goBack()}
            >
              <ArrowBack /> Voltar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default TelaFechamento;
