import React from "react"
import {
  Grid,
  Checkbox,
  ListItemText,
  ListItem,
  List,
  Collapse,
} from "@material-ui/core"
import { ExpandLess, ExpandMore } from "@material-ui/icons"
import { withStyles } from "@material-ui/core/styles"
import { colors } from "../../constants/dc_constants"

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class FilterGestor extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  action() {
    if (this.props.handleFormState) this.props.handleFormState(this.state);
  }

  handleAction(e) {
    if (e.key === "Enter") {
      this.action();
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        item
        xs={12}
        style={{
          padding: 0,
          margin: 0,
          width: 350,
          color: "#ffffff",
          backgroundColor: colors.PRIMARY,
        }}
      >
        <form className={{ display: "flex", flexWrap: "wrap" }} noValidate>
          <List style={{ maxHeight: 400, overflow: "auto" }}>
            {this.props.data.map((opt) => {
              return opt.unique && (
                <div key={opt.id}>
                  <ListItem
                    button
                    onClick={(e) => {
                      opt.open = !opt.open;
                      this.forceUpdate();
                    }}
                  >
                    <Checkbox
                      //color={colors.GREEN}
                      value={opt.label}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        opt.selected = !opt.selected;
                        opt.headRows = opt.headRows.map((hr) => {
                          hr.showChart = opt.selected;
                          return hr;
                        });
                        this.props.handleSelectData();
                      }}
                      checked={opt.selected}
                      edge="start"
                    />
                    <ListItemText primary={opt.label} />
                    {opt.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  {opt.headRows.map((hr) => {
                    return (
                      <Collapse key={hr.id} in={opt.open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            className={classes.nested}
                            onClick={() => {
                              hr.showChart = !hr.showChart;
                              this.props.handleSelectData();
                              this.forceUpdate();
                            }}
                          >
                            <Checkbox
                              value={hr.label}
                              checked={hr.showChart}
                              edge="start"
                            />
                            <ListItemText primary={hr.label} />
                          </ListItem>
                        </List>
                      </Collapse>
                    );
                  })}
                </div>
              );
            })}
          </List>
        </form>
      </Grid>
    );
  }
}

export default withStyles(styles)(FilterGestor);
