import React from "react";
import { Card, Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

const styles = () => ({
  mainContainer: {
    marginTop: 10,
  },
  logo: {
    textAlign: "left",
  },
  periodHeader: {
    border: "4px solid black",
    padding: 10,
    borderRadius: 8,
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 600,
    minHeight: 70,
    minWidth: 250,
    maxHeight: 70,
    maxWidth: 250,
    float: "right",
  },
  periodData: {
    fontSize: 14,
    lineHeight: 2,
    margin: 0,
    padding: 0,
    fontWeight: "normal",
  },
  periodo: {
    border: "5px solid black",
    borderRadius: 10,
    padding: 4,
  },
  managerHeader: {
    border: "4px solid black",
    padding: 10,
    borderRadius: 8,
    textAlign: "left",
    fontSize: 18,
    fontWeight: 600,
  },

  title: {
    color: "#F0F2E4",
    backgroundColor: "#201E29",
    borderRadius: "8px 8px 0 0",
    padding: 10,
    textAlign: "left",
  },
  label: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: 600,
    margin: 5,
  },
  values: {
    fontWeight: "normal",
    float: "right",
  },
});

class FechamentoView extends React.Component {
  constructor(props) {
    /**
     *  data
     *  dataInicial
     *  dataFinal
     *  gestor
     */
    super(props);
  }

  dateFormat = (date) => {
    if (date && date.indexOf("/") !== -1) {
      return date.split(" ")[0];
    }
    if (date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    }
  };

  showNumberValue = (value) => {
    if (value === null || value === undefined) return " -- ";
    else return value;
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={4} className={classes.mainContainer}>
        <Grid item xs={6} md={6} className={classes.logo}>
          <img
            alt="logo"
            style={{ marginLeft: 1, marginTop: 13 }}
            width={"60%"}
            src="/static/images/logo.png"
          ></img>
        </Grid>

        <Grid item xs={6}>
          <Card className={classes.periodo}>
          <Typography variante="button" style={{ fontWeight: 700 }}>
            Período do relatório
          </Typography>
            <Typography>
              {this.dateFormat(this.props.dataInicial)}
              {" à "}
              {this.dateFormat(this.props.dataFinal)}
          </Typography>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.managerHeader}>
            Medidor
            <span className={classes.values}>
              {`${this.props.gestor?.fazenda || ""} - ${
                this.props.gestor?.id_equipamento || ""
              }`}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Consumo ativo acumulado no periodo
          </Typography>
          <Typography className={classes.label}>
            Kwh
            <span className={classes.values}>{` ${this.showNumberValue(
              this.props.data.kw_total
            )} kwh`}</span>
          </Typography>
          {this.props.gestor?.demanda_geracao &&
          <Typography className={classes.label}>
            Kwh Injetado
            <span className={classes.values}>{` ${this.showNumberValue(
              this.props.data.kw_injetado_total
              )} kwh`}</span>
          </Typography>
            }
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Consumo reativo acumulado no periodo
          </Typography>
          <Typography className={classes.label}>
            Kvarh
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kvar_total)}`} kwh
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Consumo ativo por posto horário
          </Typography>
          <Typography className={classes.label}>
            Kwh HP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_ponta)}`} kwh
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kwh HFP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_fora_ponta)}`} kwh
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kwh HR
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_reservado)}`} kwh
            </span>
          </Typography>
        </Grid>
        {this.props.gestor?.demanda_geracao &&
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Consumo injetado por posto horário
          </Typography>
          <Typography className={classes.label}>
            Kwh HP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_injetado_ponta)}`} kwh
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kwh HFP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_injetado_fora_ponta)}`} kwh
            </span>
          </Typography>
        </Grid>
        }
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Consumo reativo por posto horário
          </Typography>
          <Typography className={classes.label}>
            Kvarh HP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kvar_ponta)}`} kwh
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kvarh HFP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kvar_fora_ponta)}`} kwh
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kvarh HR
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kvar_reservado)}`} kwh
            </span>
          </Typography>
        </Grid>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Demanda registrada ativa por posto horário
          </Typography>
          <Typography className={classes.label}>
            Kw HP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_hp_max)}`} kw
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kw HFP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_hfp_max)}`} kw
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kw HR
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_hr_max)}`} kw
            </span>
          </Typography>
        </Grid>
        {this.props.gestor?.demanda_geracao &&
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className={classes.title}>
            Demanda injetada por posto horário
          </Typography>
          <Typography className={classes.label}>
            Kw HP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_injetado_max_ponta)}`} kw
            </span>
          </Typography>
          <Typography className={classes.label}>
            Kw HFP
            <span className={classes.values}>
              {`${this.showNumberValue(this.props.data.kw_injetado_max_fora_ponta)}`} kw
            </span>
          </Typography>
        </Grid>
        }
        <Grid item xs={6} md={6}></Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(FechamentoView);
